<template>
  <v-row class="d-flex justify-center ma-0 pa-0 fill-height">
    <v-col cols="12" lg="10" md="7">
      <v-card class="card-shadow bg-secondary">
        <ExibicaoHotel />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ExibicaoHotel from "../Components/Exibicoes/ExibicaoHotel.vue";
export default {
  name: "ShowHotel",
  components: {
    ExibicaoHotel,
  },
  data() {
    return {};
  },
};
</script>
