<template class="template">
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <form
      id="formularioCadastroReserva"
      class="formularioCadastroReserva"
      autocomplete="off"
    >
      <v-card class="card-shadow" width="100%">
        <v-row class="card-padding pb-0 mb-0 d-flex align-center">
          <v-col cols="6" lg="6" class="d-flex flex-column py-0">
            <h2 class="label-color font-weight-600">{{ agencia.nome }}</h2>
            <div class="d-flex flex-row pd-0 align-center">
              <label for="" class="label-color font-weight-600 pr-2"
                >Id:
              </label>
              <p class="label-color font-weight-300 mb-0">
                {{ agencia.idusuario }}
              </p>
            </div>
          </v-col>
          <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
            <button 
             @click="link('/list/agency')"
             type='button'

             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
          </v-col>
        </v-row>

      <v-row class="card-padding pt-0 mt-0 d-flex flex-row align-end">
        <v-col cols="6" lg="6" md="6" sm="6" class="">
          <Dropdown
            :options="status"
              :multiple="false"
              label="status"
              placeholder="Selecione um status"
              v-model="statusselecionado"
              id="status-select"
              :initialValue="statusselecionado"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-briefcase-24'"
          />
        </v-col>
        <v-col cols="6" lg="2" md="2" sm="2" xs="2" class="px-0">
         <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    width="145"
                    class="
                      font-weight-600
                      text-capitalize
                      btn-primary
                      py-0
                      px-6
                      rounded-sm
                      mt-7
                    "
                    outlined
                    color="#0f88f2"
                    data-cy="btn-envio-status"
                    @click="atualizarStatus"
                    >status</v-btn
                  >
        </v-col>
      </v-row>        
        <v-card-text class="card-padding">
          <v-row class="">
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="nome"
                label="nome"
                v-model="nome"
                :input="true"
                icon="ni ni-circle-08"
                placeholder="Insira seu nome"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="cnpj"
                label="cnpj"
                v-model="cnpj"
                v-mask="'##.###.###/####-##'"
                :input="true"
                icon="ni ni-badge"
                placeholder="Insira seu cnpj"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="email"
                label="email"
                v-model="email"
                :input="true"
                icon="ni ni-email-83"
                placeholder="Insira seu email"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="site"
                label="site"
                v-model="site"
                :input="true"
                icon="ni ni-world-2"
                placeholder="Insira seu site"
              />
            </v-col>
          </v-row>
          <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        width="130"
        class="
          font-weight-600
          text-capitalize
          btn-primary
          py-3
          px-6
          my-3
          rounded-sm
        "
        color="#0f88f2"
        data-cy='btn-envio'
        @click="envioFormulario"
        >Atualizar</v-btn
      >
        </v-card-text>
      </v-card>
      <button type="reset" id="btn-reset" hidden />
      
    </form>
  </div>
</template>

<script>
import { alertar } from "@/servicos/notificacao.servico.js";
import AgenciaServico from "../../../servicos/agencia.servico";
import LocalizacaoServico from "../../../servicos/localizacao.servico";
import validarCnpj from "../../../servicos/validarCnpj";
import Dropdown from "../../../components/Dropdown.vue";
import Input from "../../../components/Input.vue";

export default {
  components: { Dropdown, Input },
  name: "LeituraOperadora",
  data() {
    return {
      nome: null,
      email: null,
      cnpj: null,
      data: null,
      telefone: null,
      celular: null,
      senha: null,
      senha2: null,
      site: null,
      paisselecionado: 0,
      estadoselecionado: 0,
      cidadeselecionado: 0,
      paises: [{ idpais: 0, nome: "Selecione um País" }],
      estados: [{ idestado: 0, nome: "Selecione um Estado" }],
      cidades: [{ idcidade: 0, nome: "Selecione uma Cidade" }],
      agencia: {},
      status: [],
      statusselecionado: 0,
    };
  },
  methods: {
    async envioFormulario(e) {
      e.preventDefault();
      if (this.validacao()) {
        const newFormtCnpj = this.cnpj
          .replace(".", "")
          .replace(".", "")
          .replace("/", "")
          .replace("-", "");

        const dadosusuario = {
          idusuario: parseInt(this.idusuario),
          email: this.email,
          nome: this.nome,
          iddocumentotipo: 3,
          numerodocumento: parseInt(newFormtCnpj),
          idstatususuario: this.statusselecionado,
          statususuario: this.status.filter(
            (el) => el.value === this.statusselecionado
          )[0].nome,
        };
        AgenciaServico.atualizar(dadosusuario)
          .then((resp) => {
            if (resp.status === 200) {
              alertar("success", "=)", "Agência atualizada com Sucesso!");
            }
          })
          .catch((error) => {
            alertar("warning", "=(", error.response.data.msg);
          });
      }
    },
    async atualizarStatus(){
      const dadosusuario = {
          idusuario: parseInt(this.idusuario),
          idstatususuario: this.statusselecionado,
          statususuario: this.status.filter(
            (el) => el.value === this.statusselecionado
          )[0].nome,
        };
        AgenciaServico.atualizar(dadosusuario)
          .then((resp) => {
            if (resp.status === 200) {
              alertar("success", "=)", "Agência atualizada com Sucesso!");
            }
          })
          .catch((error) => {
            alertar("warning", "=(", error.response.data.msg);
          });
    },
    validacao() {
      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        return false;
      }
      if (!this.email || this.email.trim() == "") {
        alertar("warning", "Informe um email Válido!", "Campo Obrigatório!");
        return false;
      }
      if (!this.statusselecionado || this.statusselecionado == 0) {
        alertar("warning", "Informe um status Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.cnpj || this.cnpj.trim() == "") {
        alertar("warning", "Informe um CNPJ Válido!", "Campo Obrigatório!");
        return false;
      } else if (!validarCnpj(this.cnpj)) {
        alertar("warning", "Informe um CNPJ Válido!");
        return false;
      }

      return true;
    },
    async fetchPaises() {
      this.paisselecionado = 0;
      const response = await LocalizacaoServico.paises({});
      this.paises = [{ idpais: 0, nome: "Selecione um País" }];
      response.data.map((p) => {
        this.paises.push(p);
      });
    },
    async fetchEstados(e) {
      this.estadoselecionado = 0;
      const response = await LocalizacaoServico.estados({
        idpais: this.paisselecionado,
      });
      this.estados = [{ idestado: 0, nome: "Selecione um Estado" }];
      response.data.map((p) => {
        this.estados.push(p);
      });
    },
    async fetchCidades(e) {
      this.cidadeselecionado = 0;
      const response = await LocalizacaoServico.cidades({
        idestado: this.estadoselecionado,
      });
      this.cidades = [{ idcidade: 0, nome: "Selecione uma Cidade" }];
      response.data.map((p) => {
        this.cidades.push(p);
      });
    },
    handleChangePais(e) {
      this.fetchEstados(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ idcidade: 0, nome: "Selecione uma Cidade" }];
    },
    handleChangeEstados(e) {
      this.fetchCidades(e);
    },

    link(to) {
      this.$router.push(to);
    },
    async requisitarAgencia(id) {
      const { data } = await AgenciaServico.listar(id);
      return data[0];
    },
    async requisitarStatus() {
      const { data } = await AgenciaServico.status();
      this.status = data.map((status) => ({
        nome: status.nome,
        value: status.idstatususuario,
      }));
    },
  },
  async mounted() {
    this.fetchPaises();
    await this.requisitarStatus();
    const { id } = this.$route.params;
    this.agencia = await this.requisitarAgencia(id);

    this.nome = this.agencia.nome;
    this.email = this.agencia.email;
    this.cnpj = this.agencia.numerodocumento;

    await this.fetchPaises();
    this.paisselecionado = parseInt(this.agencia.idpais) || 0;
    await this.fetchEstados(this.estado);
    this.estadoselecionado = parseInt(this.agencia.idestado) || 0;
    await this.fetchCidades(this.cidade);
    this.cidadeselecionado = parseInt(this.agencia.idcidade) || 0;

    this.idusuario = this.agencia.idusuario;
    this.statusselecionado = this.agencia.idstatususuario;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.formularioCadastroReserva {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}
.fields-container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
.span-error {
  width: 100%;
  font-size: 12px;
  color: red;
  text-align: left;
}
.apartamentosWrapper {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}
.aparatamentoCard {
  display: flex;
  flex-direction: column;
  max-width: 48%;
  padding: 0.5rem;
  background: whitesmoke;
}
.apartamentoCardWrapper {
  display: flex;
  justify-content: space-between;
}
.card-btn {
  width: auto;
  padding: 0 0.5rem;
  border-radius: unset;
}
.unicon {
  display: flex;
  justify-content: center;
}

.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}
</style>
