import api from '@/servicos/api'

export default {
  registrar(body) {
    return api().post('/hotel', body)
  },
  atualizar(body) {
    return api().put('/hotel', body)
  },
  listarTodos() {
    return api().post('/hotel/select', {})
  },
  listarPorUsuarioAdm(ativo, idusuarioadmhotel) {
    return api().post('/hotel/select', {usuarioadm: ativo, idusuario: idusuarioadmhotel})
  },
  listar(id) {
    return api().post('/hotel/select', { idhotel: id })
  },
  async solicitacoesPorHotel(id) {
    const { data } = await api().post('/hotel/select', { idhotel: id })
    return data[0].solicitacoes
  },
  
  buscar(query){
    return api().post('/hotel/select', query)
  },


  listarPromocoesAgentes(id){
    return api().post('/hotel/promocao-agentes/select', { idhotel: id })//busca promocao por hotel
  },
  listarPromocaoAgentes(id){
    return api().post('/hotel/promocao-agentes/select', { idpromocao: id })//busca promocao pelo id da promocao
  },
  buscarPromocaoAgentes(query){
    return api().post('/hotel/promocao-agentes/select', query)//busca promocao pelo id da promocao
  },
  registarPromocoesAgentes(body){
    return api().post('/hotel/promocao-agentes', body)//registra a promocao
  },
  atualizarPromocoesAgentes(body){
    return api().put('/hotel/promocao-agentes', body)//atualiza a promocao
  }

}
