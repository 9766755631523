<template class="template">
<span>
    <form id="operadoraForm" class="operadoraForm" autocomplete="off" v-if="!loading">
    <v-card class="card-shadow" width="100%">
      <h2 class="ml-5 mt-5">Cadastro de Operadora</h2>
      <v-card-text class="card-padding">
        <v-row class="">
          <v-col md="6" sm="12" xs="12">
            <Input
              name="nome"
              label="nome"
              v-model="nome"
              :input="true"
              icon="ni ni-circle-08"
              placeholder="Insira seu nome"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="email"
              label="email"
              v-model="email"
              :input="true"
              icon="ni ni-email-83"
              placeholder="Insira seu email"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col md="6" sm="12" xs="12">
            <Input
              name="cnpj"
              label="cnpj"
              v-model="cnpj"
              v-mask="'##.###.###/####-##'"
              :input="true"
              icon="ni ni-badge"
              placeholder="Insira seu cnpj"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="data"
              label="data"
              v-model="data"
              :input="true"
              type="date"
              icon="ni ni-calendar-grid-58"
              placeholder="Insira sua data de fundação"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col md="6" sm="12" xs="12">
            <Input
              name="telefone"
              label="telefone"
              v-model="telefone"
              v-mask="'(##) ####-####'"
              type="tel"
              :input="true"
              icon="ni ni-mobile-button"
              placeholder="Insira seu telefone"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="celular"
              label="celular"
              v-model="celular"
              v-mask="'(##) # ####-####'"
              type="tel"
              :input="true"
              icon="ni ni-mobile-button"
              placeholder="Insira seu celular"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            
            <Dropdown
              :multiple="false"
              :options="paises"
              id="pais-select"
              label="País"
              placeholder="Selecione um pais"
              v-model="paisselecionado"
              :reset="paisreset"
              :sideEffect="handleChangePais"
              data-cy="pais"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            
            <Dropdown
            label="Estado"
              :multiple="false"
              :options="estados"
              id="estado-select"
              placeholder="Selecione um estado"
              v-model="estadoselecionado"
              :reset="estadoreset"
              :sideEffect="handleChangeEstados"
              data-cy="estado"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            
            <Dropdown
              :multiple="false"
              :options="cidades"
              id="cidade-select"
              label="Cidade"
              placeholder="Selecione uma cidade"
              v-model="cidadeselecionado"
              :reset="cidadereset"
              data-cy="cidade"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
          <v-col md="6" sm="12" xs="12">
            <Input
              name="site"
              label="site"
              v-model="site"
              type="text"
              :input="true"
              icon="ni ni-world-2"
              placeholder="Insira seu site"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col md="6" sm="12" xs="12">
            <Input
              name="senha"
              label="senha"
              v-model="senha"
              type="password"
              :input="true"
              icon="ni ni-lock-circle-open"
              placeholder="Insira seu senha"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="senha2"
              label="senha2"
              v-model="senha2"
              type="password"
              :input="true"
              icon="ni ni-lock-circle-open"
              placeholder="Confirme sua senha"
            />
          </v-col>

          <!-- <v-checkbox
                v-model="checkbox"
                data-cy="checkbox"
                color="#0f88f2"
                :ripple="false"
                class="ma-0 checkbox-custom checkbox-thinner"
                hide-details
              >
                <template v-slot:label>
                  <span class="text-muted text-body-2 ls-0"
                    >Li e Estou de Acordo com o
                    <a
                      href="javascript:;"
                      class="text-primary text-decoration-none"
                      >Termo de Privacidade</a
                    >
                  </span>
                </template>
              </v-checkbox> -->
        </v-row>
      </v-card-text>
    </v-card>

    <button type="reset" id="btn-reset" hidden />

    <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      color="#0f88f2"
      data-cy="btn-envio"
      @click="cadastrarOperadora()"
      :loading="reqloading"
      :disabled="reqloading"
      >Cadastrar</v-btn
    >
    <span v-if="!foiValidado" hidden data-cy="data-cy-error" />
  </form>
  <FormSkeleton v-if="loading"/>
  </span>
</template>

<script>
import OperadoraServico from "../../../servicos/operadora.servico";
import LocalizacaoServico from "../../../servicos/localizacao.servico";
import { alertar } from "@/servicos/notificacao.servico.js";
import validarCnpj from "../../../servicos/validarCnpj";
import Dropdown from "../../../components/Dropdown.vue";
import Input from "../../../components/Input.vue";
import FormSkeleton from '../../../components/Loading/FormSkeleton.vue'
export default {
  components: { Dropdown, Input, FormSkeleton },
  name: "CadastroOperadora",
  watch: {
    paisselecionado: function (val) {
      this.fetchEstados();
    },
  },
  data: () => ({
    loading: false,
    nome: null,
    email: null,
    cnpj: null,
    data: null,
    telefone: null,
    celular: null,
    senha: null,
    senha2: null,
    site: null,
    paisselecionado: 0,
    estadoselecionado: 0,
    cidadeselecionado: 0,
    paises: [{ idpais: 0, nome: "Selecione um País" }],
    estados: [{ idestado: 0, nome: "Selecione um Estado" }],
    cidades: [{ idcidade: 0, nome: "Selecione uma Cidade" }],
    paisreset: false,
    estadoreset: false,
    cidadereset: false,
    foiValidado: true,
    reqloading: false,
  }),
  methods: {
    async cadastrarOperadora() {
      if (this.validarCampos()) {
        this.reqloading = true
        const cnpjNewFormat = this.cnpj
          .replace(".", "")
          .replace(".", "")
          .replace("/", "")
          .replace("-", "");

        const dadosusuario = {
          // idusuariotipo: 3,
          // idstatususuario: 1,
          // email: this.email,
          // senha: this.senha,
          nome: this.nome,
          // iddocumentotipo: 3,
          cnpj: cnpjNewFormat,
          // site: this.site,
          // enderecos: [
            //   {
              //     idpais: parseInt(this.paisselecionado),
          //     idestado: parseInt(this.estadoselecionado),
          //     idcidade: parseInt(this.cidadeselecionado),
          //   },
          // ],
        };

        console.log(JSON.stringify(dadosusuario, null, "\t"));

        await OperadoraServico.registrar(dadosusuario)
          .then((resp) => {
            if (resp.status === 200) {
              this.nome = null;
              this.email = null;
              this.cnpj = null;
              this.data = null;
              this.telefone = null;
              this.celular = null;
              this.senha = null;
              this.senha2 = null;
              this.site = null;
              this.paisselecionado = 0;
              this.estadoselecionado = 0;
              this.cidadeselecionado = 0;
              document.getElementById("btn-reset").click();
              alertar("success", "=)", "Operadora Criada com Sucesso!");
            }
          })
          .catch((error) => {
            alertar("warning", "=(", error.response.data.msg);
          });
            this.reqloading = false
      }
    },
    validarCampos() {
      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.email || this.email.trim() == "") {
        alertar("warning", "Informe um Email Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.cnpj || this.cnpj.trim() == "") {
        alertar("warning", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      } else if (!validarCnpj(this.cnpj)) {
        alertar("warning", "Informe um CNPJ Válido!");
        this.foiValidado = false;
        return false;
      }

      if (!this.data || this.data.trim() == "") {
        alertar(
          "warning",
          "Informe um Data de Criação Válida!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      } else {
        const currDate = new Date(this.data);
        if (currDate > 0 === false) {
          alertar(
            "warning",
            "Informe um Data de Criação Válida!",
            "Campo Obrigatório!"
          );
          this.foiValidado = false;
          return false;
        }
      }

      if (!this.telefone || this.telefone.trim() == "") {
        alertar("warning", "Informe um Telefone Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.celular || this.celular.trim() == "") {
        alertar("warning", "Informe um Celular Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.paisselecionado || this.paisselecionado == 0) {
        alertar("warning", "Informe um Pais Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.estadoselecionado || this.estadoselecionado == 0) {
        alertar("warning", "Informe um Estado Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.cidadeselecionado || this.cidadeselecionado == 0) {
        alertar("warning", "Informe uma Cidade Válida!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.senha || this.senha.trim() == "") {
        alertar("warning", "Informe uma Senha Válida!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.senha2 || this.senha2.trim() == "") {
        alertar("warning", "Repita a Senha!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (this.senha != this.senha2) {
        alertar("warning", "As Senhas não estão iguais!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      return true;
    },
    async fetchPaises() {
      this.paisselecionado = 0;
      const response = await LocalizacaoServico.paises({});
      this.paises = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.paises.push({ nome: p.nome, value: p.idpais });
      });
      this.cidades = [{ nome: "", value: 0 }];
    },
    async fetchEstados(e) {
      this.estadoselecionado = 0;
      const response = await LocalizacaoServico.estados({
        idpais: this.paisselecionado,
      });
      this.estados = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.estados.push({ nome: p.nome, value: p.idestado });
      });
    },
    async fetchCidades(e) {
      this.cidadeselecionado = 0;
      const response = await LocalizacaoServico.cidades({
        idestado: this.estadoselecionado,
      });
      this.cidades = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.cidades.push({ nome: p.nome, value: p.idcidade });
      });
    },
    handleChangePais(e) {
      this.fetchEstados(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "", value: 0 }];
      this.cidadereset = !this.cidadereset;
      this.estadoselecionado = 0;
      this.estados = [{ nome: "", value: 0 }];
      this.estadoreset = !this.estadoreset;
    },
    handleChangeEstados(e) {
      this.cidadereset = !this.cidadereset;
      this.fetchCidades(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "", value: 0 }];
    },
  },
  async mounted() {
    this.loading = true
    await this.fetchPaises();
    this.loading = false
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.operadoraForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}
.fields-container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
.span-error {
  width: 100%;
  font-size: 12px;
  color: red;
  text-align: left;
}
</style>
