//api de teste
// import Api from '@/services/Api'

// export default {
//   register (credentials) {
//     return Api().post('register', credentials)
//   },
//   login (credentials) {
//     return Api().post('login', credentials)
//   },
//   changePassword (credentials) {
//     return Api().post('change/password', credentials)
//   },
//   createPasswordLink (credentials) {
//     return Api().post('/change/create-password-link', credentials)
//   }
// }

import api from '@/servicos/api'

export default {
  registrar(dados) {
    return api().post('/usuario', dados)
  },
  listar(id) {
    return api().post('/usuario/select', { idusuario: id })
  },
  buscar(query) {
    return api().post('/usuario/select', query )
  },
  atualizar(body) {
    return api().put('/usuario', body)
  },
  tipos() {
    return api().get('/usuario/tipos')
  },
  listarPorTipo(id){
    return api().post('/usuario/select', {idusuariotipo: id})
  },
  status() {
    return api().get('/usuario/status')
  },
  niveis() {
    return api().get('/regras/nivel')
  },
  listarEnderecoPorUsuario(id){
    return api().post('/usuario/endereco/select', {idusuario: id})
  },
  buscarAdmHotel(id){
    return api().post('/hotel/select/admhotel', {idhotel: id})
  }
}

