<template>  
  <h1>teste</h1>
</template>



<script>
export default {
  name: "Test",
  components: {},
  data() {
    return {};
  },
  methods:{

  }
};
</script>

<style lang="scss" scoped>

</style>
