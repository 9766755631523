<template>
  <v-row class="d-flex justify-center mt-n16">
    <v-col cols="12" md="5">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <span></span>
          <v-avatar class="avatar-absolute" size="140">
            <v-img src="@/assets/img/team-4.jpg"></v-img>
          </v-avatar>
          <v-card-text
            class="card-header-padding px-12 pt-16 text-center font-size-root"
          >
            <div class="text-center mb-5">
              <h3 class="text-h3 text-typo font-weight-600 mt-8">
                Jessica Jones
              </h3>
            </div>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Name"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mt-6
                mb-4
              "
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">ni-hat-3</v-icon>
              </template>
            </v-text-field>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                mt-6
              "
              color="#0f88f2"
              >Create Account</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "lock",
};
</script>
