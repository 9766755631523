<template>
  <v-row class="d-flex justify-center mt-n16">
    <v-col cols="12" md="5">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <v-card-text class="pa-12 text-center font-size-root">
            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Email"
              required
              data-cy="input-email"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mt-6
                mb-4
              "
              v-model="email"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">ni-email-83</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Senha"
              required
              data-cy="input-senha"
              type="password"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-6
              "
              v-model="senha"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem"
                  >ni-lock-circle-open</v-icon
                >
              </template>
            </v-text-field>

            <v-btn
              elevation="0"
              :ripple="false"
              @click="login()"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                my-6
              "
              color="#0f88f2"
              data-cy="btn-login"
              >Entrar</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>

      <div class="d-flex justify-space-between mt-4">
        <a
          href="javascript:;"
          class="text-light text-decoration-none btn-hover no-default-hover"
          ><small>Esqueceu sua Senha?</small></a
        >
        <a
          href="javascript:;"
          @click="$router.push('register')"
          class="text-light text-decoration-none btn-hover no-default-hover"
          ><small>Criar novo Usuário</small></a
        >
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { alertar } from "@/servicos/notificacao.servico.js";
import { logar } from "@/servicos/autenticacao.servicos.js";
export default {
  name: "login",
  data() {
    return {
      email: "ADM@GMAILTESTE.COM",
      senha: "123456",
    };
  },
  methods: {
    login() {
      if (!this.email || this.email.trim() == "") {
        alertar("warning", "Informe um Email Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.senha || this.senha.trim() == "") {
        alertar("warning", "Informe uma Senha Válida!", "Campo Obrigatório!");
        return false;
      }

      logar(this.email, this.senha).then(() => {
        this.$router.push("/dash/dashboard");
      });
    },
  },
};
</script>
