import api from '@/servicos/api'

export default {
  configurar(body){
    return api().post('/nivel', body)
  },
  atualizar(body){
    return api().put('/nivel', body)
  },
  async listarPorHotel(id){
    const {data} =await  api().post('/nivel/select',{idhotel: id})
    return {data}
  },
}