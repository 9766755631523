<template class="template">
  <form
    id="formularioCadastroReserva"
    class="formularioCadastroReserva"
    autocomplete="off"
  >
    <v-card class="card-shadow" width="100%">
      <v-row class="card-padding pb-0 mb-0 d-flex align-center">
        <v-col cols="6" lg="6" class="d-flex flex-column py-0">
          
        </v-col>
        <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
         <button 
             @click="link('/list/reserve')"
             type='button'
             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
        </v-col>
      </v-row>
      <v-row class="card-padding pt-0 mt-0 d-flex flex-row align-end">
        <v-col cols="6" lg="6" md="6" sm="6" class="">
          <Dropdown
            :options="status"
            :multiple="false"
            placeholder="Selecione um status"
            label="status"
            v-model="statusselecionado"
            id="status-select"
            :initialValue="statusselecionado"
            :border="'2px solid rgba(15, 136, 242, 0.3)'"
            :borderactive="'2px solid rgba(15, 136, 242)'"
            :borderradius="'0.7125rem'"
            :icon="'ni ni-briefcase-24'"
            v-if="acessoadmdisponivel"
          />
        </v-col>
        <v-col cols="6" lg="2" md="2" sm="2" xs="2" class="px-0">
         <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    width="145"
                    class="
                      font-weight-600
                      text-capitalize
                      btn-primary
                      py-0
                      px-6
                      rounded-sm
                      mt-7
                    "
                    outlined
                    color="#0f88f2"
                    data-cy="btn-envio-status"
                    @click="atualizarStatus"
                    >status</v-btn
                  >
            </v-col>
      </v-row>
      <v-card-text class="card-padding">
        <v-row class="">
          <v-col md="6" sm="6" xs="6">
            <Input
              name="nome"
              label="nome do hóspede"
              v-model="nome"
              :input="true"
              icon="ni ni-circle-08"
              placeholder="Insira o nome do hóspede"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="sobrenome"
              label="sobrenome do hóspede"
              v-model="sobrenome"
              :input="true"
              icon="ni ni-hat-3"
              placeholder="Insira o sobrenome do hóspede"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="email"
              label="email do hóspede"
              v-model="email"
              :input="true"
              icon="ni ni-email-83"
              placeholder="Insira o email do hóspede"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="celular"
              label="celular do hóspede"
              v-model="celular"
              v-mask="'(##) # ####-####'"
              type="tel"
              :input="true"
              icon="ni ni-mobile-button"
              placeholder="Insira o celular do hóspede"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            
            <Dropdown
              label="hotel"
              placeholder="selecione um hotel"
              :options="hoteis"
              v-model="hotel"
              id="hotel"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :disabled="true"
              :initialValue="hotel"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
           
            <Dropdown
              :options="agencias"
              v-model="agencia"
              id="agencia"
              placeholder="Selecione uma agência"
              label="agência"
              data-cy="agencia"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-briefcase-24'"
              :initialValue="agencia"
              :disabled="true"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="facebook"
              label="facebook"
              v-model="facebook"
              :initialValue="facebook"
              :input="true"
              icon="ni ni-ungroup"
              placeholder="Insira o facebook do hóspede"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="instagram"
              label="instagram"
              v-model="instagram"
              :initialValue="instagram"
              :input="true"
              icon="ni ni-ungroup"
              placeholder="Insira o instagram do hóspede"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="linkedin"
              label="linkedin"
              v-model="linkedin"
              :initialValue="linkedin"
              :input="true"
              icon="ni ni-ungroup"
              placeholder="Insira o linkedin do hóspede"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
                        <Dropdown
              :options="operadoras"
              v-model="operadora"
              id="operadora"
              label="operadora"
              placeholder="Selecione uma operadora"
              data-cy="operadora"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-briefcase-24'"
              :initialValue="operadora"
              :disabled="true"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="checkin"
              label="checkin"
              v-model="checkin"
              :initialValue="checkin"
              :input="true"
              type="date"
              icon="ni ni-calendar-grid-58"
              placeholder=""
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="checkout"
              label="checkout"
              v-model="checkout"
              :initialValue="checkout"
              :input="true"
              type="date"
              icon="ni ni-calendar-grid-58"
              placeholder=""
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="chegada"
              label="chegada"
              v-model="chegada"
              :initialValue="chegada"
              :input="true"
              type="time"
              icon="ni ni-watch-time"
              placeholder=""
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="partida"
              label="partida"
              v-model="partida"
              :initialValue="partida"
              :input="true"
              type="time"
              icon="ni ni-watch-time"
              placeholder=""
            />
          </v-col>
        </v-row>

        <label for="" class="label-color font-weight-600 mt-2 d-block"
          >Solicitações Especiais</label
        >
        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <div
              v-for="(item, idx) in solicitacoes"
              :key="item.nome"
              v-if="solicitacoes[idx].hotelatende"
              :class="
                solicitacoes[idx].solicitado
                  ? 'item-checkbox-checked'
                  : 'item-checkbox'
              "
              @click="
                solicitacoes[idx].solicitado = !solicitacoes[idx].solicitado
              "
            >
              <input
                type="checkbox"
                v-model="solicitacoes[idx].solicitado"
                :id="`item-${item.nome}`"
                :name="`item-${item.nome}`"
                :label="`item-${item.nome}`"
                :data-cy="`${item.nome.toLowerCase()}`"
              />
              <label
                :for="`item-${item.nome}`"
                @click="
                  solicitacoes[idx].solicitado = !solicitacoes[idx].solicitado
                "
                >{{ item.nome }}</label
              >
            </div>
          </v-col>
        </v-row>

        <v-row class="mensagem-wrapper">
          <v-col cols="12" md="12" sm="12" xs="12" class="mensagem-header">
            <button @click="syncMensagem" class="refresh-button" type="button">
              <unicon size="22" fill="#fff" name="sync" :class="syncmensagemcarregando ? 'carregando-mensagem' : ''"/>
            </button>            
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12" class="mensagem-box">
            <p v-for="mensagem,idx in dadosmensagem" :key="idx" :class="mensagem.idusuario === idusuario ? 'mensagem enviada' : 'mensagem recebida'">
              {{mensagem.mensagem}}
              <span class="mensagem-data">{{mensagem.data | formatarDataYYHora}}</span>
            </p>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12" class="mensagem-footer">
            <div class="input-send-wrapper">
              <input type="text" v-model="inputmensagem" v-on:keyup.enter="enviarMensagem"/>
              <button class="send-button" type='button' @click="enviarMensagem">
                <v-icon size="22" class="send-icon" v-if="!enviomensagemcarregando && !mensagemenviada">ni ni-send</v-icon>
                <v-icon size="22" class="send-icon" v-if="mensagemenviada && !enviomensagemcarregando">ni ni-satisfied</v-icon>
                <unicon size="22" fill="#fff" name="spinner" v-if="enviomensagemcarregando" :class="enviomensagemcarregando ? 'carregando-mensagem' : ''"/>
              </button>
            </div>
          </v-col>
        </v-row>

        <v-row class="">
          <!-- <v-checkbox
                v-model="checkbox"
                color="#0f88f2"
                :ripple="false"
                class="ma-0 checkbox-custom checkbox-thinner"
                hide-details
              >
                <template v-slot:label>
                  <span class="text-muted text-body-2 ls-0"
                    >Li e Estou de Acordo com o
                    <a
                      href="javascript:;"
                      class="text-primary text-decoration-none"
                      >Termo de Privacidade</a
                    >
                  </span>
                </template>
              </v-checkbox> -->
        </v-row>
        <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      color="#0f88f2"
      data-cy="btn-envio"
      @click="envioFormulario"
      :loading="reqloading"
      :disabled="reqloading"
      >Atualizar</v-btn
    >
      </v-card-text>
    </v-card>

    <button type="reset" id="btn-reset" hidden />

    
  </form>
</template>

<script>
import AgenciaServico from "../../../servicos/agencia.servico";
import HotelServico from "../../../servicos/hotel.servico";
import { alertar } from "@/servicos/notificacao.servico.js";
import ReservaServico from "../../../servicos/reserva.servico";
import OperadoraServico from "../../../servicos/operadora.servico";
import Dropdown from "../../../components/Dropdown";
import Input from "../../../components/Input";
import EmailServico from '../../../servicos/email.servico.js'
import UsuarioServico from '../../../servicos/usuario.servico';
import NotificacaoServico from '../../../servicos/notificacao.servico';
export default {
  components: { Dropdown, Input },
  name: "LeituraReserva",
  data() {
    return {
      reqloading: false,
      solicitacoes: [],
      nome: null,
      sobrenome: null,
      email: null,
      celular: null,
      agencia: null,
      instagram: null,
      facebook: null,
      linkedin: null,
      codigo: null,
      operadora: 0,
      operadoras: [],
      checkin: null,
      checkout: null,
      chegada: null,
      partida: null,
      dadosmensagem: null,
      agencias: [],
      reserva: {},
      hotel: null,
      hoteis: [],
      status: [],
      statusselecionado: 0,
      idusuariotipo: null,
      idusuario: null,
      inputmensagem: null,
      syncmensagemcarregando: false,
      enviomensagemcarregando: false,
      mensagemenviada: false,
      acessoadmdisponivel: false,
      statusinicial: null,
    };
  },
  methods: {
    async envioFormulario(e) {
      e.preventDefault();
      if (this.validacao()) {
        this.reqloading = true
        const today = new Date();
        const dataCadastro =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        const datacheckin = this.transformDate(this.checkin, this.chegada);
        const datacheckout = this.transformDate(this.checkout, this.partida);
        const dadosusuario = {
          idreserva: parseInt(this.reserva.idreserva),
          idhotel: parseInt(this.reserva.idhotel),
          idagente: parseInt(this.reserva.idagente), //fixo pois nao tem sistema de login
          idagencia: parseInt(this.agencia),
          idoperadora: parseInt(this.operadora),
          datacadastro: dataCadastro,
          datacheckin: datacheckin.toISOString(),
          datacheckout: datacheckout.toISOString(),
          idreservastatus: parseInt(this.statusselecionado),
          reservastatus: this.status.filter(
            (el) => el.value === this.statusselecionado
          )[0].nome,
          hospedes: [
            {
              idhospede: this.reserva.idhospede,
              principal: true,
              nome: this.nome,
              sobrenome: this.sobrenome,
              datanascimento: null,
              email: this.email,
              iddocumentotipo: null,
              numerodocumento: null,
              celular: this.celular
                .replace("(", "")
                .replace(")", "")
                .replace(" ", "")
                .replace(" ", "")
                .replace("-", ""),
              facebook: this.facebook,
              instagram: this.instagram,
              twitter: null,
              linkedin: this.linkedin,
              enderecos: null,
            },
          ],
          solicitacoes: this.solicitacoes.map((soli) => {
            return {
              idtiposolicitacao: soli.idtiposolicitacao,
              solicitado: soli.solicitado,
            };
          }),
        };
        ReservaServico.atualizar(dadosusuario)
          .then(async (resp) => {
            console.log("resp", resp);
            if (resp.status === 200) {
              //enviar email
              if(this.statusinicial !== this.statusselecionado){
                EmailServico.email({
                  titulo: 'Status de Reserva Alterado',
                  conteudo: `O status da reserva n° ${dadosusuario.idreserva} foi alterado para ${dadosusuario.reservastatus}`,
                  subject: 'Status Reserva'
                }).then(() => console.log('email enviado')).catch(err => console.log('erro', err))
              //notificar
              try{
                  //enviar notificacao
                NotificacaoServico.registrar({
                  idusuario: this.reserva.idagente,//agente q criou a reserva
                  titulo: 'Atualização de Status',
                  mensagem: `Novo Status: ${dadosusuario.reservastatus}`
                }).then(() => console.log('notificacao usuario')).catch(e => console.log('error usuario'))
                NotificacaoServico.registrar({
                  idusuario: this.idusuario,
                  titulo: 'Atualização de Status',
                  mensagem: `Novo Status: ${dadosusuario.reservastatus} na reserva id: ${dadosusuario.idreserva}`
                }).then(() => console.log('notificacao criador')).catch(e => console.log('error criador'))
                }catch(e){
                  console.log('error notificacao status leitura agente', e)
                }
              }
              //notificar atualizacaos fora status
              try{
                  //enviar notificacao
                if(this.idusuariotipo === 2){
                  await NotificacaoServico.registrar({
                  idusuario: this.reserva.idagente,//como saber o agente q criou a reserva
                  titulo: 'Atualização Reserva',
                  mensagem: `A reserva id: ${dadosusuario.idreserva} foi alterada. Confira.`
                }).then(() => console.log('notificacao usuario')).catch(e => console.log('error usuario'))
                }else if(this.idusuariotipo === 4){
                  const idadmhotel = await UsuarioServico.buscarAdmHotel(this.reserva.idhotel).then(res => res.data.idusuario)
                  await NotificacaoServico.registrar({
                  idusuario: idadmhotel,//como saber o adm do hotel
                  titulo: 'Atualização Reserva',
                  mensagem: `A reserva id: ${dadosusuario.idreserva} foi alterada. Confira.`
                }).then(() => console.log('notificacao usuario')).catch(e => console.log('error usuario'))
                }
                await NotificacaoServico.registrar({
                  idusuario: this.idusuario,
                  titulo: 'Atualização Reserva',
                  mensagem: `A reserva id: ${dadosusuario.idreserva} foi alterada. Confira.`
                }).then(() => console.log('notificacao criador')).catch(e => console.log('error criador'))
                }catch(e){
                  console.log('error notificacao status leitura agente', e)
                }
              this.reqloading = false
              
              alertar("success", "=)", "reserva atualizada com sucesso!");
            }
          })
          .catch((error) => {
            this.reqloading = false
            alertar("warning", "=(", error.response.data.msg);
          });
      }
    },
    async atualizarStatus(){
      const dados = {
          idhotel: parseInt(this.reserva.idhotel),
          idreserva: parseInt(this.reserva.idreserva),
          idreservastatus: parseInt(this.statusselecionado),
          reservastatus: this.status.filter(
            (el) => el.value === this.statusselecionado
          )[0].nome,
          hospedes: [
            {
              idhospede: this.reserva.idhospede,
              principal: true,
              nome: this.nome,
              sobrenome: this.sobrenome,
              datanascimento: null,
              email: this.email,
              iddocumentotipo: null,
              numerodocumento: null,
              celular: this.celular
                .replace("(", "")
                .replace(")", "")
                .replace(" ", "")
                .replace(" ", "")
                .replace("-", ""),
              facebook: this.facebook,
              instagram: this.instagram,
              twitter: null,
              linkedin: this.linkedin,
              enderecos: null,
            },
          ],
      }
      ReservaServico.atualizar(dados)
          .then(async (resp) => {
            console.log("resp segunda", resp);
            if (resp.status === 200) {
              //enviar email
              if(this.statusinicial !== this.statusselecionado){
                EmailServico.email({
                  titulo: 'Status de Reserva Alterado',
                  conteudo: `O status da reserva n° ${dados.idreserva} foi alterado para ${dados.reservastatus}`,
                  subject: 'Status Reserva'
                }).then(() => console.log('email enviado')).catch(err => console.log('erro', err))
              //notificar
              try{
                  //enviar notificacao
                NotificacaoServico.registrar({
                  idusuario: this.reserva.idagente,//agente q criou a reserva
                  titulo: 'Atualização de Status',
                  mensagem: `Novo Status: ${dados.reservastatus}`
                }).then(() => console.log('notificacao usuario')).catch(e => console.log('error usuario'))
                NotificacaoServico.registrar({
                  idusuario: this.idusuario,
                  titulo: 'Atualização de Status',
                  mensagem: `Novo Status: ${dados.reservastatus} na reserva id: ${dados.idreserva}`
                }).then(() => console.log('notificacao criador')).catch(e => console.log('error criador'))
                }catch(e){
                  console.log('error notificacao status leitura agente', e)
                }
              }
              alertar("success", "=)", "reserva atualizada com sucesso!");
            }
          })
          .catch((error) => {
            alertar("warning", "=(", error.response.data.msg);
          });      
    },
    validacao() {
      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.sobrenome || this.sobrenome.trim() == "") {
        alertar(
          "warning",
          "Informe um Sobrenome Válido!",
          "Campo Obrigatório!"
        );
        return false;
      }

      if (!this.email || this.email.trim() == "") {
        alertar("warning", "Informe um Email Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.celular || this.celular.trim() == "") {
        alertar("warning", "Informe um Celular Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.agencia || this.agencia == 0) {
        alertar("warning", "Informe uma Agência Válida!", "Campo Obrigatório!");
        return false;
      }
      if (!this.operadora || this.operadora == 0) {
        alertar(
          "warning",
          "Informe uma operadora Válida!",
          "Campo Obrigatório!"
        );
        return false;
      }


      if (
        !this.checkin ||
        this.checkin.trim() == "" ||
        !(new Date(this.checkin) > 0)
      ) {
        alertar(
          "warning",
          "Informe uma data de Check-in Válida!",
          "Campo Obrigatório!"
        );
        return false;
      }
      if (
        !this.checkout ||
        this.checkout.trim() == "" ||
        !(new Date(this.checkout) > 0)
      ) {
        alertar(
          "warning",
          "Informe uma data de Check-out Válida!",
          "Campo Obrigatório!"
        );
        return false;
      }
      if (
        new Date(this.checkin) > new Date(this.checkout) ||
        new Date(this.checkout) < new Date(this.checkin)
      ) {
        alertar(
          "warning",
          "Revisar datas de check-in e check-out",
          "Campo Obrigatório!"
        );
        return false;
      }
      if (!this.chegada || this.chegada.trim() == "") {
        alertar(
          "warning",
          "Informe um horario de chegada Válido!",
          "Campo Obrigatório!"
        );
        return false;
      }
      if (!this.partida || this.partida.trim() == "") {
        alertar(
          "warning",
          "Informe um horario de partida Válido!",
          "Campo Obrigatório!"
        );
        return false;
      }
      return true;
    },
    async requisitarAgencias() {
      const { data } = await AgenciaServico.listarTodos();
      let agencias = new Array();
      data.map((agencia) => {
        agencias.push({ nome: agencia.nome, value: agencia.idusuario });
      });
      this.agencias = agencias;
    },
    link(to) {
      this.$router.push(to);
    },
    async requisitarReserva(idreserva, idhotel) {
      const { data } = await ReservaServico.listar({
        idreserva: parseInt(idreserva),
        idhotel: parseInt(idhotel),
      });
      return data[0];
    },
    async requisitarHoteis() {
      let usuarioadm = this.idusuariotipo === 2 ? true : false
      if(usuarioadm){
        const { data } = await HotelServico.listarPorUsuarioAdm(usuarioadm, this.idusuario);
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
        return       
      }else{
      const { data } = await HotelServico.listarTodos();
      this.hotel = 0;
      let hoteis = new Array();
      data.map((hotel) => {
        hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
      });
      this.hoteis = hoteis;
      }
    },
    async requisitarStatus() {
      const { data } = await ReservaServico.status();
      this.status = data.map((status) => ({
        nome: status.nome,
        value: status.idreservastatus,
      }));
    },
    async requisitarSolicitacoes(idhote) {
      const solicitacoes = await HotelServico.solicitacoesPorHotel(idhote);
      return solicitacoes.filter((el) => el.hotelatende === true);
    },
    splitDate(date, type) {
      if (type === "time") {
        const auxdate = new Date(date);
        return new Date(auxdate - auxdate.getTimezoneOffset() * 60000)
          .toISOString()
          .substring(11, 16);
      }
      if (type === "date") {
        return date.substring(0, 10);
      }
    },
    transformDate(date, time) {
      const dateArray = date.split("-");
      const timeArray = time.split(":");
      const auxDate = new Date(
        dateArray[0],
        parseInt(dateArray[1]) - 1,
        dateArray[2],
        timeArray[0],
        timeArray[1]
      );
      return new Date(auxDate - auxDate.getTimezoneOffset() * 60000);
    },
    async requisitarOperadoras() {
      const { data } = await OperadoraServico.listarTodos();
      this.operadora = 0;
      let operadoras = new Array();
      data.map((operadora) => {
        operadoras.push({ nome: operadora.nome, value: operadora.idoperadora });
      });
      this.operadoras = operadoras;
    },
    async enviarMensagem(){
      if(this.validaoInputMensagem()){
        const dadosmensagem = {
        idusuario: this.idusuario,
        mensagem: this.inputmensagem,
        idreserva: this.reserva.idreserva
      }
      this.enviomensagemcarregando = true
      await ReservaServico.enviarMensagem(dadosmensagem)
        .then(async res => {
          if(res.status === 200){
            this.mensagemenviada = true
            this.inputmensagem = null
            await this.syncMensagem()
            setTimeout(() => {this.mensagemenviada = false}, 1000);
          }
        })
        .catch(error => {})
        this.enviomensagemcarregando = false
      }
    },
    async syncMensagem(){
      const dadosmensagem = {
        idreserva: this.reserva.idreserva
      }
      this.syncmensagemcarregando = true
      await ReservaServico.syncMensagem(dadosmensagem)
        .then(res => {
          if(res.status === 200){
            this.dadosmensagem = res.data
          }
        })
        .catch(error => {})
      this.syncmensagemcarregando = false
    },
    validaoInputMensagem(){
      if(!this.inputmensagem || this.inputmensagem.trim()==''){
        return false
      }
      return true

    }
  },
  async mounted() {
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    if(this.idusuariotipo === 1 || this.idusuariotipo === 2){
      this.acessoadmdisponivel = true
    }
    await this.requisitarOperadoras();
    await this.requisitarAgencias();
    await this.requisitarHoteis();
    await this.requisitarStatus();
    const { id } = this.$route.params;
    const params = id.split("&");
    const idreserva = params[0];
    const idhotel = params[1];
    this.reserva = await this.requisitarReserva(idreserva, idhotel);

    const solicitacoesDisponivel = await this.requisitarSolicitacoes(idhotel);
    this.solicitacoes = solicitacoesDisponivel.map((soli) => {
      return {
        ...soli,
        solicitado: this.reserva.solicitacoes.filter(
          (el) => el.idtiposolicitacao === soli.idtiposolicitacao
        )[0].solicitado,
      };
    });
    this.nome = this.reserva.nomehospede;
    this.sobrenome = this.reserva.sobrenomehospede;
    this.email = this.reserva.email;
    this.celular = this.reserva.celular;
    this.instagram = this.reserva.instagram;
    this.facebook = this.reserva.facebook;
    this.linkedin = this.reserva.linkedin;

    this.hotel = this.reserva.idhotel;
    this.agencia = this.reserva.idagencia;
    this.checkin = this.splitDate(this.reserva.datacheckin, "date");
    this.checkout = this.splitDate(this.reserva.datacheckout, "date");
    this.dadosmensagem = this.reserva.dadosmsg;

    this.chegada = this.splitDate(this.reserva.datacheckin, "time");
    this.partida = this.splitDate(this.reserva.datacheckout, "time");
    this.operadora = this.reserva.idoperadora;

    this.statusselecionado = this.reserva.idreservastatus;
    this.statusinicial = this.reserva.idreservastatus;


  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.formularioCadastroReserva {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}
.fields-container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
.span-error {
  width: 100%;
  font-size: 12px;
  color: red;
  text-align: left;
}

.item-checkbox-checked {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  height: 4rem;
  border-radius: 1rem;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: green;
  margin-bottom: 1rem;

  label {
    cursor: pointer;
    color: white;
    font-weight: bold;
  }
  input {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
    transition: 0.2s ease-in-out;
    outline: none;
  }
  input[type="checkbox"] {
    // or change the colour
    filter: hue-rotate(277deg);
  }
}
.item-checkbox {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  height: 4rem;
  border-radius: 1rem;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: #dee4e6;
  margin-bottom: 1rem;

  label {
    cursor: pointer;
  }
  input {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
    transition: 0.2s ease-in-out;
    outline: none;
  }
  input[type="checkbox"] {
    // or change the colour
    filter: hue-rotate(277deg);
  }
}
.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}


.mensagem-wrapper{
  background: rgba(222,228,230,.5);
}

.mensagem-header{
  display: flex;
}

.mensagem-box{
  display: flex;
  flex-direction: column;
  max-height: 35em;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;  /* Chrome, Safari and Opera */
  }
  overscroll-behavior: none;
}

.mensagem{
  padding: .5em 1em;
  width: fit-content;
  max-width: 20em;
  color: white;
  letter-spacing: 0.02em;
  font-weight: 600;
  word-wrap: break-word;
  display:flex;
  flex-direction: column;
  .mensagem-data{
    font-size: 12px;
  }
  &.enviada{
    align-self: flex-end;
    background-color: #0f88f2;
    border-radius: .7125em 0 .7125em .7125em;
    .mensagem-data{
      align-self: flex-end;
    }
  }

  &.recebida{
    align-self: flex-start;
    background-color: #0B64B3;
    border-radius: 0 .7125em .7125em .7125em;
    .mensagem-data{
      align-self: flex-start;
    }
  }
}

.input-send-wrapper{
  display: flex;
  align-items: center;
  >input{
    flex: 1;
    border-radius: 1em;
    height: 100%;
    background: #C6CACC;
    height: 3em;
    margin-right: .5em;
    outline: none;
    padding-left: 1em;
  }
}

.carregando-mensagem{
  animation: carregando-mensagem 1s infinite alternate; 
}

@keyframes carregando-mensagem {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(-360deg);
  }  
}

.send-button, .refresh-button{
  border-radius: 100%;
  width: 3em;
  height: 3em;
  background: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  > .unicon{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.send-icon{
  color: white;
}
</style>
