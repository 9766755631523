<template class="template">
  <form id="agenteForm" class="agenteForm" autocomplete="off">
    <v-card class="card-shadow" width="100%">
      <v-row class="card-padding pb-0 mb-0 d-flex align-center">
        <v-col cols="6" lg="6" class="d-flex flex-column py-0">
         
        </v-col>
        <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
           <button 
             @click="link('/list/promoter')"
             type='button'
             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
        </v-col>
      </v-row>
                    <v-row class="card-padding pt-0 mt-0 d-flex flex-row align-end">
        <v-col cols="6" lg="6" md="6" sm="6" class="">
          <Dropdown
            :options="status"
            :multiple="false"
            placeholder="Selecione um status"
            label="status"
            v-model="statusselecionado"
            id="status-select"
            :initialValue="statusselecionado"
            :border="'2px solid rgba(15, 136, 242, 0.3)'"
            :borderactive="'2px solid rgba(15, 136, 242)'"
            :borderradius="'0.7125rem'"
            :icon="'ni ni-briefcase-24'"
          />
        </v-col>
        <v-col cols="6" lg="2" md="2" sm="2" xs="2" class="px-0">
         <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    width="145"
                    class="
                      font-weight-600
                      text-capitalize
                      btn-primary
                      py-0
                      px-6
                      rounded-sm
                      mt-7
                    "
                    outlined
                    color="#0f88f2"
                    data-cy="btn-envio-status"
                    @click="atualizarStatus"
                    >status</v-btn
                  >
        </v-col>
      </v-row> 
      <v-card-text class="card-padding">
        <v-row class="">
          <v-col md="6" sm="12" xs="12">
            <Input
              name="nome"
              label="nome"
              v-model="nome"
              :input="true"
              icon="ni ni-circle-08"
              placeholder="Insira seu nome"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="email"
              label="email"
              v-model="email"
              :input="true"
              icon="ni ni-email-83"
              placeholder="Insira seu email"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col md="6" sm="12" xs="12">
            <Input
              name="cpf"
              label="cpf"
              v-model="cpf"
              v-mask="'###.###.###-##'"
              :input="true"
              icon="ni ni-badge"
              placeholder="Insira seu cpf"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="data"
              label="data"
              v-model="data"
              :input="true"
              type="date"
              icon="ni ni-calendar-grid-58"
              placeholder=""
            />
          </v-col>
        </v-row>
                        <v-row class="">
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            
            <Dropdown
            label="País"
              :multiple="false"
              :options="paises"
              id="pais-select"
              placeholder="Selecione um pais"
              v-model="paisselecionado"
              :initialValue="paisselecionado"
              :reset="paisreset"
              :sideEffect="handleChangePais"
              data-cy="pais"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            
            <Dropdown
              :multiple="false"
              :options="estados"
              id="estado-select"
              label="Estado"
              placeholder="Selecione um estado"
              v-model="estadoselecionado"
              :initialValue="estadoselecionado"
              :reset="estadoreset"
              :sideEffect="handleChangeEstados"
              data-cy="estado"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            
            <Dropdown
              :multiple="false"
              :options="cidades"
              label="Cidade"
              id="cidade-select"
              placeholder="Selecione uma cidade"
              v-model="cidadeselecionado"
              :initialValue="cidadeselecionado"
              :reset="cidadereset"
              :sideEffect="handleChangeCidades"
              data-cy="cidade"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="6" xs="6">
            
            <Dropdown
              :multiple="true"
              :options="zonas"
              id="zonas-select"
              label="Zonas"
              placeholder="Selecione uma zona"
              v-model="zona"
              data-cy="zonas"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-square-pin'"
              :ableToSearch="true"
              :initialValue="zona"
              v-if="zonas.length > 0"
            />
          </v-col>
        </v-row>
         <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      color="#0f88f2"
      data-cy="btn-envio"
      @click="atualizarPromotor"
      :loading="reqloading"
      :disabled="reqloading"
      >atualizar</v-btn
    >
      </v-card-text>
    </v-card>

    <button type="reset" id="btn-reset" hidden />

   
    <span v-if="!foiValidado" hidden data-cy="data-cy-error" />
  </form>
</template>

<script>
import PromotorServico from "../../../servicos/agente.servico";
import LocalizacaoServico from "../../../servicos/localizacao.servico";
import { alertar } from "@/servicos/notificacao.servico.js";
import validarCpf from "../../../servicos/validarCpf";
import Dropdown from "../../../components/Dropdown.vue";
import Input from "../../../components/Input.vue";
import UsuarioServico from '../../../servicos/usuario.servico.js'
export default {
  components: { Dropdown, Input },
  name: "LeituraPromotor",
  data() {
    return {
      nome: null,
      email: null,
      cpf: null,
      data: null,
      idusuario: null,
      promotor: {},
      foiValidado: true,
      status: [],
      statusselecionado: 0,
      zonas: [],
      zona: null,
      paisselecionado : 0,
      paises: [],
      estadoselecionado: 0,
      estados: [],
      cidadeselecionado: 0,
      cidades: [],
      endereco: [],
      reqloading: false
    };
  },
  methods: {
    async atualizarPromotor() {
      if (this.validarCampos()) {
        this.reqloading = true
        const cpfNewFormat = this.cpf
          .replace(".", "")
          .replace(".", "")
          .replace("-", "");

        let dadosusuario = {
          idusuario: this.idusuario,
          email: this.email,
          nome: this.nome,
          datanascimento: this.data,
          iddocumentotipo: 1,
          numerodocumento: cpfNewFormat,
          idstatususuario: this.statusselecionado,
          statususuario: this.status.filter(
            (el) => el.value === this.statusselecionado
          )[0].nome,
          enderecos: [
            {
              idendereco: this.endereco[0].idendereco,
              idpais: parseInt(this.paisselecionado),
              idestado: parseInt(this.estadoselecionado),
              idcidade: parseInt(this.cidadeselecionado),
              idzona: this.zona
            },
          ],
        };
        PromotorServico.atualizar(dadosusuario)
          .then((resp) => {
            if (resp.status === 200) {
              this.reqloading = false
              alertar("success", "=)", "Usuario ataulizado com Sucesso!");
            }
          })
          .catch((error) => {
            this.reqloading = false
            alertar("warning", "=(", error.response.data.msg);
          });
      }
    },
    async atualizarStatus(){
              let dadosusuario = {
          idusuario: this.idusuario,
          email: this.email,
          nome: this.nome,
          datanascimento: this.data,
          iddocumentotipo: 1,
          idstatususuario: this.statusselecionado,
          statususuario: this.status.filter(
            (el) => el.value === this.statusselecionado
          )[0].nome,
          enderecos: [
            {
              idendereco: this.endereco[0].idendereco,
              idpais: parseInt(this.paisselecionado),
              idestado: parseInt(this.estadoselecionado),
              idcidade: parseInt(this.cidadeselecionado),
              idzona: this.zona
            },
          ],
        };
        PromotorServico.atualizar(dadosusuario)
          .then((resp) => {
            if (resp.status === 200) {
              alertar("success", "=)", "Status do Usuario atualizado com Sucesso!");
            }
          })
          .catch((error) => {
            alertar("warning", "=(", error.response.data.msg);
          });
    },
    validarCampos() {
      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.email || this.email.trim() == "") {
        alertar("warning", "Informe um Email Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.cpf || this.cpf.trim() == "") {
        alertar("warning", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      } else if (!validarCpf(this.cpf)) {
        alertar("warning", "Informe um CPF Válido!");
        this.foiValidado = false;
        return false;
      }

      if (!this.data || this.data.trim() == "") {
        alertar(
          "warning",
          "Informe um Data de Nascimento Válida!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      } else {
        const currDate = new Date(this.data);
        if (currDate > 0 === false) {
          alertar(
            "warning",
            "Informe um Data de Nascimento Válida!",
            "Campo Obrigatório!"
          );
          this.foiValidado = false;
          return false;
        }
      }

      if (!this.statusselecionado || this.statusselecionado == 0) {
        alertar("warning", "Informe um status Válido!", "Campo Obrigatório!");
        return false;
      }
      return true;
    },
 async fetchPaises() {
      this.paisselecionado = 0;
      const response = await LocalizacaoServico.paises({});
      this.paises = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.paises.push({ nome: p.nome, value: p.idpais });
      });
      this.cidades = [{ nome: "", value: 0 }];
    },
    async fetchEstados(e) {
      this.estadoselecionado = 0;
      const response = await LocalizacaoServico.estados({
        idpais: this.paisselecionado,
      });
      this.estados = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.estados.push({ nome: p.nome, value: p.idestado });
      });
    },
    async fetchCidades(e) {
    this.cidadeselecionado = 0;
      const response = await LocalizacaoServico.cidades({
        idestado: this.estadoselecionado,
      });
      this.cidades = [];
      response.data.map((p) => {
        this.cidades.push({ nome: p.nome, value: p.idcidade, zonas: p.zonas });
      });
    },
    handleChangePais(e) {
      this.fetchEstados(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "", value: 0 }];
      this.cidadereset = !this.cidadereset;
      this.estadoselecionado = 0;
      this.estados = [{ nome: "", value: 0 }];
      this.estadoreset = !this.estadoreset;
    },
    handleChangeEstados(e) {
      this.cidadereset = !this.cidadereset;
      this.fetchCidades(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "", value: 0 }];
    },
    async handleChangeCidades() {
      const zonas = this.cidades.filter(
        (el) => el.value == this.cidadeselecionado
      )[0].zonas;
      if(zonas !== null){
        this.zonas = zonas.map((zona) => ({
        nome: zona.nome,
        value: zona.id,
      }));
      this.zona = await this.requisitarZonaUsuario()
      }else{
        this.zonas = []
        this.zona = null
      }
    },
    async requisitarZonaUsuario(){
      const {data} = await LocalizacaoServico.listarZonaPorUsuario(this.promotor.idpessoa)
      return data.map(zona => zona.idzona)
      //pegar as zonas associadas ao idpessoa desse usuario na tabela pessoazona ela em inserir em this.zona
    },
    async requisitarEndereco(){
      const {data} = await UsuarioServico.listarEnderecoPorUsuario(this.promotor.idusuario)
      this.endereco = data
      //idendereco
    },
    async requisitarPromotor(id) {
      const { data } = await PromotorServico.listar(id);
      return data[0];
    },
    link(to) {
      this.$router.push(to);
    },
    async requisitarStatus() {
      const { data } = await PromotorServico.status();
      this.status = data.map((status) => ({
        nome: status.nome,
        value: status.idstatususuario,
      }));
    },
  },
  async mounted() {
    await this.requisitarStatus();
    const { id } = this.$route.params;
    this.promotor = await this.requisitarPromotor(id);
    await this.requisitarEndereco()

    this.nome = this.promotor.nome;
    this.email = this.promotor.email;
    this.cpf = this.promotor.numerodocumento;

    this.data = this.promotor.datanascimento.substring(0, 10);

    await this.fetchPaises();
    this.paisselecionado = parseInt(this.promotor.idpais) || 0;
    await this.fetchEstados();
    this.estadoselecionado = parseInt(this.promotor.idestado) || 0;
    await this.fetchCidades();
    this.cidadeselecionado = parseInt(this.promotor.idcidade) || 0;
    const zonas = this.cidades.filter(el => el.value === this.cidadeselecionado)[0].zonas
    if(zonas !== null){
      this.zonas = zonas.map(zona => ({nome: zona.nome, value: zona.id}))
    }else{
      this.zonas = []
    }
    this.zona = await this.requisitarZonaUsuario()
    this.idusuario = this.promotor.idusuario;

    this.statusselecionado = this.promotor.idstatususuario;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.agenteForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}
.fields-container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
.span-error {
  width: 100%;
  font-size: 12px;
  color: red;
  text-align: left;
}
.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}
</style>






