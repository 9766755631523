<template class="template">
<span>
    <form id="agenteForm" class="agenteForm" autocomplete="off" v-if="!loading">
    <v-card class="card-shadow" width="100%">
      <h2 class="ml-5 mt-2">Cadastro de Agente</h2>
      <v-card-text class="card-padding">
        <v-row class="">
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <Input
              name="nome"
              label="nome"
              v-model="nome"
              :input="true"
              icon="ni ni-circle-08"
              placeholder="Insira seu nome"
            />
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <Input
              name="email"
              label="email"
              v-model="email"
              :input="true"
              icon="ni ni-email-83"
              placeholder="Insira seu email"
            />
          </v-col>
        </v-row>

        <v-row class="">
                    <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <Input
              name="cpf"
              label="cpf"
              v-model="cpf"
              v-mask="'###.###.###-##'"
              :input="true"
              icon="ni ni-badge"
              placeholder="Insira seu cpf"
            />
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <Input
              name="data"
              label="data de nascimento"
              v-model="data"
              :input="true"
              type="date"
              icon="ni ni-calendar-grid-58"
              placeholder="Insira sua data de nascimento"
            />
          </v-col>
        </v-row>

        <v-row class="">
                    <v-col cols="12" lg="6" md="6" sm="12" xs="12">

            <Input
              name="telefone"
              label="telefone"
              v-model="telefone"
              v-mask="'(##) ####-####'"
              type="tel"
              :input="true"
              icon="ni ni-mobile-button"
              placeholder="Insira seu telefone"
            />
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <Input
              name="celular"
              label="celular"
              v-model="celular"
              v-mask="'(##) # ####-####'"
              type="tel"
              :input="true"
              icon="ni ni-mobile-button"
              placeholder="Insira seu celular"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            
            <Dropdown
            label="País"
              :multiple="false"
              :options="paises"
              id="pais-select"
              placeholder="Selecione um pais"
              v-model="paisselecionado"
              :reset="paisreset"
              :sideEffect="handleChangePais"
              data-cy="pais"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            
            <Dropdown
              :multiple="false"
              :options="estados"
              id="estado-select"
              label="Estado"
              placeholder="Selecione um estado"
              v-model="estadoselecionado"
              :reset="estadoreset"
              :sideEffect="handleChangeEstados"
              data-cy="estado"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            
            <Dropdown
              :multiple="false"
              :options="cidades"
              label="Cidade"
              id="cidade-select"
              placeholder="Selecione uma cidade"
              v-model="cidadeselecionado"
              :reset="cidadereset"
              :sideEffect="handleChangeCidades"
              data-cy="cidade"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>

          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            
            <Dropdown
              :multiple="false"
              :options="agencias"
              id="agencia-select"
              placeholder="Selecione uma agência"
              v-model="agenciaselecionado"
              data-cy="agencia"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-briefcase-24'"
              label="Agência"
            />
          </v-col>
        </v-row>
                <v-row class="">
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            
            <Dropdown
              :multiple="true"
              :options="zonas"
              id="zonas-select"
              label="Zonas"
              placeholder="Selecione uma zona"
              v-model="zona"
              data-cy="zonas"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-square-pin'"
              :ableToSearch="true"
              v-if="zonas.length > 0"
            />
          </v-col>
        </v-row>

        <v-row class="">
                    <v-col cols="12" lg="6" md="6" sm="12" xs="12">

            <Input
              name="senha"
              label="senha"
              v-model="senha"
              type="password"
              :input="true"
              icon="ni ni-lock-circle-open"
              placeholder="Insira seu senha"
            />
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <Input
              name="senha2"
              label="senha2"
              v-model="senha2"
              type="password"
              :input="true"
              icon="ni ni-lock-circle-open"
              placeholder="Confirme sua senha"
            />
          </v-col>

          <!-- <v-checkbox
                v-model="checkbox"
                color="#0f88f2"
                :ripple="false"
                class="ma-0 checkbox-custom checkbox-thinner"
                hide-details
              >
                <template v-slot:label>
                  <span class="text-muted text-body-2 ls-0"
                    >Li e Estou de Acordo com o
                    <a
                      href="javascript:;"
                      class="text-primary text-decoration-none"
                      >Termo de Privacidade</a
                    >
                  </span>
                </template>
              </v-checkbox> -->
        </v-row>
      </v-card-text>
    </v-card>

    <button type="reset" id="btn-reset" hidden />

    <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      color="#0f88f2"
      data-cy="btn-envio"
      :disabled="reqloading"
      :loading="reqloading"
      @click="cadastrarAgente()"
      >Cadastrar</v-btn
    >
    <span v-if="!foiValidado" hidden data-cy="data-cy-error" />
  </form>
  <FormSkeleton v-if="loading"/>
</span>
</template>

<script>
import UsuarioServico from "../../../servicos/usuario.servico";
import AgenciaServico from "../../../servicos/agencia.servico";
import LocalizacaoServico from "../../../servicos/localizacao.servico";
import { alertar } from "@/servicos/notificacao.servico.js";
import validarCpf from "../../../servicos/validarCpf";
import Dropdown from "../../../components/Dropdown.vue";
import Input from "../../../components/Input.vue";
import FormSkeleton from "../../../components/Loading/FormSkeleton.vue";
import EmailServico from '../../../servicos/email.servico.js'
import NotificacaoServico from '../../../servicos/notificacao.servico.js'

export default {
  components: { Dropdown, Input, FormSkeleton },
  name: "CadastroAgente",
  watch: {
    paisselecionado: function (val) {
      this.fetchEstados();
    },
  },
  data: () => ({
    loading: false,
    agencias: [],
    nome: null,
    email: null,
    cpf: null,
    data: null,
    telefone: null,
    celular: null,
    senha: null,
    senha2: null,
    paisselecionado: 0,
    estadoselecionado: 0,
    cidadeselecionado: 0,
    agenciaselecionado: 0,
    paises: [],
    estados: [],
    cidades: [],
    paisreset: false,
    estadoreset: false,
    cidadereset: false,
    foiValidado: true,
    zonas: [],
    zona: null,
    loading: false,
    reqloading: false,
  }),
  methods: {
    async cadastrarAgente() {
      if (this.validarCampos()) {
        this.reqloading = true 
        const cpfNewFormat = this.cpf
          .replace(".", "")
          .replace(".", "")
          .replace("-", "");

        let dadosusuario = {
          idusuariotipo: 4,
          idstatususuario: 1,
          email: this.email,
          senha: this.senha,
          nome: this.nome,
          datanascimento: this.data,
          iddocumentotipo: 1,
          numerodocumento: cpfNewFormat,
          idagencia: parseInt(this.agenciaselecionado),
          enderecos: [
            {
              idpais: parseInt(this.paisselecionado),
              idestado: parseInt(this.estadoselecionado),
              idcidade: parseInt(this.cidadeselecionado),
              idzona: this.zona
            },
          ],
        };

        await UsuarioServico.registrar(dadosusuario)
          .then((resp) => {
            if (resp.status === 200) {
                            //enviar email
                const agencia = this.agencias.filter(el => el.value === this.agenciaselecionado)[0].nome
                EmailServico.email({
                  titulo: 'Agente Registrado',
                  conteudo: `O agente com nome ${dadosusuario.nome} e email ${dadosusuario.email} foi registrado com sucesso. Agência: ${agencia}`,
                  subject: 'Agente Registrado'
                }).then(() => console.log('email enviado')).catch(err => console.log('erro', err))
                try{
                  //enviar notificacao
                NotificacaoServico.registrar({
                  idusuario: resp.data[0].idusuario,
                  titulo: 'Bem Vindo',
                  mensagem: `Usuário criado com sucesso. email: ${resp.data[0].email}`
                }).then(() => console.log('notificacao usuario')).catch(e => console.log('error usuario'))
                NotificacaoServico.registrar({
                  idusuario: this.idusuario,
                  titulo: 'Criação de Usuário',
                  mensagem: `Usuário tipo agente criado com sucesso. id: ${resp.data[0].idusuario} email: ${resp.data[0].email}`
                }).then(() => console.log('notificacao criador')).catch(e => console.log('error criador'))
                }catch(e){
                  console.log('error notificacao', e)
                }
              this.nome = null;
              this.email = null;
              this.cpf = null;
              this.data = null;
              this.telefone = null;
              this.celular = null;
              this.senha = null;
              this.senha2 = null;
              this.paisselecionado = 0;
              this.estadoselecionado = 0;
              this.cidadeselecionado = 0;
              this.agenciaselecionado = 0;
              document.getElementById("btn-reset").click();
              alertar("success", "=)", "Usuario Criado com Sucesso!");
            }
          })
          .catch((error) => {
            alertar("warning", "=(", error.response.data.msg);
          });
          this.reqloading  = false
      }
      // else{
      //   alertar("warning","==","asda")
      // }
    },
    validarCampos() {
      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.email || this.email.trim() == "") {
        alertar("warning", "Informe um Email Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.cpf || this.cpf.trim() == "") {
        alertar("warning", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      } else if (!validarCpf(this.cpf)) {
        alertar("warning", "Informe um CPF Válido!");
        this.foiValidado = false;
        return false;
      }

      if (!this.data || this.data.trim() == "") {
        alertar(
          "warning",
          "Informe um Data de Nascimento Válida!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      } else {
        const currDate = new Date(this.data);
        if (currDate > 0 === false) {
          alertar(
            "warning",
            "Informe um Data de Nascimento Válida!",
            "Campo Obrigatório!"
          );
          this.foiValidado = false;
          return false;
        }
      }

      if (!this.telefone || this.telefone.trim() == "") {
        alertar("warning", "Informe um Telefone Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.celular || this.celular.trim() == "") {
        alertar("warning", "Informe um Celular Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.paisselecionado || this.paisselecionado == 0) {
        alertar("warning", "Informe um Pais Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.estadoselecionado || this.estadoselecionado == 0) {
        alertar("warning", "Informe um Estado Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.cidadeselecionado || this.cidadeselecionado == 0) {
        alertar("warning", "Informe uma Cidade Válida!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.agenciaselecionado || this.agenciaselecionado == 0) {
        alertar("warning", "Informe uma Agência Válida!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.senha || this.senha.trim() == "") {
        alertar("warning", "Informe uma Senha Válida!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.senha2 || this.senha2.trim() == "") {
        alertar("warning", "Repita a Senha!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (this.senha != this.senha2) {
        alertar("warning", "As Senhas não estão iguais!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      return true;
    },
    async fetchPaises() {
      this.paisselecionado = 0;
      const response = await LocalizacaoServico.paises({});
      this.paises = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.paises.push({ nome: p.nome, value: p.idpais });
      });
      this.cidades = [{ nome: "", value: 0 }];
    },
    async fetchEstados(e) {
      this.estadoselecionado = 0;
      const response = await LocalizacaoServico.estados({
        idpais: this.paisselecionado,
      });
      this.estados = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.estados.push({ nome: p.nome, value: p.idestado });
      });
    },
    async fetchCidades(e) {
    this.cidadeselecionado = 0;
      const response = await LocalizacaoServico.cidades({
        idestado: this.estadoselecionado,
      });
      this.cidades = [];
      response.data.map((p) => {
        this.cidades.push({ nome: p.nome, value: p.idcidade, zonas: p.zonas });
      });
    },
    handleChangePais(e) {
      this.fetchEstados(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "", value: 0 }];
      this.cidadereset = !this.cidadereset;
      this.estadoselecionado = 0;
      this.estados = [{ nome: "", value: 0 }];
      this.estadoreset = !this.estadoreset;
    },
    handleChangeEstados(e) {
      this.cidadereset = !this.cidadereset;
      this.fetchCidades(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "", value: 0 }];
    },
    handleChangeCidades() {
        const zonas = this.cidades.filter(
        (el) => el.value == this.cidadeselecionado
      )[0].zonas;
      if(zonas !== null){
        this.zonas = zonas?.map((zona) => ({
        nome: zona.nome,
        value: zona.id,
      }));
      }else{
        this.zonas = []
      }

    },
    async requisitarAgencias() {
      const documents = await AgenciaServico.listarTodos();
      let agencias = [];
      documents.data.map((doc) => {
        if (agencias.filter((el) => el.nome === doc.nome).length === 0) {
          agencias.push({ nome: doc.nome, value: doc.idusuario });
        }
      });
      this.agencias = agencias;
    },
  },
  async mounted() {
    this.loading = true
    await this.requisitarAgencias();
    await this.fetchPaises();
    this.loading = false
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.agenteForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}
.fields-container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
.span-error {
  width: 100%;
  font-size: 12px;
  color: red;
  text-align: left;
}
</style>
