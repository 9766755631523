import api from '@/servicos/api'

export default {
  cadastrar(dados) {
    return api().post('/hotel/regulamento', dados)
  },
  listarPorHotel(id){
    return api().post('/hotel/regulamento/select', {idhotel:id})
  },
  listar(id) {
    return api().post('/hotel/regulamento/select', { idregulamento: id })
  },
  buscar(query) {
    return api().post('/hotel/regulamento/select', query )
  },
  // listarTodos() {
  //   return api().post('/hotel/regulamento/select')
  // },
  atualizar(body) {
    return api().put('/hotel/regulamento', body)
  }
}

