<template>
  <v-row class="d-flex justify-center mt-n16">
    <v-col cols="12" md="7">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <v-row>
            <v-col cols="12" class="mt-n3">
              <v-card class="card-shadow">
                <v-card-text style="display: flex; justify-content: center">
                  <v-btn
                    elevation="0"
                    @click="tipo = 'agente'"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-600
                      text-capitalize
                      btn-primary
                      py-3
                      px-6
                      rounded-sm
                      my-6
                      ml-3
                    "
                    color="#0f88f2"
                    data-cy="btn-agente"
                    >Sou Agente</v-btn
                  >

                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    @click="tipo = 'agencia'"
                    class="
                      font-weight-600
                      text-capitalize
                      btn-primary
                      py-3
                      px-6
                      rounded-sm
                      my-6
                      ml-3
                    "
                    color="#0f88f2"
                    data-cy="btn-agencia"
                    >Nova Agência</v-btn
                  >

                
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>


          <div class="formWrapper" v-if="tipo === 'agente'">
            <CadastroAgente />
          </div>
          <div class="formWrapper" v-if="tipo === 'agencia'">
            <CadastroAgencia />
          </div>

          <v-card-text class="pa-12 text-center font-size-root">
            <v-checkbox
              v-model="checkbox"
              color="#0f88f2"
              :ripple="false"
              class="ma-0 checkbox-custom checkbox-thinner"
              hide-details
            >
              <template v-slot:label>
                <span class="text-muted text-body-2 ls-0"
                  >Li e Estou de Acordo com o
                  <a
                    href="javascript:;"
                    class="text-primary text-decoration-none"
                    >Termo de Privacidade</a
                  >
                </span>
              </template>
            </v-checkbox>

          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import CadastroAgente from "./../Components/Cadastros/CadastroAgente.vue";
import CadastroAgencia from "./../Components/Cadastros/CadastroAgencia.vue";
import CadastroAdmHotel from "./../Components/Cadastros/CadastroAdmHotel.vue";

export default {
  name: "register",
  components: {
    CadastroAgente,
    CadastroAgencia,
    CadastroAdmHotel
  },
  data() {
    return {
      checkbox: false,
      tipo: "agente",
    };
  },
};
</script>
