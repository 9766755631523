<template>
  <div class="page-wrapper">
    <v-col cols="12" lg="10" md="7">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <CadastroAgente />
        </v-card>
      </v-form>
    </v-col>
  </div>
</template>



<script>
import CadastroAgente from "../Components/Cadastros/CadastroAgente.vue";
export default {
  name: "RegisterAgent",
  components: {
    CadastroAgente,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.page-wrapper {
  display: flex;
  justify-content: center;
}
</style>
