<template>
  <div class="wrapper">
      <section class="section-info" v-if="!loading">
      <div class="grupo">       
        <div class="chamada-wrapper">
          <span class="chamada-header">
            <unicon name="gift" />
            <h1>Escolha uns dos premios abaixo</h1>
          </span>
          <span class="chamada-input">
           <Dropdown
              placeholder="Selecione um hotel"
              :options="hoteis"
              v-model="hotel"
              :initialValue="hotel"
              id="hotel"
              label="Hotel"
              :sideEffect="alterarcaoCampoHotel"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :reset="hotelreset"
            />
          </span>
        </div>
        <div class="request-wrapper">
          <div
            :class="vantagem.available ? 'request' : 'request not-available'"
            v-for="vantagem in vantagens"
            :key="vantagem.id"
            :data-cy="`${vantagem.name.toLowerCase()}`"
            @click="trocarPremio(vantagem.available, vantagem.id)"
          >
            <unicon :name="vantagem.icon" v-if="vantagem.icon" />
            <div class="request-info">
              <h1>{{ vantagem.name }}</h1>
              <h1>({{ vantagem.score }} pontos)</h1>
            </div>
            <form 
              class="form-request-wrapper" 
              v-if="expandido === vantagem.id">
              <span class="form-header">
                <h1>{{ vantagem.form.title }}</h1>
                <h4>{{ vantagem.form.subtitle }}</h4>
              </span>

              <div
                v-for="(field, idx) in vantagem.form.fields"
                :class="field.textarea ? 'form-field-textarea' : 'form-field'"
                :key="idx"
              >
                <label :for="field.name">{{ field.name }}:</label>
                <span class="field">
                  <v-icon size="22" v-if="field.icon">
                    {{ field.icon }}
                  </v-icon>
                  <input
                    :type="field.type"
                    :name="field.name"
                    :data-cy="field.name"
                    v-mask="field.mask"
                    v-model="field.vmodel"
                    autocomplete="off"
                    :placeholder="field.placeholder"
                    :min="field.min"
                    :max="field.max"
                    v-if="field.input"
                    :disabled="field.disabled"
                    required
                  />
                  <textarea
                    :data-cy="field.name"
                    :name="field.name"
                    v-model="field.vmodel"
                    autocomplete="off"
                    :placeholder="field.placeholder"
                    v-if="field.textarea"
                    required
                  />
                  <Dropdown
                    v-if="field.select"
                    v-model="field.vmodel"
                    :options="field.options"
                    :multiple="false"
                    :border="'none'"
                    :borderradius="'0.7125rem'"
                    :placeholder="field.placeholder"
                    :initialValue="field.initialValue"
                    :disabled="field.disabled"
                    :reset="resetarDropdown"
                    required
                  />
                </span>
                <span class="error">{{ field.error }}</span>
              </div>
              <div class="form-field submit">
                      <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      data-cy="btn-envio"
      color="#0f88f2"
      :disabled="reqloading"
      :loading="reqloading"
       @click="(e) => enviarSolicitacao(e, vantagem)" 
      >requisitar</v-btn
    >
              </div>
            </form>
          </div>
          <span class="request-aviso" v-if="vantagens.length === 0">nenhum hotel foi selecionado</span>
        </div>
      </div>
      <div class="info-wrapper">
          <div class="agente-info">
            <unicon class="thumb-icon" name="scenery" />
            <div class="agente-chip">
              <p>Nome:</p>
              <h1>{{ nomeagente }}</h1>
            </div>
            <div class="agente-chip">
              <p>Nível:</p>
              <h1>Bronze</h1>
            </div>
          </div>
          <div class="resume-info">
            <div class="resume-chip" v-if="pontosdisponiveis !== null">
              <unicon name="thumbs-up" />{{pontosdisponiveis}} pontos disponíveis
            </div>           
          </div>
      </div>
      </section>   
      <FormSkeleton v-if="loading"/>   
  </div>
</template>
<script>
import Dropdown from "../../../components/Dropdown.vue";
import HotelServico from "../../../servicos/hotel.servico.js";
import AgenciaServico from "../../../servicos/agencia.servico.js";
import PremioServico from "@/servicos/premio.servico.js";
import SolicitacaoServico from '@/servicos/solicitacao.servico.js'
import {alertar} from '@/servicos/notificacao.servico.js'
import FormSkeleton from '../../../components/Loading/FormSkeleton'
function getRandom(max, restriction) {
  if (restriction) {
    let random = Math.floor(Math.random() * max + 1);
    while (restriction.includes(random)) {
      random = Math.floor(Math.random() * max + 1);
      if (!restriction.includes(random)) {
        continue;
      }
    }
    return random;
  } else {
    return Math.floor(Math.random() * max + 1);
  }
}
export default {
  name:"CadastroSolicitacao",
  components: {
    Dropdown,
    FormSkeleton
  },
  data() {
    return {
      expandido: null,
      vantagens: [],
      idagencia: null,
      idusuario: null,
      nomeagente: null,
      resetarDropdown: false,
      hotel: null,
      hoteis: [],
      loading: false,
      pontosdisponiveis: null,
      reqloading: false,
    };
  },
  watch: {
    expandido() {
      this.vantagens.map((vantagem) => {
        vantagem.form.fields.map(async (field) => {
          if (field.name === "agencia") {
            this.resetarDropdown = false
            field.options = await AgenciaServico.listarTodos().then((res) =>
              res.data.map((item) => {
                return {
                  nome: item.nome,
                  value: item.idusuario,
                };
              })
            );
            field.initialValue = parseInt(this.idagencia);
            field.vmodel = parseInt(this.idagencia);
          }
          if (field.name === "hotel") {
            field.options = await HotelServico.listarTodos().then((res) =>
              res.data.map((item) => {
                return {
                  nome: item.nome,
                  value: item.idhotel,
                };
              })
            );
          }
          if (field.name === "nome") {
            field.vmodel = this.nomeagente;
          }
        });
      });
    },
  },
  methods: {
    async requistarPremios() {
      const { data } = await PremioServico.listarPorHotel(this.hotel);
      let premios = new Array();
      let normal = ({ name, title, available, idpremio, score }) => {
        return {
          id: getRandom(100),
          name: name,
          score: score,
          available: available,
          type: 'normal',
          idpremio: idpremio,
          form: {
            title: title,
            subtitle: "Preencha todos os campos abaixo",
            fields: [
              {
                name: "nome",
                type: "text",
                vmodel: null,
                icon: "ni ni-circle-08",
                placeholder: "Insira seu nome",
                input: true,
                disabled: true,
              },
              {
                name: "comentario",
                type: "text",
                vmodel: null,
                icon: "",
                placeholder: "Insira seu comentário",
                textarea: true,
              },
            ],
            validacao: (dados) => {
              let validou = true
              dados.map(attr => {
                if(attr.name === 'nome'){
                  if(!attr.vmodel || attr.vmodel.trim() == ''){
                    alertar("warning", "Informe um nome válido!", "Campo Obrigatório!");
                    validou = false;
                  }
                }
                if(attr.name === 'comentario'){
                  if(!attr.vmodel || attr.vmodel.trim() == ''){
                    alertar("warning", "Informe um cometario válido!", "Campo Obrigatório!");
                    validou = false;
                  }
                }
              })
              return validou
            }
          },
        };
      };
      let jantar = ({ name, title, available,idpremio, score }) => {
        return {
          id: getRandom(100),
          name: name,
          score: score,
          available: available,
          type: 'jantar',
          idpremio:idpremio,
          form: {
            title: title,
            subtitle: "Preencha todos os campos abaixo",
            fields: [
              {
                name: "nome",
                type: "text",
                vmodel: null,
                icon: "ni ni-circle-08",
                placeholder: "Insira seu nome",
                input: true,
              },
              {
                name: "data",
                type: "date",
                vmodel: null,
                icon: "ni ni-calendar-grid-58",
                placeholder: "Insira a data",
                input: true,
              },
              {
                name: "quantidade",
                max: 3,
                min: 1,
                type: "number",
                vmodel: null,
                icon: "ni ni-single-02",
                placeholder: "Insira a quantidade de pessoas",
                input: true,
              },
              {
                name: "comentario",
                type: "text",
                vmodel: null,
                icon: "",
                placeholder: "Insira seu comentário",
                textarea: true,
              },
            ],
            validacao: (dados) => {
              let validou = true
              dados.map(attr => {
                if(attr.name === 'quantidade'){
                  if(!attr.vmodel || attr.vmodel>2|| attr.vmodel===0){
                    alertar("warning", "Informe uma quantidade de pessoas valida. de 0 a 2!", "Campo Obrigatório!");
                    validou = false;
                  }
                }
                if(attr.name === 'data'){
                  if(!attr.vmodel || attr.vmodel.trim() == "" || !(new Date(attr.vmodel) > 0)){
                    alertar("warning", "Informe um checkin válido!", "Campo Obrigatório!");
                    validou = false;
                  }
                }
                if(attr.name === 'nome'){
                  if(!attr.vmodel || attr.vmodel.trim() == ""){
                    alertar("warning", "Informe um nome válido!", "Campo Obrigatório!");
                    validou = false;
                  }
                }
                if(attr.name === 'comentario'){
                  if(!attr.vmodel || attr.vmodel.trim() == ""){
                    alertar("warning", "Informe um comentario válido!", "Campo Obrigatório!");
                    validou = false;
                  }
                }
                
              })
              return validou
            }
          },
        };
      };
      let hospedagem = ({ name, title, available,idpremio, score }) => {
        return {
          id: getRandom(100),
          name: name,
          score: score,
          available: available,
          type: 'hospedagem',
          idpremio:idpremio,
          form: {
            title: title,
            subtitle: "Preencha todos os campos abaixo",
            fields: [
              {
                name: "nome",
                type: "text",
                vmodel: null,
                icon: "ni ni-circle-08",
                placeholder: "Insira seu nome",
                input: true,
                disabled: true,
              },
              // {
              //   name: "sobrenome",
              //   type: "text",
              //   vmodel: null,
              //   icon: "ni ni-hat-3",
              //   placeholder: "Insira seu sobrenome",
              //   input: true,
              // },

              // {
              //   name: "cpf",
              //   type: "text",
              //   mask: "###.###.###-##",
              //   vmodel: null,
              //   icon: "ni ni-badge",
              //   placeholder: "Insira seu cpf",
              //   input: true,
              // },
              {
                name: "quantidade",
                max: 3,
                min: 1,
                type: "number",
                vmodel: null,
                icon: "ni ni-single-02",
                placeholder: "Insira a quantidade de pessoas",
                input: true,
              },
              {
                name: "checkin",
                type: "date",
                vmodel: null,
                icon: "ni ni-calendar-grid-58",
                placeholder: "Insira a data",
                input: true,
              },
              {
                name: "checkout",
                type: "date",
                vmodel: null,
                icon: "ni ni-calendar-grid-58",
                placeholder: "Insira a data",
                input: true,
              },
              // {
              //   name: "hotel",
              //   type: "text",
              //   vmodel: null,
              //   icon: "ni ni-building",
              //   placeholder: "Escolha o hotel",
              //   select: true,
              //   options: this.hoteis
              // },
              // {
              //   name: "agencia",
              //   type: "text",
              //   vmodel: null,
              //   icon: "ni ni-briefcase-24",
              //   placeholder: "Insira sua agência",
              //   select: true,
              //   options: [],
              //   initialValue: this.idagencia,
              //   disabled: true,
              // },
              {
                name: "comentario",
                type: "text",
                vmodel: null,
                icon: "",
                placeholder: "Insira seu comentário",
                textarea: true,
              },
            ],
            validacao: (dados) => {
              let validou = true
              dados.map(attr => {
                if(attr.name === 'quantidade'){
                  if(!attr.vmodel || attr.vmodel>2|| attr.vmodel===0){
                    alertar("warning", "Informe uma quantidade de pessoas valida. de 0 a 2!", "Campo Obrigatório!");
                    validou = false;
                  }
                }
                if(attr.name === 'checkin'){
                  if(!attr.vmodel || attr.vmodel.trim() == "" || !(new Date(attr.vmodel) > 0)){
                    alertar("warning", "Informe um checkin válido!", "Campo Obrigatório!");
                    validou = false;
                  }
                }
                if(attr.name === 'checkout'){
                  if(!attr.vmodel || attr.vmodel.trim() == "" || !(new Date(attr.vmodel) > 0)){
                    alertar("warning", "Informe um checkout válido!", "Campo Obrigatório!");
                    validou = false;
                  }
                }
                if(attr.name === 'comentario'){
                  if(!attr.vmodel || attr.vmodel.trim() == ""){
                    alertar("warning", "Informe um comentario válido!", "Campo Obrigatório!");
                    validou = false;
                  }
                }
                
              })
              return validou
            }
          },
        };
      };
      data.map((vantagem) => {
        if (vantagem.nome.toLowerCase() === "jantar") {
          premios.push(
            jantar({
              score: vantagem.pontuacao,
              name: "Jantar",
              title: "Solicite seu Jantar",
              idpremio: vantagem.idpremio, 
              available:vantagem.ativo === true ?
                                this.nivel === vantagem.nivel ?
                                  this.pontosdisponiveis >= vantagem.pontuacao? true : false
                                :false
                        :false
            })
          );
        } else if (
          vantagem.nome.toLowerCase() === "hospedagem" ||
          vantagem.nome.toLowerCase() === "diária hotel"
        ) {
          premios.push(
            hospedagem({
              score: vantagem.pontuacao,  
              name: "Hospedagem",
              title: "Solicite sua Hospedagem",
              idpremio: vantagem.idpremio, 

              available:vantagem.ativo === true ?
                                this.nivel === vantagem.nivel ?
                                  this.pontosdisponiveis >= vantagem.pontuacao? true : false
                                :false
                        :false
            })
          );
        } else {


          premios.push(
            normal({
              score: vantagem.pontuacao,  
              name: vantagem.nome,
              title: `Solicite seu(a) ${vantagem.nome}`,
              idpremio: vantagem.idpremio, 
              available:vantagem.ativo === true ?
                                this.nivel === vantagem.nivel ?
                                  this.pontosdisponiveis >= vantagem.pontuacao? true : false
                                :false
                        :false
            })
          );
        }
      });
      this.vantagens = premios;
    },
    async enviarSolicitacao(e, data){
      e.preventDefault()
      if(data.form.validacao(data.form.fields)){
        this.reqloading = true
        let dadosusuario = new Object()
        data.form.fields.map(field => {
          dadosusuario[field.name] = field.vmodel
        })
        dadosusuario['tipo'] = data.type
        dadosusuario['idagencia'] = parseInt(this.idagencia)
        dadosusuario['idusuario'] = parseInt(this.idusuario)
        dadosusuario['idpremio'] = data.idpremio
        dadosusuario['idhotel'] = this.hotel
        
        await SolicitacaoServico.cadastrar(dadosusuario)
          .then(async res => {
            
            if (res.status === 200){
        
              this.expandido = null
              let dadosConsumoPontos = {
                "idhotel":this.hotel,
                "idagente":parseInt(this.idusuario),
                "idpremio":data.idpremio,
                "idsolicitacaopremio":res.data.idsolicitacao
              }


              await SolicitacaoServico.consumirPontos(dadosConsumoPontos).then(resp =>{
                
                if (resp.status === 200){
                    alertar('success', '=)', 'Solicitação enviada com sucesso')
                }else{
                  alertar('success', '=)', 'Não possível pontuar, isso afetará sua premiação')
                }
              }).catch(error =>{
                console.log(error)
                alertar('warning', '=/', 'Erro ao Consumir os Pontos, isso afetará sua premiação')
              })

            }
          })
          .catch(error => {
            alertar('warning', '=/', 'Erro ao enviar a solicitação')
          })
        this.reqloading = false
        }
    },
    trocarPremio(disponivel, id){

      this.resetarDropdown = true
      disponivel ? (this.expandido = id) : null
    },
    async requisitarHoteis() {
      const { data } = await HotelServico.listarTodos();
      this.hotel = 0;
      let hoteis = new Array();
      data.map((hotel) => {
        hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
      });
      this.hoteis = hoteis;
    },
    async requisitarPontosDisponiveis(idusuario, idhotel){
      const {data} = await PremioServico.saldoAgente(idusuario, idhotel)
      this.nivel = this.getNivel(data.dsnivel)
      if(data.qtdepontos){
        this.pontosdisponiveis = data.qtdepontos
      }else{
        this.pontosdisponiveis = 0
      }
    },
    async alterarcaoCampoHotel(){
      localStorage.setItem('hotel', this.hotel)
      await this.requisitarPontosDisponiveis(this.idusuario, this.hotel)
      await this.requistarPremios()
    },
    getNivel(nivel){
      switch(nivel){
        case 'Ouro':
          return 3
        case 'Prata':
          return 2
        case 'bronze':
          return 1
      }
    }
  },
  async mounted() {
    this.loading = true
    await this.requisitarHoteis()
    this.idagencia = localStorage.getItem('idagencia');
    this.idusuario = localStorage.getItem('idusuario');
    this.nomeagente = localStorage.getItem('nome');
    await this.requistarPremios();
    const currSearch = localStorage.getItem('hotel')
    if(currSearch){
      this.hotel = parseInt(currSearch)
      await this.alterarcaoCampoHotel()
    }
    this.loading = false
  },
};
</script>
<style lang="scss">
.wrapper {
  height: 100%;
  background: rgba(249, 249, 249, 0);
}

.minicards {
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
}

.section-info {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr .5fr;
  grid-gap: 1em;

  .col-1{
    width: 100%;
  }

  .info-wrapper {
    box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
    border-radius: 1rem;
    padding: 1rem;
    background: white;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .agente-info {
      height: -webkit-fill-available;
      padding: 1rem;
      box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: white;
      width: 100%;
      margin-bottom: 1rem;

      &:hover {
        cursor: pointer;
      }

      .thumb-icon.unicon {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        svg {
          width: 100%;
          height: auto;
          path {
            fill: rgba(133, 133, 133, 0.2);
          }
        }
      }

      .agente-chip {
        display: flex;
        align-items: center;

        p {
          font-size: 0.8rem;
          color: #bbb;
          margin: 0;
          margin-right: 0.5rem;
        }

        h1 {
          font-size: 1rem;
          text-transform: capitalize;
        }

        .unicon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .resume-info {
      .resume-chip {
        display: flex;
        align-items: center;
        height: -webkit-fill-available;
        padding: 1rem;
        border-radius: 1rem;
        background: white;
        margin-bottom: 0.5rem;
        .unicon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.5rem;
        }
        &:hover {
          box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
          cursor: pointer;
          z-index: 100;
        }
      }
    }
  }
  .chamada-wrapper {
    box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
    border-radius: 1rem;
    padding: 1rem;
    background: white;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: fit-content;
    h1 {
      font-size: 2em;
      line-height: 1;
    }
    .unicon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;
      svg {
        width: 100%;
        height: auto;
        path {
          fill: #0f88f2;
        }
      }
    }
    .chamada-header{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1em;
    }
    .chamada-input{
      width:100%;
    }
  }
 .request-wrapper {
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 1rem;
  padding: 1rem;
  background: white;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  margin-top: 1em;

  .request {
    width: fit-content;
    padding: 0.7125rem;
    box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    color: white;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: #0f88f2;

    &:hover {
      cursor: pointer;
      box-shadow: 0 4px 5px rgba(133, 133, 133, 0.6);
      transition: 0.2s ease-in-out;
    }

    > .unicon {
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .request-info {
      text-transform: capitalize;
      text-align: center;
      h1 {
        font-size: 1rem;
      }
      p {
        font-size: 0.8rem;
        color: #bbb;
        margin: 0;
      }
    }
  }

  .request.not-available {
    background-color: rgba(133, 133, 133, 0.2);
    color: rgba(0, 0, 0, 0.3);
    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
      transition: 0.2s ease-in-out;
      cursor: unset;
      user-select: none;
    }
  }
  .request-aviso{
    text-transform: capitalize;
    color: #bbb;
    font-weight: 600;
  }
}
  @media screen and (max-width:769px){
    grid-template-columns: 1fr;
    grid-gap: 1em;

    .grupo{
      display: flex;
      flex-direction: column;
    }

    .info-wrapper{
      grid-row: 1/2;
      grid-column: 1/2;
    }
  }

}

.form-request-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(105%);
  height: 5rem;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 1rem;
  padding: 1rem;
  background: white;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .form-header {
    width: 100%;
    margin-bottom: 2rem;

    h1 {
      font-size: 1.5rem;
      text-transform: capitalize;
      color: rgba(0, 0, 0, 0.87);
    }
    h4 {
      font-size: 0.8rem;
      color: #bbb;
    }
  }

  .form-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 47%;
    margin-bottom: 0.5rem;
    flex-grow: 0;
    margin-right: 1rem;
    font-weight: 600;
    color: rgba(0, 0, 0,1);

    > span.field {
      width: 100%;
      display: flex;
      align-items: center;
      border: 1px solid rgba(15, 136, 242, 0.3);
      border-radius: 0.7125rem;
      transition: 0.2s all;
      > i {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s all;
        margin: 0 0.7rem;
        color: rgba(15, 136, 242, 0.5);
      }
      > input,
      textarea {
        border-radius: 0.5rem;
        outline: none;
        width: 100%;
        min-height: 2.75rem;
        color: rgb(0, 0, 0);
        font-size: 14px;
        letter-spacing: 0.02rem;
      }
      > textarea {
        padding: 0.5rem;
        min-height: 2.75rem;
        resize: y;
        overflow: hidden;
      }
      &:focus-within {
        border: 1px solid rgb(15, 136, 242);
        > i {
          color: rgb(15, 136, 242);
        }
      }
    }

    label {
      font-size: 14px;
      text-transform: capitalize;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.87);
      letter-spacing: 0.02rem;
      margin-bottom: 0.5rem;
    }
    @media screen and (max-width: 768px){
      width: 100%;
    }
  }

  .form-field-textarea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0.5rem;
    flex-grow: 0;
    margin-right: 1rem;
    font-weight: 600;
    color: rgba(0, 0, 0,1);

    > span.field {
      width: 100%;
      display: flex;
      align-items: center;
      border: 1px solid rgba(15, 136, 242, 0.3);
      border-radius: 0.7125rem;
      transition: 0.2s all;
      > i {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s all;
        margin: 0 0.7rem;
        color: rgba(15, 136, 242, 0.5);
      }
      textarea {
        border-radius: 0.5rem;
        outline: none;
        width: 100%;
        min-height: 7rem;
        color: rgb(0, 0, 0);
        font-size: 14px;
        letter-spacing: 0.02rem;
        padding: 0.5rem;
        resize: y;
        overflow: hidden;
      }
      &:focus-within {
        border: 1px solid rgb(15, 136, 242);
        > i {
          color: rgb(15, 136, 242);
        }
      }
    }

    label {
      font-size: 14px;
      text-transform: capitalize;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.87);
      letter-spacing: 0.02rem;
      margin-bottom: 0.5rem;
    }
    @media screen and (max-width: 768px){
      width: 100%;
    }
  }

  .form-field.submit {
    align-self: end;
    padding-top: 1rem;
    > span.field {
      width: 100%;
      display: flex;
      align-items: center;
      background: rgb(15, 136, 242);
      border-radius: 0.7125rem;
      transition: 0.2s all;
      > input {
        color: white;
        font-weight: bold;
        letter-spacing: 0.02rem;
        text-transform: uppercase;
      }
    }
  }
}
.mr {
  margin-right: 1rem;
}

//css indesejados
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>