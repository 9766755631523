<template>
  <div>
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom" v-if="!initialLoading">
        <v-row class="d-flex justify-space-between py-5 px-5 lista-header">
          <div>
            <p class="font-weight-600 text-h3 text-typo mb-1">
              Lista de Notificacões
            </p>

          </div>
        </v-row>
      </div>
      <div class="card-header-padding card-border-bottom" v-if="!initialLoading">
        <v-row class="d-flex justify-space-between py-5 px-5 lista-header">
          <div>
        <p 
        class="font-weight-600 text-h5 text-typo"
        v-if="notificacoes.length === 0">=/ Você não tem nenhuma notificação. </p>     
          </div>
        </v-row>
      
            <v-list-item
              v-for="(item, i) in notificacoes"
              :key="i"
              class="pa-4 list-item-hover-active"
              :class="{ 'border-bottom': i != notificacoes.length - 1 }"
              @click="() => visualizarNotificacao(item)"
            >
              <v-list-item-avatar :size="48" class="my-0 me-5">
                <v-icon>ni ni-bell-55</v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  <v-row>
                    <v-col>
                      {{ item.titulo }}
                    </v-col>
                    <v-col class="text-right">
                      <span class="text-muted text-body-2 text-caption ls-0">
                        2 hrs ago
                      </span>
                    </v-col>
                  </v-row>
                </v-list-item-title>

                <p
                  class="mt-1 mb-0 text-body text-body-2 ls-0 font-weight-thin"
                >
                  {{ item.mensagem }}
                </p>
              </v-list-item-content>
            </v-list-item>
            </div>
    </v-card>
    <ListSkeleton v-if="initialLoading"/>
  </div>  
</template>

<script>
import ListSkeleton from '../../../components/Loading/ListSkeleton.vue'
import NotificacaoServico from '../../../servicos/notificacao.servico'
export default {
  components: {ListSkeleton},
  data(){
    return {
      notificacoes: null,
      initialLoading: true
    }
  },
  methods: {
    async requisitarNotificacoes(){
      this.notificacoes = await NotificacaoServico.buscar({idusuario: parseInt(localStorage.getItem('idusuario'))}).then(res => res.data)
    },
    async visualizarNotificacao(item){
      const novoestado = this.notificacoes.filter(el => el.idnotificacao !== item.idnotificacao)
      this.notificacoes = novoestado
      return await NotificacaoServico.visualizar({idnotificacao: item.idnotificacao})
    }
  },
  async mounted(){
    this.initialLoading = true
    await this.requisitarNotificacoes()
    this.initialLoading = false
  }
}
</script>

<style lang="scss" scoped>

</style>