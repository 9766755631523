<template class="template">
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <form id="formularioCadastro" class="formularioCadastro" autocomplete="off">
      <v-card class="card-shadow" width="100%">
        <v-row class="card-padding d-flex align-center">
          <v-col cols="6" lg="6" class="d-flex flex-column">
            <h2 class="">{{ hotel.nome }}</h2>
            <p class="">id: {{ hotel.idhotel }}</p>
          </v-col>
          <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
             <button 
             @click="link('/list/hotel')"
             type='button'

             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
          </v-col>
        </v-row>
        <v-card-text class="card-padding">
          <v-row class="">
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="nome"
                label="nome"
                v-model="nome"
                :input="true"
                icon="ni ni-circle-08"
                placeholder="Insira seu nome"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="email"
                label="email"
                v-model="email"
                :input="true"
                icon="ni ni-email-83"
                placeholder="Insira seu email"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="celular"
                label="celular"
                v-model="celular"
                v-mask="'(##) # ####-####'"
                type="tel"
                :input="true"
                icon="ni ni-mobile-button"
                placeholder="Insira seu celular"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="telefone"
                label="telefone"
                v-model="telefone"
                v-mask="'(##) ####-####'"
                type="tel"
                :input="true"
                icon="ni ni-mobile-button"
                placeholder="Insira seu telefone"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="cnpj"
                label="cnpj"
                v-model="cnpj"
                v-mask="'##.###.###/####-##'"
                :input="true"
                icon="ni ni-badge"
                placeholder="Insira seu cnpj"
              />
            </v-col>
            <v-col  cols="12" md="6" sm="6" xs="6">
              <Input
                name="site"
                label="site"
                v-model="site"
                type="text"
                :input="true"
                icon="ni ni-world-2"
                placeholder="Insira seu site"
              />
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="facebook"
                label="facebook"
                v-model="facebook"
                :input="true"
                icon="ni ni-ungroup"
                placeholder="Insira seu facebook"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="instagram"
                label="instagram"
                v-model="instagram"
                :input="true"
                icon="ni ni-ungroup"
                placeholder="Insira seu instagram"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="linkedin"
                label="linkedin"
                v-model="linkedin"
                :input="true"
                icon="ni ni-ungroup"
                placeholder="Insira seu linkedin"
              />
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="12" md="6" sm="6" xs="6">
              
              <Dropdown
                :multiple="false"
                :options="paises"
                id="pais-select"
                label="Selecione um pais"
                v-model="paisselecionado"
                :initialValue="paisselecionado"
                :reset="paisreset"
                :sideEffect="handleChangePais"
                data-cy="pais"
                :border="'2px solid rgba(15, 136, 242, 0.3)'"
                :borderactive="'2px solid rgba(15, 136, 242)'"
                :borderradius="'0.7125rem'"
                :icon="'ni ni-map-big'"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="6">
              
              <Dropdown
                :multiple="false"
                :options="estados"
                id="estado-select"
                label="Selecione um estado"
                v-model="estadoselecionado"
                :initialValue="estadoselecionado"
                :reset="estadoreset"
                :sideEffect="handleChangeEstados"
                data-cy="estado"
                :border="'2px solid rgba(15, 136, 242, 0.3)'"
                :borderactive="'2px solid rgba(15, 136, 242)'"
                :borderradius="'0.7125rem'"
                :icon="'ni ni-map-big'"
              />
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="12" md="6" sm="6" xs="6">
              
              <Dropdown
                :multiple="false"
                :options="cidades"
                id="cidade-select"
                label="Selecione uma cidade"
                v-model="cidadeselecionado"
                :initialValue="cidadeselecionado"
                :reset="cidadereset"
                data-cy="cidade"
                :border="'2px solid rgba(15, 136, 242, 0.3)'"
                :borderactive="'2px solid rgba(15, 136, 242)'"
                :borderradius="'0.7125rem'"
                :icon="'ni ni-map-big'"
              />
            </v-col>
          </v-row>
          <v-row class="">
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="bairro"
                label="bairro"
                v-model="bairro"
                :input="true"
                icon="ni ni-ungroup"
                placeholder="Insira seu bairro"
              />
            </v-col>
            <v-col>
              <Input
                name="logradouro"
                label="logradouro"
                v-model="logradouro"
                :input="true"
                icon="ni ni-ungroup"
                placeholder="Insira seu logradouro"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="complemento"
                label="complemento"
                v-model="complemento"
                :input="true"
                icon="ni ni-ungroup"
                placeholder="Insira um complemento"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="6">
              <Input
                name="numero"
                label="numero"
                v-model="numero"
                :input="true"
                icon="ni ni-ungroup"
                placeholder="Insira o numero"
              />
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="12" md="12" sm="12" xs="12">
              <Input
                name="quemSomos"
                label="quemSomos"
                v-model="quemSomos"
                :textarea="true"
                placeholder="Fale sobre o hotel"
              />
            </v-col>
          </v-row>

          <v-row class="">
            <v-card class="card-shadow" width="100%">
              <form>
                <h2 class="ml-5 mt-2">Tipos de Solicitações</h2>
                <v-card-text class="card-padding">
                  <div class="solicitacoesWrapper">
                    <div
                      class="solicitacaoCard"
                      v-for="item in solicitacoes"
                      :key="item.nome"
                    >
                      <input type="checkbox" 
                      :data-cy="`soli-${item.nome.toLowerCase()}`"
                      v-model="item.hotelatende" />
                      <button
                        class="card-btn"
                        type="button"
                        @click="() => selecionarSolicitacao(item)"

                      >
                        {{ item.nome }}
                      </button>

                      <!-- <button
                        class="card-btn"
                        type="button"
                        @click="(e) => deletarSolicitacao(item)"
                      >
                        x
                      </button> -->
                    </div>
                  </div>
                  <!-- <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    width="145"
                    class="
                      font-weight-600
                      text-capitalize
                      btn-primary
                      py-3
                      px-6
                      rounded-sm
                      my-6
                    "
                    color="#0f88f2"
                    data-cy="btn-envio-solicitacoes"
                    @click="envioFormularioSolicitacoes"
                    >criar solicitação</v-btn
                  > -->
                </v-card-text>

                <button type="reset" id="resetFormularioSolicitacao" hidden />
              </form>
            </v-card>
          </v-row>

          <v-row class="">
            <v-card class="card-shadow" width="100%">
          <form>
            <h2 class="ml-5 mt-2">Tipos de Apartamentos</h2>
            <v-card-text class="card-padding">
              <v-row class="">
                <v-col cols="12" lg="6" md="6" sm="6" xs="6">
                  <Input
                    name="nomeap"
                    label="nome apartamento"
                    v-model="nomeap"
                    :input="true"
                    icon="ni ni-ungroup"
                    placeholder="Informe o tipo de apartamento"
                  />
                  <Input
                    name="descricaoap"
                    label="descrição apartamento"
                    v-model="descricaoap"
                    :input="true"
                    icon="ni ni-ungroup"
                    placeholder="Informe a descrição"
                  />
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="6" xs="6">
                  <input 
                    type="file"
                    accept="image/png, image/jpeg"
                    @change="(e)=>uploadImagemApartamento(e)" 
                    hidden
                    id='btn-upload-iamgem apartamento'
                  />
                  <span class="upload-btn-wrapper" v-if="fotoap===null">
                    <button type="button" class="btn" @click="clicarElemento('btn-upload-iamgem apartamento')">
                    <v-icon>
                        ni ni-fat-add
                      </v-icon>
                  </button>
                  <p>inserir imagem</p>
                  </span>
                  <div class="item-midias" v-if="fotoap">
                   <div class="item-midias-header">
                      <button 
                        type="button" 
                        class="btn-item-imagem icon danger"
                        @click="fotoap = null">
                          <v-icon>ni ni-fat-remove</v-icon>
                      </button>
                      <button 
                        type="button"
                        class="btn-item-imagem link"
                        @click="clicarElemento('btn-upload-iamgem apartamento')">
                          alterar imagem
                      </button>
                      
                    </div>
                    <img 
                      :src="'data:' + fotoap.tipo + ';base64,' + fotoap.dados"
                      />  
                  </div>              
                </v-col>
              </v-row>
               <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    width="145"
                    class="
                      font-weight-600
                      text-capitalize
                      btn-primary
                      py-0
                      px-6
                      rounded-sm
                      mt-7
                    "
                    color="#0f88f2"
                    data-cy="btn-envio-ap"
                    @click="envioFormularioTipoAp"
                    >criar quarto</v-btn
                  >
                  <div class="apartamentosWrapper">
                    <div
                      class="aparatamentoCard"
                      v-for="item in apartamentos"
                      :key="item.nome"
                    >
                      <div class="apartamentoCardWrapper">
                        <button
                          class="card-btn"
                          type="button"
                          @click="() => selecionarApartamento(item)"
                        >
                          {{ item.nome }}
                        </button>
                        <button
                          class="card-btn"
                          type="button"
                          @click="(e) => deletarApartamento(e, item)"
                        >
                          x
                        </button>
                      </div>
                      <img 
                        @click="() => selecionarApartamento(item)"
                        :src="'data:' + item.tipo + ';base64,' + item.dados"/> 
                      </div>

                 </div>
                 
                </v-card-text>

                <button type="reset" id="resetFormularioTipo" hidden />
              </form>
            </v-card>
          </v-row>
          <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        width="145"
        class="
          font-weight-600
          text-capitalize
          btn-primary
          py-3
          px-6
          rounded-sm
          ms-3
        "
        color="#0f88f2"
        data-cy="btn-envio"
        @click="envioFormulario"
        :loading="reqloading"
        :disabled="reqloading"
        >Atualizar</v-btn
      >
        </v-card-text>
      </v-card>
      <button type="reset" id="btn-reset" hidden />
      
    </form>
  </div>
</template>

<script>
import { alertar } from "@/servicos/notificacao.servico.js";
import HotelServico from "../../../servicos/hotel.servico";
import LocalizacaoServico from "../../../servicos/localizacao.servico";
import validarCnpj from "../../../servicos/validarCnpj";
import Dropdown from "../../../components/Dropdown";
import Input from "../../../components/Input";
import {serializarImagem} from '../../../util/serializar'
function getRandom(max, restriction) {
  if (restriction) {
    let random = Math.floor(Math.random() * max + 1);
    while (restriction.includes(random)) {
      random = Math.floor(Math.random() * max + 1);
      if (!restriction.includes(random)) {
        continue;
      }
    }
    return random;
  } else {
    return Math.floor(Math.random() * max + 1);
  }
}
export default {
  components: { Dropdown, Input },
  name: "LeituraHotel",
  data() {
    return {
      nome: null,
      site: null,
      email: null,
      cnpj: null,
      instagram: null,
      facebook: null,
      linkedin: null,
      logradouro: null,
      bairro: null,
      complemento: null,
      numero: null,
      quemSomos: null,
      celular: null,
      telefone: null,
      paisselecionado: 0,
      estadoselecionado: 0,
      cidadeselecionado: 0,
      paises: [{ idpais: 0, nome: "Selecione um País" }],
      estados: [{ idestado: 0, nome: "Selecione um Estado" }],
      cidades: [{ idcidade: 0, nome: "Selecione uma Cidade" }],
      paisreset: false,
      estadoreset: false,
      cidadereset: false,
      apartamentos: [],
      nomeap: null,
      descricaoap: null,
      fotoap: null,
      tipoApartamento: "criar",
      apartamentoDoMomento: null,
      hotel: {},
      solicitacoes: [],
      nomesolicitacao: null,
      descricaosolicitacao: null,
      tiposolicitacao: "criar",
      solicitacaoDoMomento: null,
      reqloading:false,
    };
  },
  methods: {
    async envioFormulario(e) {
      e.preventDefault();
      if (this.validacao()) {
        this.reqloading = true
        const newFormtCnpj = this.cnpj
          .replace(".", "")
          .replace(".", "")
          .replace("/", "")
          .replace("-", "");

        const dadosusuario = {
          idhotel: this.hotel.idhotel,
          quemsomos: this.quemSomos,
          nome: this.nome,
          iddocumentotipo: 3,
          numerodocumento: parseInt(newFormtCnpj),
          facebook: this.facebook,
          instagram: this.instagram,
          linkedin: this.linkedin,
          site: this.site,
          email: this.email,
          celular: this.celular,
          telefone: this.telefone,
          enderecos: [
            {
              idendereco: 1,
              idpais: parseInt(this.paisselecionado),
              idestado: parseInt(this.estadoselecionado),
              idcidade: parseInt(this.cidadeselecionado),
              bairro: this.bairro,
              logradouro: this.logradouro,
              numero: this.numero,
              complemento: this.complemento,
            },
          ],
          apartamentos: this.apartamentos,
          solicitacoes: this.solicitacoes.map((soli) => {
            return {
              idtiposolicitacao: soli.idtiposolicitacao,
              nome: soli.nome,
              hotelatende: soli.hotelatende,
            };
          }),
        };
        HotelServico.atualizar(dadosusuario)
          .then((resp) => {
            if (resp.status === 200) {
              this.reqloading = false
              alertar("success", "=)", "Hotel atualizado com Sucesso!");
            }
          })
          .catch((error) => {
            this.reqloading = false
            alertar("warning", "=(", error.response.data.msg);
          });
      }
    },
    validacao() {
      // if (!this.apartamentos || this.apartamentos.length == 0) {
      //   alertar(
      //     "warning",
      //     "Preencha o formulário dos tipos de quartos!",
      //     "Campo Obrigatório!"
      //   );
      //   return false;
      // }
      if (!this.celular || this.celular.trim() == "") {
        alertar("warning", "Informe um celular Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.telefone || this.telefone.trim() == "") {
        alertar("warning", "Informe um telefone Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.site || this.site.trim() == "") {
        alertar("warning", "Informe um site Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.email || this.email.trim() == "") {
        alertar("warning", "Informe um Email Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.cnpj || this.cnpj.trim() == "") {
        alertar("warning", "Informe um CNPJ Válido!", "Campo Obrigatório!");
        return false;
      } else if (!validarCnpj(this.cnpj)) {
        alertar("warning", "Informe um CNPJ Válido!");
        return false;
      }

      if (!this.instagram || this.instagram.trim() == "") {
        alertar(
          "warning",
          "Informe um Instagram Válido!",
          "Campo Obrigatório!"
        );
        return false;
      }

      if (!this.facebook || this.facebook.trim() == "") {
        alertar("warning", "Informe um Facebook Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.linkedin || this.linkedin.trim() == "") {
        alertar("warning", "Informe um Linkedin Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.logradouro || this.logradouro.trim() == "") {
        alertar(
          "warning",
          "Informe um logradouro Válido!",
          "Campo Obrigatório!"
        );
        return false;
      }

      if (!this.bairro || this.bairro.trim() == "") {
        alertar("warning", "Informe uma bairro Válida!", "Campo Obrigatório!");
        return false;
      }
      if (!this.complemento || this.complemento.trim() == "") {
        alertar(
          "warning",
          "Informe uma data de Check-in Válida!",
          "Campo Obrigatório!"
        );
        return false;
      }
      if (!this.numero || this.numero.trim() == "") {
        alertar(
          "warning",
          "Informe uma data de Check-out Válida!",
          "Campo Obrigatório!"
        );
        return false;
      }

      if (!this.paisselecionado || this.paisselecionado == 0) {
        alertar("warning", "Informe um Pais Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.estadoselecionado || this.estadoselecionado == 0) {
        alertar("warning", "Informe um Estado Válido!", "Campo Obrigatório!");
        return false;
      }

      if (!this.cidadeselecionado || this.cidadeselecionado == 0) {
        alertar("warning", "Informe uma Cidade Válida!", "Campo Obrigatório!");
        return false;
      }
      return true;
    },
    async fetchPaises() {
      this.paisselecionado = 0;
      const response = await LocalizacaoServico.paises({});
      this.paises = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.paises.push({ nome: p.nome, value: p.idpais });
      });
      this.cidades = [{ nome: "", value: 0 }];
    },
    async fetchEstados(e) {
      this.estadoselecionado = 0;
      const response = await LocalizacaoServico.estados({
        idpais: this.paisselecionado,
      });
      this.estados = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.estados.push({ nome: p.nome, value: p.idestado });
      });
    },
    async fetchCidades(e) {
      this.cidadeselecionado = 0;
      const response = await LocalizacaoServico.cidades({
        idestado: this.estadoselecionado,
      });
      this.cidades = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.cidades.push({ nome: p.nome, value: p.idcidade });
      });
    },
    handleChangePais(e) {
      this.fetchEstados(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "", value: 0 }];
      this.cidadereset = !this.cidadereset;
      this.estadoselecionado = 0;
      this.estados = [{ nome: "", value: 0 }];
      this.estadoreset = !this.estadoreset;
    },
    handleChangeEstados(e) {
      this.cidadereset = !this.cidadereset;
      this.fetchCidades(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "Selecione uma cidade", value: 0 }];
    },

    validacaoQuartos() {
      if (!this.nomeap || this.nomeap.trim() == "") {
        alertar("warning", "Nome do apartamento!", "Campo Obrigatório!");
        return false;
      }

      if (!this.descricaoap || this.descricaoap.trim() == "") {
        alertar("warning", "Descricção do apartamento!", "Campo Obrigatório!");
        return false;
      }
      return true;
    },
    async envioFormularioTipoAp(e) {
      e.preventDefault();
      if (this.validacaoQuartos()) {
        try {
          if (this.tipoApartamento === "criar") {
            const data = {
              nome: this.nomeap,
              descricao: this.descricaoap,
              dados: this.fotoap.dados,
              tipo: this.fotoap.tipo,
            };
            this.apartamentos.push(data);
            this.nomeap = null;
            this.descricaoap = null;
            this.fotoap = null;
            document.getElementById("resetFormularioTipo").click();
          }

          if (this.tipoApartamento === "atualizar") {
            const data = {
              idapartamento: this.apartamentoDoMomento.idapartamento,
              nome: this.nomeap,
              descricao: this.descricaoap,
              dados: this.fotoap.dados,
              tipo: this.fotoap.tipo,
            };
            const apartamentos = this.apartamentos.filter(
              (apartamento) => apartamento.idapartamento !== this.apartamentoDoMomento.idapartamento
            );
            this.apartamentos = apartamentos;
            this.apartamentos.push(data);
            this.nomeap = null;
            this.descricaoap = null;
            this.fotoap = null;
            document.getElementById("resetFormularioTipo").click();
            this.tipoApartamento = "criar";
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    deletarApartamento(e, ap) {
      const updateState = this.apartamentos.filter(
        (apartamento) => apartamento.nome !== ap.nome
      );
      this.apartamentos = updateState;
      if (document.getElementById("nomeap").value === ap.nome) {
        this.nomeap = null;
        this.descricaoap = null;
        document.getElementById("resetFormularioTipo").click();
      }
    },
    selecionarApartamento(ap) {
      this.tipoApartamento = "atualizar";
      this.apartamentoDoMomento = ap;
      this.nomeap = ap.nome;
      this.descricaoap = ap.descricao;
      this.fotoap ={
        dados: ap.dados,
        tipo: ap.tipo,
      };
    },

    deletarSolicitacao(solicitacao) {
      const updateState = this.solicitacoes.filter(
        (soli) => soli.nome !== solicitacao.nome
      );
      this.solicitacoes = updateState;
      if (
        document.getElementById("nomesolicitacao").value === solicitacao.nome
      ) {
        this.nomesolicitacao = null;
        this.descricaosolicitacao = null;
        document.getElementById("resetFormularioSolicitacao").click();
      }
    },
    selecionarSolicitacao(solicitacao) {
      this.tiposolicitacao = "atualizar";
      this.solicitacaoDoMomento = solicitacao.idtiposolicitacao;
      this.nomesolicitacao = solicitacao.nome;
      this.descricaosolicitacao = solicitacao.descricao;
    },
    alterarCheckBoxSolicitacao(solicitacao) {
      this.solicitacoes.map((soli, idx) => {
        if (soli.idtiposolicitacao === solicitacao.idtiposolicitacao) {
          this.solicitacoes[idx].hotelatende = !solicitacao.hotelatende;
        }
      });
    },
    envioFormularioSolicitacoes(e) {
      e.preventDefault();
      if (this.tiposolicitacao === "criar") {
        const data = {
          idtiposolicitacao: getRandom(1000),
          nome: this.nomesolicitacao,
          descricao: this.descricaosolicitacao,
          hotelatende: true,
        };
        this.solicitacoes.push(data);
        this.nomesolicitacao = null;
        this.descricaosolicitacao = null;
        document.getElementById("resetFormularioSolicitacao").click();
      }

      if (this.tiposolicitacao === "atualizar") {
        const data = {
          idtiposolicitacao: getRandom(1000),
          nome: this.nomesolicitacao,
          descricao: this.descricaosolicitacao,
          hotelatende: this.solicitacoes.filter(
            (el) => el.nome === this.nomesolicitacao
          ).hotelatende,
        };
        const solicitacoes = this.solicitacoes.filter(
          (solicitacao) =>
            solicitacao.idtiposolicitacao !== this.solicitacaoDoMomento
        );
        this.solicitacoes = solicitacoes;
        this.solicitacoes.push(data);
        this.nomesolicitacao = null;
        this.descricaosolicitacao = null;
        document.getElementById("resetFormularioSolicitacao").click();
        this.tipoSolicitacao = "criar";
      }
    },
    link(to) {
      this.$router.push(to);
    },
    async requisitarHotel(id) {
      const { data } = await HotelServico.listar(id);
      return data[0];
    },
        clicarElemento(id){
      document.getElementById(id)?.click()
    },
    uploadImagemApartamento(e){
      serializarImagem(e.target.files[0],0.5)
        .then((res => {
          this.fotoap = res
        }))
        .catch(e => {
          console.log('erro ao serializar a imagem')
        })
    }
  },
  async mounted() {
    this.fetchPaises();
    const { id } = this.$route.params;
    this.hotel = await this.requisitarHotel(id);
    this.nome = this.hotel.nome;
    this.site = this.hotel.site;
    this.email = this.hotel.email;
    this.telefone = this.hotel.telefone;
    this.celular = this.hotel.celular;
    this.cnpj = this.hotel.numerodocumento;
    this.instagram = this.hotel.instagram;
    this.facebook = this.hotel.facebook;
    this.linkedin = this.hotel.linkedin;
    this.quemSomos = this.hotel.quemsomos;

    await this.fetchPaises();
    this.paisselecionado = parseInt(this.hotel.idpais);
    await this.fetchEstados(this.estado);
    this.estadoselecionado = parseInt(this.hotel.idestado);
    await this.fetchCidades(this.cidade);
    this.cidadeselecionado = parseInt(this.hotel.idcidade);

    this.bairro = this.hotel.bairro;
    this.logradouro = this.hotel.logradouro;
    this.complemento = this.hotel.complemento;
    this.numero = this.hotel.numero;
    this.apartamentos =
      this.hotel.apartamentos !== null ? this.hotel.apartamentos : [];
    this.solicitacoes = this.hotel.solicitacoes;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.formularioCadastro {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}
.fields-container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
.span-error {
  width: 100%;
  font-size: 12px;
  color: red;
  text-align: left;
}
.apartamentosWrapper {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}
.aparatamentoCard {
  display: flex;
  flex-direction: column;
  max-width: 48%;
  padding: 0.5rem;
  background: whitesmoke;
}
.apartamentoCardWrapper {
  display: flex;
  justify-content: space-between;
}
.card-btn {
  width: auto;
  border-radius: unset;
  &:hover {
    cursor: auto;
    text-decoration: underline;
    font-weight: bold;
  }
}
.unicon {
  display: flex;
  justify-content: center;
}
.solicitacoesWrapper {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.solicitacaoCard {
  display: flex;
  padding: 0.5rem 1rem;
  background: #dee4e6;
  margin-right: 1rem;
  align-items: center;
  border-radius: 1rem;
  margin-bottom: 1rem;
  button,
  input {
    background: transparent;
  }
  input {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
  }
  &:hover {
    background-color: #0f88f2;
    transition: 0.2s ease-in-out;
    font-weight: bold;
    color: white;
  }
}
.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}

.wrapper-midias{
  display: flex;
  overflow: hidden;
  padding: 10px 0;
}
.item-midias{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  padding: 10px;
  border-radius: .7125em;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  min-width: 10em;
  background-color: white;
  flex-shrink: 0;
  .item-midias-header{
    width: 100%;
    display:flex;
    align-items: center;    
    justify-content: space-between;
    margin-bottom: 1em;

    .btn-item-imagem{
      &.icon{
         width: 2em;
         height: 2em;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 100%;
         border:none;

         .v-icon{
           color: white;
           border:none;
         }

         &.danger{
           background-color: red;
         }
         &.text{
           background-color: blue;
         }
        }
      &.link{
        font-size: 12px;
        &:hover{
          color: blue;
          text-decoration: underline;
        }
      }
    }
          
  }
  &.item-texto{
    max-width: 20em;
    height: fit-content;
    max-height: 28em;
    >.content-texto{
      overflow-y: scroll;
      word-wrap: break-word;
    }
    
  }
}
.btn{
  background: #187fd9;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  .v-icon{
    color: white
  }

  &.secondary{
    background-color: gray;
    color: black;
  }
}


.upload-btn-wrapper{
  display: flex;
  align-items: center;

  >button{
    margin-right: .5em;
  }
  >p{
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
  }
  
}

.v-application p {
  margin-bottom:  unset;
}
</style>
