<template>
  <v-card class="card-shadow" width="100%">
    <v-row class="card-padding d-flex flex-column" v-if="!loading">
      <v-col md="6" sm="12" xs="12" class="px-0">
        <h2 class="">Cadastro de Regulamento</h2>
      </v-col>
       <v-row class="mt-3 mb-2">
     
      <v-col cols=12 md="6" sm="6" xs="6" class="py-0">
        <Input
          name="nome"
          label="nome"
          v-model="nome"
          :input="true"
          icon="ni ni-circle-08"
          placeholder="Insira o nome do regulamento"
        />
      </v-col>
      <v-col cols=12 md="6" sm="6" xs="6" class="pt-0">
       <Dropdown
              label="hotel"
              placeholder="Selecione um hotel"
              :options="hoteis"
              v-model="hotel"
              id="hotel"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :reset="hotelreset"
            />
      </v-col>
    </v-row>
    <label>Regulamento:</label>
      <HtmlEditor />
      <div class="footer">
                <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          width="145"
          class="
            font-weight-600
            text-capitalize
            btn-primary
            py-3
            px-6
            rounded-sm
            ms-3
          "
          
          color="#0f88f2"
          @click="salvarRegulamento"
          :disabled="reqloading"
          :loading="reqloading"
          type="button" 
          data-cy="btn-envio"
          >Cadastrar regulamento</v-btn
        >
      </div>
    </v-row>
    <FormSkeleton v-if="loading" />
  </v-card>
</template>
<script>
import HtmlEditor from "../../../components/HtmlEditor.vue";
import Input from "../../../components/Input.vue";
import Dropdown from "../../../components/Dropdown.vue";
import HotelServico from '../../../servicos/hotel.servico.js'
import RegulamentoServico from '../../../servicos/regulamento.servico.js'
import { alertar } from "@/servicos/notificacao.servico.js";
import FormSkeleton from '../../../components/Loading/FormSkeleton.vue'
export default {
  components: { HtmlEditor, Input, Dropdown, FormSkeleton },
  data() {
    return {
      nome: null,
      hoteis: [],
      hotel: 0,
      hotelreset: false,
      conteudo: null,
      loading: false,
      idusuario: null,
      idusuariotipo: null,
      reqloading: false,
    };
  },
  methods: {
    async salvarRegulamento() {
      if(this.validar()){
        this.reqloading = true
      const dadosusuario = {
        nome: this.nome,
        conteudo: document.getElementsByClassName("#quill-editor")[0].innerHTML,
        idhotel: this.hotel
      }
      await RegulamentoServico.cadastrar(dadosusuario).then(res => {
        if(res.status === 200){
          this.nome= null;
          document.getElementsByClassName("#quill-editor")[0].innerHTML = null
          this.hotelreset = true
          this.hotel = null;
          alertar("success", "=)", "Regulamento criado com Sucesso!");
        }
       }).catch(error=>{
            alertar("warning", "=(", error.response.data.msg);
       })
      this.reqloading = false
      }
    },
    async requisitarHoteis() {
      if( this.idusuariotipo === 2){
        console.log('=====adm hotel =====')
        const { data } = await HotelServico.listarPorUsuarioAdm(true, this.idusuario);
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis; 
      }else if(this.idusuariotipo === 1){
        console.log('=====adm =====')
        const { data } = await HotelServico.listarTodos();
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
      }
    },
    validar() {
      const conteudo = document.getElementsByClassName("#quill-editor")[0].children[0].children[0].innerText
      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }
      if (!conteudo || conteudo.trim() == ""  ) {
        alertar("warning", "Informe um conteudo Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.hotel || this.hotel == 0) {
        alertar("warning", "Informe um hotel Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }
      return true;
    },
  },
  async mounted(){
    this.loading = true
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    await this.requisitarHoteis()
    this.loading=false
  }
};
</script>
<style lang="scss" scoped>
.wrapper-regulamento {
  height: auto;
  position: relative;
  padding: 1em;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  > button {
    height: 3em;
    border-radius: 0.5em;
    background-color: #0f88f2;
    width: 90%;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    @media screen and (min-width: 1024px) {
      width: 20%;
    }
  }
}

.title-regulamento {
  text-align: center;
}
  label {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.02rem;
    margin-bottom: 0.5rem;
  }
</style>