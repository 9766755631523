<template class="template">
  <form id="agenteForm" class="agenteForm" autocomplete="off">
    <v-card class="card-shadow" width="100%">
      <v-row class="card-padding pb-0 mb-0 d-flex align-center">
        <v-col cols="6" lg="6" class="d-flex flex-column py-0">
      <h2 class="ml-5 mt-5">Editar de Promoção</h2>
          
        </v-col>
        <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
           <button 
           type="button"
             @click="link('/list/promo')"
             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
        </v-col>
      </v-row>

      <v-card-text class="card-padding">
        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <div class="section-content">
              <form>
                  <span class="form-header">
                    <button 
                      type="button" 
                      @click="clicarElemento('btn-upload-file imagem')"
                      class="btn"
                    >
                      <v-icon>
                        ni ni-fat-add
                      </v-icon>
                    </button>
                  </span>
                  <input 
                    type="file" 
                    @change="(e)=>alteracaoCampoImagem(e)" 
                    accept="image/png, image/jpeg"
                    hidden
                    id="btn-upload-file imagem"
                  />
                  <button type="reset" id="btn-reset-files imagem" hidden/>
              </form>  
              <div class="wrapper-midias">
                <div class="item-midias item-imagem" v-if="imagem!==null">
                    <div class="item-midias-header">
                      <button 
                        type="button" 
                        class="btn-item-imagem icon danger"
                        @click="removerImagem()">
                          <v-icon>ni ni-fat-remove</v-icon>
                      </button>
                      <button 
                        type="button"
                        class="btn-item-imagem link"
                        @click="clicarElemento('btn-upload-file imagem')"
                        >
                          alterar imagem
                      </button>
                      <input 
                        type="file"
                        @change="(e)=>editarMidia(e)" 
                        accept="image/png, image/jpeg" 
                        hidden
                        />
                    </div>
                    <img 
                      :src="'data:' + imagem.tipo + ';base64,' + imagem.dados"
                      v-if="imagem !==null"/> 
                  </div>
                  <span 
                    class="wrapper-aviso" 
                    v-if="imagem ===null"
                    @click="clicarElemento('btn-upload-file imagem')"
                  >
                    <div class="card-aviso">
                      <v-icon>ni ni-image</v-icon>
                      <h4>sem imagens ainda</h4>  
                      <h3>clique para adicionar</h3>  
                    </div>
                  </span>
              </div>
            </div>
          </v-col>
          <v-col md="6" sm="12" xs="12">
            <Input
              name="titulo"
              label="titulo"
              v-model="titulo"
              :input="true"
              icon="ni ni-circle-08"
              placeholder="Insira seu titulo"
            />
            <Input
              name="link"
              label="link"
              v-model="linkpromo"
              :input="true"
              icon="ni ni-badge"
              placeholder="Insira seu link"
            />
            <Dropdown
              label="hotel"
              placeholder="Selecione um hotel"
              :options="hoteis"
              v-model="hotel"
              id="hotel"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :reset="hotelreset"
              :initialValue="hotel"
              :disabled="true"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="12" sm="12" xs="12">
            <Input
              name="descricao"
              label="descricao"
              v-model="descricao"
              :textarea="true"
              placeholder="Insira sua descrição"
            />
          </v-col>
        </v-row>
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-600
            text-capitalize
            btn-primary
            py-3
            px-6
            rounded-sm
            my-6
            btn-cadastrar
          "
          color="#0f88f2"
          data-cy="btn-envio"
          @click="atualizarPromocao"
          :loading="reqloading"
          :disabled="reqloading"
          >Atualizar</v-btn
        >
      </v-card-text>
    </v-card>

    <button type="reset" id="btn-reset" hidden />

    <span v-if="!foiValidado" hidden data-cy="data-cy-error" />
  </form>
</template>

<script>
import PromocaoServico from "../../../servicos/promocao.servico";
import HotelServico from "../../../servicos/hotel.servico";
import { alertar } from "@/servicos/notificacao.servico.js";
import Input from "../../../components/Input.vue";
import Dropdown from "../../../components/Dropdown.vue";
import {serializarImagem} from '../../../util/serializar'

export default {
  components: { Input, Dropdown },
  name: "CadastroPromocao",
  data: () => ({
    titulo: null,
    descricao: null,
    linkpromo: null,
    imagem: null,
    foiValidado: true,
    hotel: null,
    hoteis: [],
    hotelreset:null,
    reqloading: false,
  }),
  methods: {
    async atualizarPromocao() {
      if (this.validarCampos()) {
        this.reqloading = true
        let dadosusuario = {
          titulo: this.titulo,
          descricao: this.descricao,
          link: this.linkpromo,
          imagem: this.imagem,
          idhotel: this.hotel,
          idpromocao: this.promocao.idpromocao
        };
        PromocaoServico.atualizar(dadosusuario)
          .then((resp) => {
            if (resp.status === 200) {
              this.reqloading = false
              alertar("success", "=)", "Promoção Atualizada com Sucesso!");
            }
          })
          .catch((error) => {
            this.reqloading = false
            alertar("warning", "=(", error.response.data.msg);
          });
      }
    },
    validarCampos() {
      if (!this.titulo || this.titulo.trim() == "") {
        alertar("warning", "Informe um titulo Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.descricao || this.descricao.trim() == "") {
        alertar(
          "warning",
          "Informe uma descrição Válida!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      }
      if (!this.imagem || this.imagem ==null) {
        alertar("warning", "Informe uma imagem Válida!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }
      if (!this.linkpromo || this.linkpromo.trim() == "") {
        alertar("warning", "Informe um link Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.hotel || this.hotel == 0) {
        alertar("warning", "Informe um hotel Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      return true;
    },
    async requisitarHoteis() {
      const { data } = await HotelServico.listarTodos();
      this.hotel = 0;
      let hoteis = new Array();
      data.map((hotel) => {
        hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
      });
      this.hoteis = hoteis;
    },
    alteracaoCampoImagem(e){
      serializarImagem(e.target.files[0], 0.5)
        .then(resp => {
          resp['chave'] = this.randomString()
          this.imagem = resp
          })
        .catch(error=>{
          console.log('error serialiando', error)
        })
        document.getElementById(`btn-reset-files imagem`).click()              
    },
    editarMidia(e){
          serializarImagem(e.target.files[0], 0.5)
            .then(resp => {
              resp['chave'] = this.randomString()
              const estadoatual = this.imagem
              estadoatual = resp
              this.imagem = estadoatual
            })
            .catch(error=>{
              console.log('error serialiando', error)
            })
       
       
    },
    removerImagem(){
      this.imagem = null
    },
    randomString(){
      return (Math.random() + 1).toString(36).substring(7).split('').sort(() => 0.5-Math.random()).join('');
    },
    clicarElemento(id){
      document.getElementById(id)?.click()
    },
    link(to) {
      this.$router.push(to);
    },
    async requisitarPromocao(id){
      const {data} = await PromocaoServico.listar(id)
      return data
    }
  },
  async mounted() {
    await this.requisitarHoteis()
    const {id} = this.$route.params
    this.promocao = await this.requisitarPromocao(id)

    this.titulo = this.promocao.titulo
    this.descricao= this.promocao.descricao,
    this.linkpromo= this.promocao.link,
    this.imagem= this.promocao.imagem,
    this.hotel= this.promocao.idhotel,
    this.idpromocao= this.promocao.idpromocao
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.btn-cadastrar {
  width: 40%;
  @media screen and (max-width: 480px) and (min-width: 360px) {
    width: 100%;
  }
}
.agenteForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}
.fields-container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
.span-error {
  width: 100%;
  font-size: 12px;
  color: red;
  text-align: left;
}

label {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.02rem;
  margin-bottom: 0.5rem;
}

.item-midias{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  padding: 10px;
  border-radius: .7125em;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  width: 100%;
  background-color: white;
  flex-shrink: 0;
  .item-midias-header{
    width: 100%;
    display:flex;
    align-items: center;    
    justify-content: space-between;
    margin-bottom: 1em;

    .btn-item-imagem{
      &.icon{
         width: 2em;
         height: 2em;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 100%;
         border:none;

         .v-icon{
           color: white;
           border:none;
         }

         &.danger{
           background-color: red;
         }
         &.text{
           background-color: blue;
         }
        }
      &.link{
        font-size: 12px;
        &:hover{
          color: blue;
          text-decoration: underline;
        }
      }
    }
          
  }
  &.item-texto{
    max-width: 20em;
    height: fit-content;
    max-height: 28em;
    >.content-texto{
      overflow-y: scroll;
      word-wrap: break-word;
    }
  }
  &.item-imagem{
    img{
      width: 100%;
      height: auto;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
.btn{
  background: #187fd9;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  .v-icon{
    color: white
  }

  &.secondary{
    background-color: gray;
    color: black;
  }
}
.section-content{
  padding: 1em;
  background-color: #dee4e6;
  position: relative;
}

.wrapper-midias{
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  
  padding: 10px 0;
}
.wrapper-aviso{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .card-aviso{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover{
      cursor: pointer;
    }
    .v-icon{
      font-size: 7em;
      color: #bbb;
    }

    h4{
      color: #bbb;
    }

    h3{
      color: #bbb;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}
</style>


