<template>
<div class='wrapper-skeleton'>  
  <div class="card-skeleton">
    <div class="card-info">
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton small animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton medium animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton big animation"/>
      <div class="info-skeleton big animation"/>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data(){
    return{
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper-skeleton{
  width: 100%;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  display: flex;
  flex-direction: column;
}

.card-skeleton{
  border-radius: .5em;
  background-color: white;
  padding: 1em;
}
.card-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.row{
    flex-direction: row;
    justify-content: space-between;
  }
}

.info-skeleton{
  width: 100%;
  height: 1.5em;
  margin-bottom: 1em;
  border-radius: .5em;    background-color: #eee;


  &.small{
    width: 30%;
  }
  &.medium{
    width: 60%;
  }
}
.midia-skeleton{
  width:49%;
  border-radius: .5em;
  height: 10em;
  margin-bottom: 1em;
  background-color: #eee;
}

@keyframes skeleton-loading {
  0%{
    background-color: #ddd;
  }
  100%{
    background-color: #eee;
  }
}

.animation{
  -webkit-animation: skeleton-loading .5s infinite alternate;
  animation: skeleton-loading .5s infinite alternate;
}

//css indesejados
.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: unset;
}

</style>