import api from '@/servicos/api'

export default {
  registrar(body) {
    return api().post('/reserva', body)
  },
  atualizar(body) {
    return api().put('/reserva', body)
  },
  listarPorHotel({ idhotel }) {
    return api().post('/reserva/select', { idhotel })
  },
  listar({ idhotel, idreserva }) {
    return api().post('/reserva/select', { idhotel, idreserva })
  },
  buscar(query){
    return api().post('/reserva/select', query)
  },
  status() {
    return api().get('/reserva/status')
  },
  enviarMensagem(body){
    return api().post('/reserva/mensagem', body)
  },
  syncMensagem(body){
    return api().post('/reserva/sync-mensagem', body)
  }
}
