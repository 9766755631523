import {json2excel, excel2json} from 'js2excel';

function exportToExcel(data, name){
  try {
    json2excel({
        data,
        name: name,
        formateDate: '20021/12/09'
    });
  } catch (e) {
    console.error('export error');
  } 
}

export {exportToExcel}