<template>
  <div>
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <v-row class="d-flex justify-space-between py-5 px-5 lista-header">
          <div>
            <p class="font-weight-600 text-h3 text-typo mb-1">
              Lista de Hoteis
            </p>
            <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
              Segue abaixo a listagem de todos os hoteis disponíveis
            </p>
          </div>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            @click="link('/register/hotel')"
            class="
              font-weight-600
              text-capitalize
              btn-primary
              py-3
              px-6
              rounded-sm
            "
            color="#0f88f2"
            v-if="acessoadmdisponivel"
            >Novo Hotel</v-btn
          >
        </v-row>
      </div>
      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <span class="search-wrapper">
         <span class="search-field">
            <unicon fill="#0f88f2" name="search" />
            <input 
              type="text"
              v-model="nomepesquisa"
              class="search-input"
              :placeholder="'Busque pelo nome'"
            />
         </span>
         <!-- <span class="search-field">
            <unicon fill="#0f88f2" name="files-landscapes-alt" />
            <input 
              type="text"
              v-mask="'##.###.###/####-##'"
              v-model="cnpjpesquisa"
              class="search-input"
              placeholder="Busque pelo cnpj"
            />
         </span> -->
        <span>

          <button type="button" class="search-button" @click="buscaHotel">Pesquisar</button>
          <button type="button" class="reset-button" @click="resetaHotel">Resetar</button>
        </span>
        </span>
      </div>

      <v-card-text class="px-0 py-0" v-if="!initialloading">
        <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="480"
          data-cy="tabela-lista-hotel"
        >
          <template v-slot:item.id="{ item }">
            <span class="text-body text-h5">
              {{ item.id }}
            </span>
          </template>

          <template v-slot:item.nome="{ item }">
            <span class="text-body text-h5">
              {{ item.nome }}
            </span>
          </template>

          <template v-slot:item.cnpj="{ item }">
            <span class="text-body text-h5">
              {{ item.cnpj }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <td @click.stop>
              <v-btn
                @click="link(`/list/hotel/${item.idhotel}`)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="44"
                width="44"
                class="
                  btn-editar
                  font-weight-600
                  text-capitalize
                  btn-ls btn-warning
                  me-2
                  my-2
                  rounded-sm
                "
                v-if="acessoadmdisponivel"
              >
                <v-icon size="12">editar</v-icon>
              </v-btn>
            </td>
            <td @click.stop>
              <v-btn
                @click="link(`/show/hotel/${item.idhotel}`)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="70"
                width="70"
                depressed
                class="
                  btn-visualizar
                  font-weight-600
                  text-capitalize
                  btn-ls btn-warning
                  me-2
                  my-2
                  rounded-sm
                "
              >
                <v-icon size="12">visualizar</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding" v-if="!initialloading">
        <v-row>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <span class="text-body me-3">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <v-btn
              elevation="2"
              outlined
              small
              text
              @click="exportar"
              v-if="items.length"
              >
              exportar excel
            </v-btn>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#0f88f2"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ListSkeleton v-if="initialloading"/>
  </div>
</template>
<script>
import HotelServico from "../../../servicos/hotel.servico";
import PromotorServico from "../../../servicos/promotor.servico";
import ListSkeleton from '../../../components/Loading/ListSkeleton.vue'
import {exportToExcel} from '../../../util/excel.js'

export default {
  name: "paginated-tables",
  components: {ListSkeleton},
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      headers: [
        { text: "Id", value: "idhotel" },
        { text: "Nome", value: "nome" },
        { text: "Cnpj", value: "numerodocumento" },
        { text: "", value: "actions" },
      ],
      items: [], 
      acessoadmdisponivel: false,    
      idusuariotipo: null, 
      idusuario: null,
      cnpjpesquisa: null,
      nomepesquisa: null,
      initialloading: false
    };
  },
  methods: {
    exportar(){
      let data = this.items
      exportToExcel(data, 'planilha-dados-hotel')
    },

    link(to) {
      this.$router.push(to);
    },

    async requisitarHoteis() {
      if( this.idusuariotipo === 2){
        const { data } = await HotelServico.listarPorUsuarioAdm(true, this.idusuario);
        this.items = data; 
        return null     
      }else if(this.idusuariotipo === 1 || this.idusuariotipo === 4){
        const { data } = await HotelServico.listarTodos();
        this.items = data;
        return null     
      }else if(this.idusuariotipo === 5){
        //saber quem é o adm hotel desse promotor
        const response = await PromotorServico.buscarIdAdmHotel(this.idusuario)
        const { data } = await HotelServico.listarPorUsuarioAdm(true, response.data);
        this.items = data
      }
    },
    redirecionar(item){
      if(this.acessoadmdisponivel){
         this.link(`/list/hotel/${item.idhotel}`)
      }
    },
    async buscaHotel(){
      const {data} = await HotelServico.buscar({nome: this.nomepesquisa})
      this.items = data
    },
    async resetaHotel(){
      await this.requisitarHoteis()
      this.nomepesquisa = null
    }
  },

  async mounted() {
    this.initialloading = true
    //alterar viualizacao dos botoes
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    if(this.idusuariotipo === 1 || this.idusuariotipo === 2){
      this.acessoadmdisponivel = true
    }
    await this.requisitarHoteis();
    this.initialloading = false
  },
};
</script>
<style lang="scss" scoped>
.search-wrapper{
  display: flex;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  padding: 0.5em 1em;
  border-radius: 0.7125em;
  height: 4em;
  display: flex;
  justify-content: space-between;

}

.search-field{
  .unicon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;  }
  display: flex;
  align-items: center;
}
.search-input{
  outline: none;
  font-size: 14px;
  color:rgba(0,0,0,0.8);
  flex: 1;  
}

.search-button{
  border: 2px solid #0f88f2;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;margin-right: .5em;
  color: #0f88f2;
}
.reset-button{
    border: 2px solid rgba(133, 133, 133, 0.4);
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;
  color: rgba(133, 133, 133, 0.4);
}

.lista-header {
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    > button {
      margin-top: 1.5em;
    }
  }
}

::v-deep tbody tr {
  cursor: pointer;
}
.v-data-table {
  width: 100%;
  text-align: left;
}
.btn-visualizar,
.btn-editar {
  color: #000;
  &:hover {
    color: #000;
  }
}
</style>
