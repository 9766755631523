import api from '@/servicos/api'

export default {
  registrar(body) {
    return api().post('/operadora', body)
  },
  atualizar(body) {
    return api().put('/operadora', body)
  },
  listarTodos() {
    return api().post('/operadora/select', {})
  },
  buscar(query) {
    return api().post('/operadora/select', query)
  },
  listar(id) {
    return api().post('/operadora/select', { idoperadora: id })
  },
  status() {
    return api().get('/usuario/status')
  }
}
