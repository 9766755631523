<template>
  <v-row class="d-flex justify-center ma-0 pa-0 fill-height">
    <v-col cols="12" lg="10" md="7">
      <v-card class="card-shadow bg-secondary">
        <ExibicaoKit />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ExibicaoKit from "../Components/Exibicoes/ExibicaoKit.vue";
export default {
  name: "ShowKit",
  components: {
    ExibicaoKit,
  },
  data() {
    return {};
  },
};
</script>
