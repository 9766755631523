import api from '@/servicos/api'

export default {
  listarTodos() {
    return api().post('/usuario/select', { idusuariotipo: 4 })
  },
  listar(id) {
    return api().post('/usuario/select', { idusuario: id })
  },
  busca(query) {
    return api().post('/usuario/select', query)
  },
  status() {
    return api().get('/usuario/status')
  },
  atualizar(body) {
    return api().put('/usuario', body)
  },
  listarPontuacao(idhotel, idagente){
    return api().post('/regras/pontos', {idhotel: idhotel, idagente:idagente})
  },
  buscarPontuacao(query){
    return api().post('/regras/pontos', query)
  }
}

