<template>
  <div class="page-wrapper">
    <v-col cols="12" lg="10" md="7">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <CadastroPremio />
        </v-card>
      </v-form>
    </v-col>
  </div>
</template>
<script>
import CadastroPremio from "../Components/Cadastros/CadastroPremio.vue";

export default {
  name: "RegisterPromoter",
  components: {
    CadastroPremio,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.page-wrapper {
  display: flex;
  justify-content: center;
}
</style>
