import api from '@/servicos/api'

export default {
  registrar(body) {
    return api().post('/hotel/kit', body)
  },
  buscar(query) {
    return api().post('/hotel/kit/select', query)
  },
  atualizar(body) {
    return api().put('/hotel/kit', body)
  },
  listarPorHotel(id) {
    return api().post('/hotel/kit/select', { idhotel: id })
  },
  listar(id) {
    return api().post('/hotel/kit/select', { idkit: id })
  },
}
