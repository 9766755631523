
import api from '@/servicos/api'

export default {
  registrar(dados) {
    return api().post('/usuario', dados)
  },
  listarTodos() {
    return api().post('/usuario/select', { idusuariotipo: 5 })
  },
  listar(id) {
    return api().post('/usuario/select', { idusuario: id })
  },
  listarPorAdmHotel(id) {
    return api().post('/promotor/select', {idadmhotel: id})    
  },
  buscar(query) {
    return api().post('/usuario/select', query)
  },
  atualizar(body) {
    return api().put('/usuario', body)
  },
  status() {
    return api().get('/usuario/status')
  },
  buscarIdAdmHotel(id){
    return api().post('/promotor/select-adm-hotel', {idpromotor: id})
  },
  listarPorHotel(id){
    return api().post('/promotor/select', {idhotel: id})    
  },
  nomeHotel(id){
    return api().post('/promotor/select-hotel', {idpromotor: id})
  }
}
