<template>
  <div>
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <v-row class="d-flex justify-space-between py-5 px-5 lista-header">
          <div>
            <p class="font-weight-600 text-h3 text-typo mb-1">
              Lista de Kits
            </p>
            <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
              Segue abaixo a listagem de todas os kits de midias disponíveis
            </p>
          </div>
          <!-- <Spinner v-if="loading"/> -->
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            @click="link('/register/kit')"
            class="
              font-weight-600
              text-capitalize
              btn-primary
              py-3
              px-6
              rounded-sm
            "
            color="#0f88f2"
            :loading="loading"
            v-if="acessoadmdisponivel"
            >Novo Kit</v-btn
          >
        </v-row>
         <Dropdown
              label="hotel"
              placeholder="Selecione um hotel"
              :options="hoteis"
              v-model="hotel"
              id="hotel"
              :sideEffect="requisitarKits"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :reset="hotelreset"
              :initialValue="hotel"
            />
      </div>
      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <span class="search-wrapper">
          <span class="search-field">
            
          <unicon fill="#0f88f2" name="search" />
          
          <input 
            type="text"
            v-model="nomepesquisa"
            class="search-input"
            :placeholder="'Faça sua busca'"
          />
          </span>
          <span>
          <button type="button" class="search-button" @click="buscaKit">Pesquisar</button>
          <button type="button" class="reset-button" @click="resetaKit">Resetar</button>
          </span>
        </span>
      </div>

      <v-card-text class="px-0 py-0" v-if="!initialloading">
        <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint=480
          data-cy="tabela-lista-kit"
        >
          <template v-slot:item.idkit="{ item }">
            <span class="text-body text-h5">
              {{ item.idkit }}
            </span>
          </template>

          <template v-slot:item.nome="{ item }">
            <span class="text-body text-h5">
              {{ item.nome }}
            </span>
          </template>

           <template v-slot:item.actions="{ item }">
            <td @click.stop>
              <v-btn
                @click="link(`/list/kit/${item.idkit}`)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="44"
                width="44"
                class="
                  btn-editar
                  font-weight-600
                  text-capitalize
                  btn-ls btn-warning
                  me-2
                  my-2
                  rounded-sm
                "
                data-cy="btn-editar"
                v-if="acessoadmdisponivel"
              >
                <v-icon size="12">editar</v-icon>
              </v-btn>
            </td>
            <td @click.stop>
              <v-btn
                @click="link(`/show/kit/${item.idkit}`)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="70"
                width="70"
                depressed
                class="
                  btn-visualizar
                  font-weight-600
                  text-capitalize
                  btn-ls btn-warning
                  me-2
                  my-2
                  rounded-sm
                "
                data-cy="btn-visualizar"
              >
                <v-icon size="12" >visualizar</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding" v-if="!initialloading">
        <v-row>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <span class="text-body me-3">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#0f88f2"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ListSkeleton v-if="initialloading" />
  </div>
</template>
<script>
import KitServico from "../../../servicos/kit.servico";
import HotelServico from "../../../servicos/hotel.servico";
import PromotorServico from "../../../servicos/promotor.servico";
import Dropdown from "../../../components/Dropdown.vue";
import ListSkeleton from '../../../components/Loading/ListSkeleton.vue'
import Spinner from '../../../components/Loading/Spinner.vue'
export default {
  name: "paginated-tables",
  components: { Dropdown, ListSkeleton, Spinner },
  data() {
    return {
      hoteis: null,
      hotel: 0,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      checkboxModel: [],
      headers: [
        { text: "Id", value: "idkit" },
        { text: "Nome", value: "nome" },
        { text: "Ações", value: "actions" },
      ],
      items: [],
      acessoadmdisponivel: false,
      nomepesquisa: null,
      idusuariotipo: null,
      idusuario: null,
      initialloading: false
    };
  },
  methods: {
  

    link(to) {
      this.$router.push(to);
    },
    async requisitarKits () {
      this.loading = true
      const { data } = await KitServico.listarPorHotel(this.hotel);
      localStorage.setItem('hotel',this.hotel)
      this.items = data;
      this.loading = false
    },
    async requisitarHoteis() {
      if( this.idusuariotipo === 2){
        console.log('=====adm hotel =====')
        const { data } = await HotelServico.listarPorUsuarioAdm(true, this.idusuario);
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis; 
      }else if(this.idusuariotipo === 1){
        console.log('=====adm =====')
        const { data } = await HotelServico.listarTodos();
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
      }else if(this.idusuariotipo === 5){
        console.log('=====promotor =====')
        const response = await PromotorServico.buscarIdAdmHotel(this.idusuario)
        const { data } = await HotelServico.listarPorUsuarioAdm(true, response.data);
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
      }
    },
    
    async buscaKit(){
      const {data} = await KitServico.buscar({nome: this.nomepesquisa})
      this.items = data
    },
    async resetaKit(){
      await this.requisitarKits()
    },
    redirecionar(item){
      if(this.acessoadmdisponivel){
         this.link(`/list/kit/${item.idkit}`)
      }
    }
  },

  async mounted() {
    //alterar viualizacao dos botoes
    this.initialloading = true
    const idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    this.idusuariotipo = idusuariotipo
    if(idusuariotipo === 1 || idusuariotipo === 2){
      this.acessoadmdisponivel = true
    }

    //codigo para manter pesquisa
    const currSearch = localStorage.getItem('hotel')
    if (currSearch){
      this.hotel = parseInt(currSearch)
      this.requisitarKits()
    }
    await this.requisitarHoteis();
    this.initialloading = false
  },
};
</script>
<style lang="scss" scoped>
.search-wrapper{
  display: flex;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  padding: 0.5em 1em;
  border-radius: 0.7125em;
  height: 4em;
  display: flex;
  justify-content: space-between;

}

.search-field{
  .unicon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;  }
  display: flex;
  align-items: center;
}
.search-input{
  outline: none;
  font-size: 14px;
  color:rgba(0,0,0,0.8);
  flex: 1;  
}

.search-button{
  border: 2px solid #0f88f2;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;margin-right: .5em;
  color: #0f88f2;
}
.reset-button{
    border: 2px solid rgba(133, 133, 133, 0.4);
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;
  color: rgba(133, 133, 133, 0.4);
}
.lista-header{
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    > button {
      margin-top: 1.5em;
    }    
  }
}


::v-deep tbody tr {
  cursor: pointer;
}
.v-data-table {
  width: 100%;
  text-align: left;
}
.btn-visualizar,
.btn-editar {
  color: #000;
  &:hover {
    color: #000;
  }
}
</style>
