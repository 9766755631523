<template>
  <div
    :class="`slider-wrapper`"
    :id="`slider-wrapper ${uniqueId}`"
    @mousedown="handleDragStart"
    @mousemove="handleDragOver"
    @mouseup="handleDragEnd"
  >
    <div :class="`slider`" v-if="type === 'img'">
      <div :class="`slider-item`" v-for="(item, idx) in items" :key="idx">
        <img :src="item.media" />
      </div>
    </div>

    <div :class="`slider`" :id="`slider ${uniqueId}`" v-if="type === 'html'" :style="cssVars">
      <slot style="inherit"></slot>
    </div>
  </div>
</template>
<script>
function getRandom(max, restriction) {
  if (restriction) {
    let random = Math.floor(Math.random() * max + 1);
    while (restriction.includes(random)) {
      random = Math.floor(Math.random() * max + 1);
      if (!restriction.includes(random)) {
        continue;
      }
    }
    return random;
  } else {
    return Math.floor(Math.random() * max + 1);
  }
}
export default {
  props: {
    items: Array,
    type: String,
    inView: Number,
  },
  data() {
    return {
      start: null,
      change: null,
      uniqueId: getRandom(100),
    };
  },
  computed: {
    cssVars() {
      return {
        "--inView": (1 / this.inView) * 100 + "%",
      };
    },
  },
  methods: {
    handleDragStart(e) {
      this.start = e.clientX;
    },
    handleDragOver(e) {
      e.preventDefault();
      let touch = e.clientX;
      this.change = this.start - touch;
    },
    handleDragEnd(e) {
      let item = document.elementFromPoint(e.clientX, e.clientY);
      let width = item.offsetWidth;
      if (this.change > 0) {
        document.getElementById("slider-wrapper " + this.uniqueId).scrollLeft +=
          width;
      } else {
        document.getElementById("slider-wrapper " + this.uniqueId).scrollLeft -=
          width;
      }
    },
  },
  mounted() {
    this.$slots.default.map((slot) => slot.elm.classList.add("width"));
  },
};
</script>
<style lang="scss" scoped>
.slider-wrapper {
  width: 100%;
  min-height: 25vh;
  overflow: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  @media screen and (max-width: 768px) {
    scroll-behavior: unset;
    scroll-snap-type: unset;
  }
  .slider {
    height: 100%;
    width: 100%;
    display: flex;
    @media screen and (max-width: 768px) {
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none; /*Chrome, Safari and Opera*/
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .slider-item {
      scroll-snap-align: start;
      flex-shrink: 0;
      width: 50%; //porcentagem de acordo com quantos itens quer se ver na tela
      height: fit-content;
      margin-right: 1rem;

      img {
        position: relative;
        width: 100%;
        height: auto;
        border-radius: 0.5rem 0.5rem 1rem 1rem;
      }
      @media screen and (max-width: 768px) {
        width: 50%; //porcentagem de acordo com quantos itens quer se ver na tela
      }
    }
  }
}
.width {
  width: calc(
    var(--inView) - 0.5rem
  ); //porcentagem de acordo com quantos itens quer se ver na tela
}
</style>
