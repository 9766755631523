import api from '@/servicos/api'

export default {
  listarTodos() {
    return api().post('/usuario/select', { idusuariotipo: 3 })
  },
  listar(id) {
    return api().post('/usuario/select', { idusuario: id })
  },
  buscar(query) {
    return api().post('/usuario/select', query)
  },
  status() {
    return api().get('/usuario/status')
  },
  atualizar(body) {
    return api().put('/usuario', body)
  }
}

