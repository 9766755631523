<template class="template">
  <span>
  <v-card class="card-shadow" width="100%" v-if="!loading">
    <v-row class="card-padding d-flex align-center pb-0">
      <v-col cols="6" lg="6" class="d-flex flex-column">
        <h2 class="">Informações dos Hotéis</h2>
      </v-col>
      <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
        <!-- <div class="search-wrraper">
          <unicon name="search" /><input
            type="text"
            placeholder="pesquise seu hotel"
          />
        </div> -->
         <button 
             @click="link('/list/hotel')"
             type='button'
             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
      </v-col>
    </v-row>
    <v-card-text class="card-padding">
      <v-row class="">
        <div class="section-content section-infohotel">
          <unicon name="home" fill="#5e72e4" height="92" width="92" />
          <div class="infohotel">
            <p class="infohotel-nome" data-cy="nome">{{ hotel.nome }}</p>
            <div>
              <p class="infohotel-endereco">
                {{ hotel.logradouro }}, {{ hotel.numero }}
              </p>
              <div class="infohotel-contato">
                {{ hotel.email }} tel: {{ hotel.telefone || hotel.celular }}
              </div>
            </div>
          </div>
        </div>
      </v-row>
      <!-- <v-row> <Slider :items="sliderItems" type="img" /> </v-row> -->
      <v-row class="d-flex flex-column">
        <div class="section-title">quem somos</div>
        <div class="section-content">
          <div class="quemsomos">
            {{ hotel.quemsomos }}
          </div>
        </div>
      </v-row>
      <v-row class="">
        <div class="section-title">localizacao</div>
        <div class="section-content width100">
          <div class="localizacao">
            <h1>Hotel</h1>
            <div class="content">
              <div class="localizacao-icone">
                <unicon name="location-point" fill="#A3A3A3" />
              </div>
              <div class="localizacao-info">
                <p>{{ hotel.logradouro }}, {{ hotel.numero }}</p>
                <p>{{ cidade }} - {{ estado }}</p>
              </div>
              <div class="grow" />
              <div class="localizacao-favoritar">
                <unicon name="favorite" fill="#eba73c" />
              </div>
            </div>
          </div>
        </div>
      </v-row>
      <v-row>
        <Slider type="html" :inView="2">
          <div class="card" v-for="(ap, idx) in hotel.apartamentos" :key="idx">
            <div class="info">
              <p class="tipo">{{ap.nome }}</p>
              <p class="descricao">{{ap.descricao }}</p>
            </div>
            <div class="media">
              <img :src="'data:' + ap.tipo + ';base64,' + ap.dados" />
            </div>
          </div>
        </Slider>
      </v-row>
    </v-card-text>
  </v-card>
  <HotelSkeleton v-if="loading"/>
  </span>
</template>

<script>
import HotelServico from "../../../servicos/hotel.servico";
import Slider from "../../../components/Slider.vue";
import LocalizacaoServico from "../../../servicos/localizacao.servico";
import HotelSkeleton from '../../../components/Loading/HotelSkeleton.vue'
export default {
  components: { Slider, HotelSkeleton },
  name: "ExibicaoHotel",
  data() {
    return {
      hotel: {},
      cidade: null,
      pais: null,
      estado: null,
      sliderItems: [
        {
          key: 1,
          media:
            "https://www.praiasdenatal.com.br/wp-content/uploads/2014/08/Restaurante-Mangai1.jpg",
          info: {
            tipo: "comum",
            descricao: "Um quarto Comum",
          },
        },
        {
          key: 2,
          media:
            "https://www.praiasdenatal.com.br/wp-content/uploads/2014/08/Restaurante-Mangai1.jpg",
          info: {
            tipo: "Master",
            descricao: "Um quarto master",
          },
        },
        {
          key: 3,
          media:
            "https://www.praiasdenatal.com.br/wp-content/uploads/2014/08/Restaurante-Mangai1.jpg",
          info: {
            tipo: "luxo",
            descricao: "Um quarto de luxo",
          },
        },
        {
          key: 4,
          media:
            "https://www.praiasdenatal.com.br/wp-content/uploads/2014/08/Restaurante-Mangai1.jpg",
          info: {
            tipo: "luxo",
            descricao: "Um quarto de luxo",
          },
        },
        {
          key: 5,
          media:
            "https://www.praiasdenatal.com.br/wp-content/uploads/2014/08/Restaurante-Mangai1.jpg",
          info: {
            tipo: "luxo",
            descricao: "Um quarto de luxo",
          },
        },
        {
          key: 1,
          media:
            "https://www.praiasdenatal.com.br/wp-content/uploads/2014/08/Restaurante-Mangai1.jpg",
          info: {
            tipo: "comum",
            descricao: "Um quarto Comum",
          },
        },
        {
          key: 2,
          media:
            "https://www.praiasdenatal.com.br/wp-content/uploads/2014/08/Restaurante-Mangai1.jpg",
          info: {
            tipo: "Master",
            descricao: "Um quarto master",
          },
        },
        {
          key: 3,
          media:
            "https://www.praiasdenatal.com.br/wp-content/uploads/2014/08/Restaurante-Mangai1.jpg",
          info: {
            tipo: "luxo",
            descricao: "Um quarto de luxo",
          },
        },
        {
          key: 4,
          media:
            "https://www.praiasdenatal.com.br/wp-content/uploads/2014/08/Restaurante-Mangai1.jpg",
          info: {
            tipo: "luxo",
            descricao: "Um quarto de luxo",
          },
        },
        {
          key: 5,
          media:
            "https://www.praiasdenatal.com.br/wp-content/uploads/2014/08/Restaurante-Mangai1.jpg",
          info: {
            tipo: "luxo",
            descricao: "Um quarto de luxo",
          },
        },
      ],
      start: null,
      touch: null,
      change: null,
      loading: false,
    };
  },
  methods: {
    link(to) {
      this.$router.push(to);
    },
    async requisitarHotel(id) {
      const { data } = await HotelServico.listar(id);
      return data[0];
    },
    async requisitarPais() {
      const { data } = await LocalizacaoServico.paises({});
      this.pais = data.filter((el) => el.idpais === this.hotel.idpais)[0].nome;
    },
    async requisitarEstado(e) {
      const { data } = await LocalizacaoServico.estados({});
      this.estado = data.filter(
        (el) => el.idestado === this.hotel.idestado
      )[0].nome;
    },
    async requisitarCidade(e) {
      const { data } = await LocalizacaoServico.cidades({});
      this.cidade = data.filter(
        (el) => el.idcidade === this.hotel.idcidade
      )[0].nome;
    },
  },
  async mounted() {
    this.loading = true
    const { id } = this.$route.params;
    this.hotel = await this.requisitarHotel(id);
    await this.requisitarPais();
    await this.requisitarEstado();
    await this.requisitarCidade();
    this.loading = false
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.formularioCadastro {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}

.section-title {
  font-weight: bold;
  color: white;
  background-color: #5e72e4;
  border-radius: 1rem;
  text-transform: capitalize;
  width: fit-content;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  flex-grow: 0;
}
.section-content {
  display: flex;
  margin: 1rem 0;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.overflow-hidden {
  overflow: hidden;
}
.width100 {
  width: 100%;
}
.quemsomos {
  word-wrap: break-word;
  width: 70%;
  border-radius: 1rem;
  background-color: #f5f5f5;
  padding: 1rem;
}
.localizacao {
  padding: 1rem 2rem;
  border-left: 10px solid #5e72e4;
  background-color: #fff;
  width: 50%;
  height: 7rem;
  box-shadow: 5px 12px 30px rgba(232, 232, 232, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    font-size: 1rem;
  }
  .content {
    display: flex;
    align-items: center;
  }
  .localizacao-icone {
    margin-right: 1rem;
  }
  .localizacao-info {
    display: flex;
    flex-direction: column;
    p {
      line-height: 1.2rem;
      margin: 0;
    }
  }
  .localizacao-favoritar {
    background-color: #ffeabb;
    width: auto;
    padding: 0.4rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    .unicon {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s all;
      > svg {
        fill: #eba73c;
        transition: inherit;
      }
    }
  }
}
.grow {
  flex: 1;
}
.search-wrraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #a1cade;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: 0.2s all;
  .unicon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    fill: #a1cade;
    transition: 0.2s all;
    > svg {
      fill: inherit;
      transition: inherit;
    }
  }
  input {
    outline: none;
    font-size: 0.8rem;
    text-transform: capitalize;
    color: #dedede;
  }
}

.search-wrraper:focus-within {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #5e72e4;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  .unicon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    fill: #5e72e4;
    transition: 0.2s all;
    svg {
      fill: inherit;
      transition: inherit;
    }
  }
  input {
    outline: none;
    font-size: 0.8rem;
    text-transform: capitalize;
    color: #333;
  }
}

.section-infohotel {
  margin-bottom: 2rem;
  .unicon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }
}

.infohotel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .infohotel-nome {
    color: rgb(17, 17, 17);
    margin-bottom: 0;
    font-weight: bold;
    font-size: 1.4rem;
  }
  .infohotel-endereco {
    margin-bottom: 0;
  }
  .infohotel-contato {
  }
}

.grow {
  flex: 1;
}

.card {
  scroll-snap-align: start;
  flex-shrink: 0;
  height: fit-content;
  margin-right: 1rem;
  width: 50%;
  img {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 0.5rem 0.5rem 1rem 1rem;
  }
}
.card .info {
  flex-basis: 40px;
  flex-grow: 0;
  background-color: white;
  border-radius: 1rem 1rem 0 0;
  padding: 0.5rem;
  p {
    margin: 0;
  }
  border-bottom: 5px solid #69bf76;
  .tipo {
    color: #69bf76;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0.2rem;
  }
  .descricao {
    color: black;
    font-weight: bold;
    font-size: 14px;
    text-transform: capitalize;
  }
}
.card .media {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: 0.5rem;
  border-radius: 0.5rem 0.5rem 1rem 1rem;
  img {
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem 0.5rem 1rem 1rem;
  }
}
.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}
</style>
