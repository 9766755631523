<template>
  <v-card class="card-shadow" width="100%">
    <v-row class="card-padding d-flex flex-column">
       <v-row class="card-padding pb-0 mb-0 d-flex align-center">
        <v-col cols="6" lg="6" class="d-flex flex-column py-0">
      <h2 class="mb-5 ml-2">{{nome}}</h2>
        </v-col>
        <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
           <button 
           type="button"
             @click="link('/list/rule')"
             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
        </v-col>
      </v-row>
      <span v-html="conteudo"/>
    </v-row>
  </v-card>
</template>
<script>
import RegulamentoServico from '../../../servicos/regulamento.servico.js'

export default {
  data() {
    return {
      nome: null,
      conteudo: null,
      idhotel: null,
    };
  },
  methods: {
    async requisitarRegulamento(id){
      const {data} = await RegulamentoServico.listar(id)
      this.regulamento = data[0] 
    },
    
    link(to) {
      this.$router.push(to);
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    await this.requisitarRegulamento(id)
    this.nome= this.regulamento.nome
    this.idhotel= this.regulamento.idhotel
    this.conteudo= this.regulamento.conteudo
  },
};
</script>
<style lang="scss" scoped>
.wrapper-regulamento {
  height: auto;
  position: relative;
  padding: 1em;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  > button {
    height: 3em;
    border-radius: 0.5em;
    background-color: #0f88f2;
    width: 90%;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    @media screen and (min-width: 1024px) {
      width: 20%;
    }
  }
}

.title-regulamento {
  text-align: center;
}
.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}
</style>