import api from '@/servicos/api'

export default {
  cadastrar(body) {
    return api().post('/hotel/promocao', body)
  },
  atualizar(body) {
    return api().put('/hotel/promocao', body)
  },
  listarPorHotel(id) {
    return api().post('/hotel/promocao/select', { idhotel:id })
  },
  listar(id) {
    return api().post('/hotel/promocao/select', { idpromocao:id })
  },
  buscar(query) {
    return api().post('/hotel/promocao/select', query)
  },

}
