<template>
  <div class="loader"></div>
</template>
<script>
export default {
  data(){
    return {}
  }
}
</script>
<style lang="scss" scoped>
.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: .5em solid rgba(15,136,242,.7);
  border-right: .5em solid rgba(15,136,242,.7);
  border-bottom: .5em solid rgba(15,136,242,.7);
  border-left: .5em solid #f2f2f2;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


</style>