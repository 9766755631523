<template class="template">
<span>
    <form id="formularioCadastro" class="formularioCadastro" autocomplete="off" v-if="!loading">
    <v-card class="card-shadow" width="100%">
      <h2 class="ml-5 mt-5">Cadastro de Regra de Pontuação</h2>

      <v-card-text class="card-padding">
        <v-row class="">
            <v-col cols="12" md="12" sm="12" xs="12">
            <Dropdown
                    label="hotel"
                    placeholder="Selecione um hotel"
                    :options="hoteis"
                    v-model="idhotelselecionado"
                    id="hotel"
                    data-cy="hotel"
                    :border="'2px solid rgba(15, 136, 242, 0.3)'"
                    :borderactive="'2px solid rgba(15, 136, 242)'"
                    :borderradius="'0.7125rem'"
                    :icon="'ni ni-building'"
                    :ableToSearch="true"
                    :reset="hotelreset"
                    :initialValue="hotel"
                    :sideEffect="alteracaoCampoHotel"
                    />
            </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="nome"
              label="nome"
              v-model="nome"
              :input="true"
              icon="ni ni-circle-08"
              placeholder="Insira seu nome"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Dropdown
              :multiple="false"
              :options="niveis"
              id="nivel-select"
              label="Nível Mínimo"
              placeholder="Selecione um Nível"
              v-model="nivelselecionado"
              :initialValue="nivelselecionado"
              :sideEffect="handleChangePais"
              data-cy="nivel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="dtregrainicial"
              label="Regra Válida no período de"
              v-model="dtregrainicial"
              :initialValue="dtregrainicial"
              type="date"
              :input="true"
              icon="ni ni-mobile-button"
              placeholder="Insira a data inicial da Regra"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="dtregrafinal"
              label="Até"
              v-model="dtregrafinal"
              :initialValue="dtregrafinal"
              type="date"
              :input="true"
              icon="ni ni-mobile-button"
              placeholder="Insira a data final da Regra"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="dtvendainicial"
              label="Vendas Realizadas no período de"
              v-model="dtvendainicial"
              :initialValue="dtvendainicial"
              type="date"
              :input="true"
              icon="ni ni-mobile-button"
              placeholder="Insira a data inicial do periodo"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="dtvendafinal"
              label="Até"
              v-model="dtvendafinal"
              :initialValue="dtvendafinal"
              type="date"
              :input="true"
              icon="ni ni-mobile-button"
              placeholder="Insira a data final do periodo"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" sm="6" xs="6">
            <Input
              name="dtdiariainicial"
              label="Com diárias no Período de:"
              v-model="dtdiariainicial"
              :initialValue="dtdiariainicial"
              type="date"
              :input="true"
              icon="ni ni-badge"
              placeholder="Insira a data inicial do Periodo"
            />
          </v-col>
          <v-col md="6" sm="6" xs="6">
            <Input
              name="dtdiariafinal"
              label="Até"
              v-model="dtdiariafinal"
              :initialValue="dtdiariafinal"
              type="date"
              :input="true"
              icon="ni ni-world-2"
              placeholder="Insira a data final do Periodo"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="diariaporponto"
              label="Diarias para Pontuar"
              v-model="diariaporponto"
              :initialValue="diariaporponto"
              :input="true"
              icon="ni ni-ungroup"
              placeholder="Insira a qtde de Diarias para Pontuar"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="pontos"
              label="Quantidade de Pontos Fornecido"
              v-model="pontos"
              :initialValue="pontos"
              :input="true"
              icon="ni ni-ungroup"
              placeholder="Insira a quantidade de pontos cedidas"
            />
          </v-col>
          
        </v-row>

        

        <div style="display:flex;align-items:center;justify-content:center;margin-top:30px;">

     
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          width="145"
          class="
            font-weight-600
            text-capitalize
            btn-primary
            py-3
            px-6
            rounded-sm
            ms-3
          "
          
          color="#0f88f2"
          @click="envioFormulario"
          :disabled="reqloading"
          :loading="reqloading"
          data-cy="btn-envio"
          >Cadastrar Regra</v-btn
        >
        </div>
      </v-card-text>
    </v-card>
    <button type="reset" id="btn-reset" hidden />
    <span v-if="!foiValidado" data-cy="data-cy-error" />
  </form>
  <FormSkeleton v-if="loading"/>
</span>
</template>

<script>
import { alertar } from "@/servicos/notificacao.servico.js";
import HotelServico from "../../../servicos/hotel.servico";
import LocalizacaoServico from "../../../servicos/localizacao.servico";
import Dropdown from "../../../components/Dropdown.vue";
import Input from "../../../components/Input.vue";

function getRandom(max, restriction) {
  if (restriction) {
    let random = Math.floor(Math.random() * max + 1);
    while (restriction.includes(random)) {
      random = Math.floor(Math.random() * max + 1);
      if (!restriction.includes(random)) {
        continue;
      }
    }
    return random;
  } else {
    return Math.floor(Math.random() * max + 1);
  }
}
import api from '@/servicos/api'
import FormSkeleton from '../../../components/Loading/FormSkeleton.vue'

export default {
  components: { Dropdown, FormSkeleton, Input },
  name: "CadastroRegra",
  data() {
    return {
      loading: false,
        hoteis: [],
        idhotelselecionado:1,
      niveis:[],
      nivelselecionado:2,
      nome: null,
      pontos:1,
    diariaporponto:7,
    dtregrainicial:'1900-01-01',
    dtregrafinal:'2100-01-01',
    dtdiariainicial:'1900-01-01',
    dtdiariafinal:'2100-01-01',
    dtvendainicial:'1900-01-01',
    dtvendafinal:'2100-01-01',
      site: null,
      email: null,
      cnpj: null,
      instagram: null,
      facebook: null,
      linkedin: null,
      logradouro: null,
      bairro: null,
      complemento: null,
      numero: null,
      quemSomos: null,
      celular: null,
      telefone: null,
      paisselecionado: 0,
      estadoselecionado: 0,
      cidadeselecionado: 0,
      paises: [{ idpais: 0, nome: "Selecione um País" }],
      estados: [{ idestado: 0, nome: "Selecione um Estado" }],
      cidades: [{ idcidade: 0, nome: "Selecione uma Cidade" }],
      paisreset: false,
      estadoreset: false,
      cidadereset: false,
      apartamentos: [],
      nomeap: null,
      descricaoap: null,
      fotoap: null,
      tipoApartamento: "criar",
      apartamentoDoMomento: null,
      foiValidado: true,
      solicitacoes: [
        { hotelatende: false, idtiposolicitacao: 1, nome: "Cama Extra" },
        { hotelatende: false, idtiposolicitacao: 2, nome: "Internet" },
        { hotelatende: false, idtiposolicitacao: 3, nome: "Berço" },
        { hotelatende: false, idtiposolicitacao: 4, nome: "Vinho" },
        {
          hotelatende: false,
          idtiposolicitacao: 5,
          nome: "Decoração",
        },
        { hotelatende: false, idtiposolicitacao: 6, nome: "Mimos" },
        { hotelatende: false, idtiposolicitacao: 7, nome: "Jantar" },
      ],
      nomesolicitacao: null,
      descricaosolicitacao: null,
      tiposolicitacao: "criar",
      solicitacaoDoMomento: null,
      idusuariotipo: null,
      idusuario: null,
      reqloading: false,
    };
  },
  methods: {
     async requisitarHoteis() {
        let usuarioadm = this.idusuariotipo == 2 ? true : false
        if(usuarioadm){
          const { data } = await HotelServico.listarPorUsuarioAdm(usuarioadm, this.idusuario);
          let hoteis = new Array();
          data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis; 
        return null     
        }else{
          const { data } = await HotelServico.listarTodos();
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
      }
    },
    async buscarNiveis(){
        
        await api().get('/regras/nivel').then(resp=>{
            this.niveis = resp.data
        })
    },
    async envioFormulario() {
        if (this.validacao()) {
          this.reqloading = true
            let regra = {
                idnivel:this.nivelselecionado,
                idhotel:this.idhotelselecionado,
                nome:this.nome,
                pontos:this.pontos,
                flstatus:true,
                datavigenciainicial:this.dtregrainicial,
                datavigenciafinal:this.dtregrafinal,
                datavendainicial:this.dtvendainicial,
                datavendafinal:this.dtvendafinal,
                datareservainicial:this.dtdiariainicial,
                datareservafinal:this.dtdiariafinal,
                qtdediarias:this.diariaporponto
            }
            await api().post('/regra',regra).then(() =>{
                alertar("success", "Cadastro craido com Sucesso!", "=D");
            }).catch(erro=>{
                alertar("warning", erro.response.data.msg, "=/");
            })
          this.reqloading = false

        }
        
    //   e.preventDefault();
    //   if (this.validacao()) {
    //     const newFormtCnpj = this.cnpj
    //       .replace(".", "")
    //       .replace(".", "")
    //       .replace("/", "")
    //       .replace("-", "");

    //     const dadosusuario = {
    //       idhotel: 2,
    //       idusuarioadmhotel: 2,
    //       quemsomos: this.quemSomos,
    //       nome: this.nome,
    //       iddocumentotipo: 3,
    //       numerodocumento: newFormtCnpj,
    //       facebook: this.facebook,
    //       instagram: this.instagram,
    //       linkedin: this.linkedin,
    //       site: this.site,
    //       email: this.email,
    //       celular: this.celular,
    //       telefone: this.telefone,
    //       enderecos: [
    //         {
    //           idendereco: 1,
    //           idpais: parseInt(this.paisselecionado),
    //           idestado: parseInt(this.estadoselecionado),
    //           idcidade: parseInt(this.cidadeselecionado),
    //           bairro: this.bairro,
    //           logradouro: this.logradouro,
    //           numero: this.numero,
    //           complemento: this.complemento,
    //         },
    //       ],
    //       apartamentos: this.apartamentos.map((ap) => {
    //         return {
    //           id: ap.id,
    //           nome: ap.nome,
    //           descricao: ap.descricao,
    //         };
    //       }),
    //       solicitacoes: this.solicitacoes,
    //     };
    //     console.log(JSON.stringify(dadosusuario, null, "\t"));
    //     HotelServico.registrar(dadosusuario)
    //       .then((resp) => {
    //         if (resp.status === 200) {
    //           this.nome = null;
    //           this.site = null;
    //           this.email = null;
    //           this.cnpj = null;
    //           this.instagram = null;
    //           this.facebook = null;
    //           this.linkedin = null;
    //           this.logradouro = null;
    //           this.bairro = null;
    //           this.complemento = null;
    //           this.numero = null;
    //           this.chegada = null;
    //           this.partida = null;
    //           this.quemSomos = null;
    //           this.apartamentos = [];
    //           this.nomeap = null;
    //           this.descricaoap = null;
    //           this.fotoap = null;
    //           this.tipoApartamento = "criar";
    //           this.apartamentoDoMomento = null;
    //           this.paisselecionado = 0;
    //           this.estadoselecionado = 0;
    //           this.cidadeselecionado = 0;
    //           document.getElementById("btn-reset").click();
    //           alertar("success", "=)", "Hotel criado com Sucesso!");
    //         }
    //       })
    //       .catch((error) => {
    //         alertar("warning", "=(", error.response.data.msg);
    //       });
    //   }
    },
    validacao() {

      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.nivelselecionado ) {
        alertar("warning", "Informe um Nivel Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.dtvendainicial ) {
        alertar("warning", "Informe um Data de Venda Inicial Válida!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.dtvendafinal) {
        alertar("warning", "Informe um Data de Venda Final Válida!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.dtdiariainicial) {
        alertar("warning", "Informe um Data Inicial para a diaria da Reserva!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.dtdiariafinal) {
        alertar("warning", "Informe um Data Final para a diaria da Reserva!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.diariaporponto) {
        alertar("warning", "Informe a Quantidade de Diárias para Pontuar!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      } 

      if (!this.pontos ) {
        alertar("warning", "Informe a Quantidade de Pontos Fornecido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      } 

      return true;
    },
    async fetchPaises() {
      this.paisselecionado = 0;
      const response = await LocalizacaoServico.paises({});
      this.paises = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.paises.push({ nome: p.nome, value: p.idpais });
      });
      this.cidades = [{ nome: "", value: 0 }];
    },
    async fetchEstados(e) {
      this.estadoselecionado = 0;
      const response = await LocalizacaoServico.estados({
        idpais: this.paisselecionado,
      });
      this.estados = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.estados.push({ nome: p.nome, value: p.idestado });
      });
    },
    async fetchCidades(e) {
      this.cidadeselecionado = 0;
      const response = await LocalizacaoServico.cidades({
        idestado: this.estadoselecionado,
      });
      this.cidades = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.cidades.push({ nome: p.nome, value: p.idcidade });
      });
    },
    handleChangePais(e) {
      this.fetchEstados(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "", value: 0 }];
      this.cidadereset = !this.cidadereset;
      this.estadoselecionado = 0;
      this.estados = [{ nome: "", value: 0 }];
      this.estadoreset = !this.estadoreset;
    },
    handleChangeEstados(e) {
      this.cidadereset = !this.cidadereset;
      this.fetchCidades(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "", value: 0 }];
    },
    validacaoQuartos() {
      if (!this.nomeap || this.nomeap.trim() == "") {
        alertar("warning", "Nome do apartamento!", "Campo Obrigatório!");
        return false;
      }

      if (!this.descricaoap || this.descricaoap.trim() == "") {
        alertar("warning", "Descricção do apartamento!", "Campo Obrigatório!");
        return false;
      }
      return true;
    },
    async envioFormularioTipoAp(e) {
      e.preventDefault();
      if (this.validacaoQuartos()) {
        if (this.tipoApartamento === "criar") {
          const data = {
            id: getRandom(1000),
            nome: this.nomeap,
            descricao: this.descricaoap,
            //fotoAp,
          };
          this.apartamentos.push(data);
          this.nomeap = null;
          this.descricaoap = null;
          document.getElementById("resetFormularioTipo").click();
        }

        if (this.tipoApartamento === "atualizar") {
          const data = {
            id: getRandom(1000),
            nome: this.nomeap,
            descricao: this.descricaoap,
            //fotoAp,
          };
          const apartamentos = this.apartamentos.filter(
            (apartamento) => apartamento.id !== this.apartamentoDoMomento
          );
          this.apartamentos = apartamentos;
          this.apartamentos.push(data);
          this.nomeap = null;
          this.descricaoap = null;
          document.getElementById("resetFormularioTipo").click();
          this.tipoApartamento = "criar";
        }
      }
    },
    deletarApartamento(ap) {
      const updateState = this.apartamentos.filter(
        (apartamento) => apartamento.nome !== ap.nome
      );
      this.apartamentos = updateState;
      if (document.getElementById("nomeap").value === ap.nome) {
        this.nome = null;
        this.descricaoap = null;
        document.getElementById("resetFormularioTipo").click();
      }
    },
    selecionarApartamento(ap) {
      this.tipoApartamento = "atualizar";
      this.apartamentoDoMomento = ap.id;
      this.nomeap = ap.nome;
      this.descricaoap = ap.descricao;
    },
    deletarSolicitacao(solicitacao) {
      const updateState = this.solicitacoes.filter(
        (soli) => soli.nome !== solicitacao.nome
      );
      this.solicitacoes = updateState;
      if (
        document.getElementById("nomesolicitacao").value === solicitacao.nome
      ) {
        this.nomesolicitacao = null;
        this.descricaosolicitacao = null;
        document.getElementById("resetFormularioSolicitacao").click();
      }
    },
    selecionarSolicitacao(solicitacao) {
      this.tiposolicitacao = "atualizar";
      this.solicitacaoDoMomento = solicitacao.idtiposolicitacao;
      this.nomesolicitacao = solicitacao.nome;
      this.descricaosolicitacao = solicitacao.descricao;
    },
    envioFormularioSolicitacoes(e) {
      e.preventDefault();
      if (this.tiposolicitacao === "criar") {
        const data = {
          idtiposolicitacao: getRandom(1000),
          nome: this.nomesolicitacao,
          descricao: this.descricaosolicitacao,
          hotelatende: this.hotelatende || false,
        };
        this.solicitacoes.push(data);
        this.nomesolicitacao = null;
        this.descricaosolicitacao = null;
        document.getElementById("resetFormularioSolicitacao").click();
      }

      if (this.tiposolicitacao === "atualizar") {
        console.log(
          this.solicitacoes
            .filter((el) => el.nome === this.nomesolicitacao)
            .then((result) => result[0].hotelatende)
        );
        const data = {
          idtiposolicitacao: getRandom(1000),
          nome: this.nomesolicitacao,
          descricao: this.descricaosolicitacao,
          hotelatende: false,
        };
        const solicitacoes = this.solicitacoes.filter(
          (solicitacao) =>
            solicitacao.idtiposolicitacao !== this.solicitacaoDoMomento
        );
        this.solicitacoes = solicitacoes;
        this.solicitacoes.push(data);
        this.nomesolicitacao = null;
        this.descricaosolicitacao = null;
        document.getElementById("resetFormularioSolicitacao").click();
        this.tipoSolicitacao = "criar";
      }
    },
    link(to) {
      this.$router.push(to);
    },
    alterarCheckBoxSolicitacao(solicitacao) {
      this.solicitacoes.map((soli, idx) => {
        if (soli.idtiposolicitacao === solicitacao.idtiposolicitacao) {
          this.solicitacoes[idx].hotelatende = !solicitacao.hotelatende;
        }
      });
    },
  },
  async mounted() {
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    this.loading =true
    await this.requisitarHoteis();
    await this.buscarNiveis()
    this.loading =false
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.formularioCadastro {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}
.fields-container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
.span-error {
  width: 100%;
  font-size: 12px;
  color: red;
  text-align: left;
}
.apartamentosWrapper {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}
.aparatamentoCard {
  display: flex;
  flex-direction: column;
  max-width: 48%;
  padding: 0.5rem;
  background: whitesmoke;
}
.apartamentoCardWrapper {
  display: flex;
  justify-content: space-between;
}
.card-btn {
  width: auto;
  border-radius: unset;
  &:hover {
    cursor: auto;
    text-decoration: underline;
    font-weight: bold;
  }
}
.unicon {
  display: flex;
  justify-content: center;
}
.solicitacoesWrapper {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.solicitacaoCard {
  display: flex;
  padding: 0.5rem 1rem;
  background: #dee4e6;
  margin-right: 1rem;
  align-items: center;
  border-radius: 1rem;
  margin-bottom: 1rem;
  button,
  input {
    background: transparent;
  }
  input {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
  }
  &:hover {
    background-color: #0f88f2;
    transition: 0.2s ease-in-out;
    font-weight: bold;
    color: white;
  }
}
</style>
