import api from '@/servicos/api'

export default {
  cadastrar(body) {
    return api().post('/solicitacao', body)
  },
  atualizar(body) {
    return api().put('/solicitacao', body)
  },
  listarPorUsuario(id){
    return api().post('/solicitacao/select', {idusuario: id}) 
  },
  listar(id){
    return api().post('/solicitacao/select', {idsolicitacao: id}) 
  },
  status(){
    return api().get('solicitacao/status')
  },
  consumirPontos(body){
    return api().post('/regras/consumirPontos',body)
  }
}

