<template>
  <div>
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom" v-if="hoteis !== null">
        <v-row class="d-flex justify-space-between py-5 px-5 lista-header">
          <div>
            <p class="font-weight-600 text-h3 text-typo mb-1">
              Lista de Regras
            </p>
            <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
              Segue abaixo a listagem de todos as Regras disponíveis
            </p>
          </div>
          <!-- <Spinner v-if="loading"/> -->
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            @click="link('/register/regra')"
            class="
              font-weight-600
              text-capitalize
              btn-primary
              py-3
              px-6
              rounded-sm
            "
            color="#0f88f2"
            :loading="loading"
            >Nova Regra</v-btn
          >
        </v-row>
      </div>

        <div style="padding-right:50%;padding-left:30px;" v-if="hoteis !== null">
            <Dropdown
                    label="hotel"
                    placeholder="Selecione um hotel"
                    :options="hoteis"
                    v-model="idhotelselecionado"
                    id="hotel"
                    data-cy="hotel"
                    :border="'2px solid rgba(15, 136, 242, 0.3)'"
                    :borderactive="'2px solid rgba(15, 136, 242)'"
                    :borderradius="'0.7125rem'"
                    :icon="'ni ni-building'"
                    :ableToSearch="true"
                    :initialValue="idhotelselecionado"
                    :sideEffect="alteracaoCampoHotel"
                    />
        </div>

      <v-card-text class="px-0 py-0" v-if="hoteis !== null">
        <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="480"
          
          data-cy="tabela-lista-regra"
        >
        <!-- @click:row="(item) => link(`/list/hotel/${item.idhotel}`)" -->
          <template v-slot:item.id="{ item }">
            <span class="text-body text-h5">
              {{ item.id }}
            </span>
          </template>

          <template v-slot:item.flstatus="{ item }">
            <span class="text-body text-h5">
              {{ item.flstatus ? 'Ativo' : "Desativado" }}
            </span>
          </template>

          <template v-slot:item.nome="{ item }">
            <span class="text-body text-h5">
              {{ item.nome }}
            </span>
          </template>

          <template v-slot:item.cnpj="{ item }">
            <span class="text-body text-h5">
              {{ item.cnpj }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <td @click.stop>

<v-btn v-if="item.flstatus"
            elevation="0"
            :ripple="false"
            height="23"
            @click="inativar(item.idregra)"
            class="
              font-weight-600
              text-capitalize
              btn-primary
              py-3
              px-6
              rounded-sm
            "
            color="green"
            >Ativado</v-btn>
<!--             
              <v-btn v-if="item.flstatus"
                @click="inativar(item.idregra)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="44"
                width="44"
                style=""
                class="
                  btn-editar
                  font-weight-600
                  text-capitalize
                  btn-ls btn-warning
                  me-2
                  my-2
                  pl-2
                  pr-2
                  rounded-sm
                "
              >
                <v-icon size="12">Inativar</v-icon>
              </v-btn> -->

<v-btn v-else
            elevation="0"
            :ripple="false"
            height="23"
            @click="ativar(item.idregra)"
            class="
              font-weight-600
              text-capitalize
              btn-primary
              py-3
              px-6
              rounded-sm
            "
            color="red"
            >Desativado</v-btn>

              <!-- <v-btn v-else="item.flstatus"
                @click="ativar(item.idregra)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="44"
                width="44"
                class="
                  btn-editar
                  font-weight-600
                  text-capitalize
                  btn-ls btn-warning
                  me-2
                  my-2
                  pl-2
                  pr-2
                  rounded-sm
                "
              >
                Ativar
              </v-btn> -->

            </td>
            <!-- <td @click.stop>
              <v-btn
                @click="link(`/show/hotel/${item.idhotel}`)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="70"
                width="70"
                depressed
                class="
                  btn-visualizar
                  font-weight-600
                  text-capitalize
                  btn-ls btn-warning
                  me-2
                  my-2
                  rounded-sm
                "
              >
                <v-icon size="12" data-cy="btn-visualizar">visualizar</v-icon>
              </v-btn>
            </td> -->
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding" v-if="hoteis !== null">
        <v-row>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <span class="text-body me-3">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <v-btn
              elevation="2"
              outlined
              small
              text
              @click="exportar"
              v-if="items.length"
              >
              exportar excel
            </v-btn>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#0f88f2"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ListSkeleton v-if="hoteis === null"/>
  </div>
</template>
<script>

import HotelServico from "../../../servicos/hotel.servico";
import RegrasServico from "../../../servicos/regras.servico";
import { alertar } from '@/servicos/notificacao.servico.js'
import Dropdown from "../../../components/Dropdown.vue";
import ListSkeleton from '../../../components/Loading/ListSkeleton.vue'
import Spinner from '../../../components/Loading/Spinner.vue'
import {exportToExcel} from '../../../util/excel.js'
export default {
  name: "paginated-tables",
  components: { Dropdown, ListSkeleton, Spinner },
  data() {
    return {
        hoteis: null,
        idhotelselecionado:1,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: "", value: "actions" },
        { text: "Id", value: "idregra", width:'100px'  },
        { text: "Nome", value: "nome", width:'200px' },
        { text: "Pontos", value: "pontos" , width:'100px' },
        { text: "Status", value: "flstatus", width:'100px'  },
        { text: "Periodo da Regra", value: "dtvig", width:'200px' },
        { text: "Periodo da Venda", value: "dtvenda", width:'200px'  },
        { text: "Periodo da Reserva", value: "dtreserva", width:'200px'  },
        
      ],
      items: [],
      loading: false,
      idusuariotipo: null,
      idusuario: null,
     
    };
  },
  methods: {
    exportar(){
      let data = this.items
      exportToExcel(data, 'planilha-dados-regras')
    },
      alteracaoCampoHotel(){
        localStorage.setItem('hotel', this.idhotelselecionado)
        this.getRegrasPorHotel(this.idhotelselecionado);
      },
      async requisitarHoteis() {
        let usuarioadm = this.idusuariotipo == 2 ? true : false
        if(usuarioadm){
          const { data } = await HotelServico.listarPorUsuarioAdm(usuarioadm, this.idusuario);
          let hoteis = new Array();
          data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis; 
        return null     
        }else{
          const { data } = await HotelServico.listarTodos();
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
      }
    },
      async inativar(idregra){
          await RegrasServico.inativarRegra(this.idhotelselecionado, idregra)
          this.getRegrasPorHotel(this.idhotelselecionado)
      },
      async ativar(idregra){
          await RegrasServico.ativarRegra(this.idhotelselecionado, idregra).then(()=>{
              this.getRegrasPorHotel(this.idhotelselecionado)
          }).catch(error=>{
              alertar('warning',error.response.data.msg,'=(')
          })
          
      },
    

    link(to) {
      this.$router.push(to);
    },

    async getRegrasPorHotel(idhotel) {
      this.loading = true
      const { data } = await RegrasServico.getRegrasPorHotel(idhotel);
      this.items = data;
      this.loading = false
    },
  },

  async mounted() {
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    await this.requisitarHoteis();
    const currSearch = localStorage.getItem('hotel')
    if (currSearch){
      this.idhotelselecionado = parseInt(currSearch)
      await this.alteracaoCampoHotel()
    }
    //this.getRegrasPorHotel(this.idhotelselecionado);

  },
};
</script>
<style lang="scss" scoped>
.lista-header {
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    > button {
      margin-top: 1.5em;
    }
  }
}

::v-deep tbody tr {
  cursor: pointer;
}
.v-data-table {
  width: 100%;
  text-align: left;
}
.btn-visualizar,
.btn-editar {
  color: #000;
  &:hover {
    color: #000;
  }
}
</style>
