<template>
  <v-card class="card-shadow" width="100%">
    <v-row class="card-padding d-flex flex-column">
       <v-row class="card-padding pb-0 mb-0 d-flex align-center">
        <v-col cols="6" lg="6" class="d-flex flex-column py-0">
         
        </v-col>
        <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
           <button 
             @click="link('/list/rule')"
             type='button'
             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
        </v-col>
      </v-row>
       <v-row class="mt-3 mb-2">
     
      <v-col cols=12 md="6" sm="6" xs="6" class="pt-0">
        <Input
          name="nome"
          label="nome"
          v-model="nome"
          :input="true"
          icon="ni ni-circle-08"
          placeholder="Insira o nome do regulamento"
        />
      </v-col>
       <v-col cols=12 md="6" sm="6" xs="6" class="py-0">
       <Dropdown
              label="hotel"
              placeholder="Selecione um hotel"
              :options="hoteis"
              v-model="idhotel"
              id="hotel"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :initialValue="idhotel"
            />
      </v-col>
    </v-row>
    <label>Regulamento:</label>

      <HtmlEditor :value="conteudo" />
      <div class="footer">
        <button type="button" data-cy="btn-envio" @click="salvarRegulamento">Salvar</button>
      </div>
    </v-row>
  </v-card>
</template>
<script>
import HtmlEditor from "../../../components/HtmlEditor.vue";
import Input from "../../../components/Input.vue";
import Dropdown from "../../../components/Dropdown.vue";
import HotelServico from '../../../servicos/hotel.servico.js'
import RegulamentoServico from '../../../servicos/regulamento.servico.js'
import { alertar } from "@/servicos/notificacao.servico.js";

export default {
  components: { HtmlEditor, Input, Dropdown },
  data() {
    return {
      nome: null,
      hoteis: [],
      idhotel: 0,
      conteudo: null,
      idregulamento: null,
    };
  },
  methods: {
    async salvarRegulamento() {
      if(this.validar()){
      const dadosusuario = {
        idregulamento: this.idregulamento,
        nome: this.nome,
        conteudo: document.getElementsByClassName("#quill-editor")[0].innerHTML,
        idhotel: this.idhotel
      }
      console.log(dadosusuario)
      RegulamentoServico.atualizar(dadosusuario).then(res => {
        if(res.status === 200){
          alertar("success", "=)", "Regulamento atualizado com Sucesso!");
        }
       }).catch(error=>{
            alertar("warning", "=(", error.response.data.msg);
       })
      }
    },
    async requisitarHoteis() {
      const { data } = await HotelServico.listarTodos();
      this.idhotel = 0;
      let hoteis = new Array();
      data.map((hotel) => {
        hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
      });
      this.hoteis = hoteis;
    },
    link(to) {
      this.$router.push(to);
    },
    validar() {
      const conteudo = document.getElementsByClassName("#quill-editor")[0].children[0].children[0].innerText
      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }
      if (!conteudo || conteudo.trim() == "" ) {
        alertar("warning", "Informe um conteudo Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.idhotel || this.idhotel == 0) {
        alertar("warning", "Informe um hotel Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }
      return true;
    },
    async requisitarRegulamento(id){
      const {data} = await RegulamentoServico.listar(id)
      this.regulamento = data[0] 
    },
  },
  async mounted() {
    await this.requisitarHoteis()
    const { id } = this.$route.params;
    await this.requisitarRegulamento(id)
    this.idregulamento = this.regulamento.idregulamento
    this.nome= this.regulamento.nome
    this.conteudo= this.regulamento.conteudo
    this.idhotel= this.regulamento.idhotel
},
};
</script>
<style lang="scss" scoped>
.wrapper-regulamento {
  height: auto;
  position: relative;
  padding: 1em;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  > button {
    height: 3em;
    border-radius: 0.5em;
    background-color: #0f88f2;
    width: 90%;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    @media screen and (min-width: 1024px) {
      width: 20%;
    }
  }
}

.title-regulamento {
  text-align: center;
}
.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}
  label {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.02rem;
    margin-bottom: 0.5rem;
  }
</style>

 