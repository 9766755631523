<template>
  <div>
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <v-row class="d-flex justify-space-between py-5 px-5 lista-header">
          <div>
            <p class="font-weight-600 text-h3 text-typo mb-1">
              Lista de Prêmios
            </p>
            <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
              Segue abaixo a listagem de todas os prêmios
            </p>
          </div>
          <!-- <Spinner v-if="loading"/> -->
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            @click="link('/register/award')"
            class="
              font-weight-600
              text-capitalize
              btn-primary
              py-3
              px-6
              rounded-sm
            "
            color="#0f88f2"
            :loading="loading"
            v-if="acessoadmdisponivel"
            >Novo Premio</v-btn
          >
        </v-row>
        <Dropdown
              label="hotel"
              placeholder="Selecione um hotel"
              :options="hoteis"
              v-model="hotel"
              id="hotel"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :reset="hotelreset"
              :sideEffect="requisitarPremios"
              :initialValue="hotel"
            />
      </div>
      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <span class="search-wrapper">
          <span class="search-field">
            
          <unicon fill="#0f88f2" name="search" />
          
          <input 
            type="text"
            v-model="nomepesquisa"
            class="search-input"
            :placeholder="'Faça sua busca'"
          />
          </span>
          <span>
          <button type="button" class="search-button" @click="buscaPremio">Pesquisar</button>
          <button type="button" class="reset-button" @click="resetaPremio">Resetar</button>
          </span>
        </span>
      </div>

      <v-card-text class="px-0 py-0" v-if="!initialloading">
        <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint=480
          @click:row="(item) => redirecionar(item)"
          :loading="loading"
          loading-text="Carregando"
          data-cy="tabela-lista-premio"
        >
          

          <template v-slot:item.idpremio="{ item }">
            <span class="text-body text-h5">
              {{ item.idpremio }}
            </span>
          </template>

          <template v-slot:item.nome="{ item }">
            <span class="text-body text-h5">
              {{ item.nome }}
            </span>
          </template>

          <template v-slot:item.ativo="{ item }">
            <span class="text-body text-h5">
              {{ item.ativo ? 'ativo' : 'inativo' }}
            </span>
          </template>

          <template v-slot:item.pontuacao="{ item }">
            <span class="text-body text-h5">
              {{ item.pontuacao }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding" v-if="!initialloading">
        <v-row>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <span class="text-body me-3">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#0f88f2"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ListSkeleton v-if="initialloading" />
  </div>
</template>
<script>
import PremioServico from "../../../servicos/premio.servico";
import HotelServico from "../../../servicos/hotel.servico";
import PromotorServico from "../../../servicos/promotor.servico";
import Dropdown from '../../../components/Dropdown.vue'
import ListSkeleton from '../../../components/Loading/ListSkeleton.vue';
import Spinner from '../../../components/Loading/Spinner.vue';
export default {
  components: {Dropdown, ListSkeleton, Spinner},
  name: "paginated-tables",
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: "Id", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Pontuação", value: "pontuacao" },
        { text: "Ativo", value: "ativo" },
        { text: "Nível", value: "nivel" },
        ,
      ],
      items: [],
      acessoadmdisponivel: false,
      hoteis: null,
      hotel: null,
      loading: false,
      nomepesquisa: null,
      initialloading: false,
    };
  },
  methods: {
    redirecionar(item){
      if(this.acessoadmdisponivel){
        this.link(`/list/award/${item.idpremio}`)
      }
    },
    link(to) {
      this.$router.push(to);
    },
    async requisitarHoteis() {
      if( this.idusuariotipo === 2){
        console.log('=====adm hotel =====')
        const { data } = await HotelServico.listarPorUsuarioAdm(true, this.idusuario);
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis; 
      }else if(this.idusuariotipo === 1){
        console.log('=====adm =====')
        const { data } = await HotelServico.listarTodos();
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
      }else if(this.idusuariotipo === 5){
        console.log('=====promotor =====')
        const response = await PromotorServico.buscarIdAdmHotel(this.idusuario)
        const { data } = await HotelServico.listarPorUsuarioAdm(true, response.data);
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
      }
    },
    async requisitarPremios(){
      this.loading = true
      const { data } = await PremioServico.listarPorHotel(this.hotel);
      localStorage.setItem('hotel', this.hotel)
      this.items = data;
      this.loading = false
    },
    async buscaPremio(){
      const {data} = await PremioServico.buscar({nome: this.nomepesquisa, idhotel: this.hotel})
      this.items = data
    },
    async resetaPremio(){
      await this.requisitarPremios()
    },

  },
 
  async mounted() {
    this.initialloading = true
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    if(this.idusuariotipo === 1 || this.idusuariotipo === 2){
      this.acessoadmdisponivel = true
    }
    //codigo para manter pesquisa
    await this.requisitarHoteis()
    const currSearch = localStorage.getItem('hotel')
    if (currSearch){
      this.hotel = parseInt(currSearch)
      await this.requisitarPremios()
    }
    this.initialloading = false
  },
};
</script>
<style lang="scss" scoped>
.search-wrapper{
  display: flex;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  padding: 0.5em 1em;
  border-radius: 0.7125em;
  height: 4em;
  display: flex;
  justify-content: space-between;

}

.search-field{
  .unicon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;  }
  display: flex;
  align-items: center;
}
.search-input{
  outline: none;
  font-size: 14px;
  color:rgba(0,0,0,0.8);
  flex: 1;  
}

.search-button{
  border: 2px solid #0f88f2;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;margin-right: .5em;
  color: #0f88f2;
}
.reset-button{
    border: 2px solid rgba(133, 133, 133, 0.4);
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;
  color: rgba(133, 133, 133, 0.4);
}
.lista-header{
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    > button {
      margin-top: 1.5em;
    }    
  }
}

::v-deep tbody tr {
  cursor: pointer;
}
.v-data-table {
  width: 100%;
  text-align: left;
}
</style>
