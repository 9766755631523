<template>
  <v-row class="d-flex justify-center ma-0 pa-0 fill-height">
    <v-col cols="12" lg="10" md="7">
      <v-card class="card-shadow bg-secondary">
        <ExibicaoSolicitacao />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ExibicaoSolicitacao from "../Components/Exibicoes/ExibicaoSolicitacao.vue";
export default {
  name: "ShowRule",
  components: {
    ExibicaoSolicitacao,
  },
  data() {
    return {};
  },
};
</script>
