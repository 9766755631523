<template>
  <div>
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <v-row class="d-flex justify-space-between py-5 px-5 lista-header">
          <div>
            <p class="font-weight-600 text-h3 text-typo mb-1">
              Lista de Agências
            </p>
            <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
              Segue abaixo a listagem de todas as agências disponíveis
            </p>
          </div>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            @click="link('/register/agency')"
            class="
              font-weight-600
              text-capitalize
              btn-primary
              py-3
              px-6
              rounded-sm
            "
            color="#0f88f2"
            >Nova Agência</v-btn
          >
        </v-row>
      </div>

      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <span class="search-wrapper">
          <span class="search-field">
            
          <unicon fill="#0f88f2" name="search" />
          
          <input 
            type="text"
            v-model="nomepesquisa"
            class="search-input"
            :placeholder="'Faça sua busca'"
          />
          </span>
          <span>
          <button type="button" class="search-button" @click="buscaAgencia">Pesquisar</button>
          <button type="button" class="reset-button" @click="resetaAgencia">Resetar</button>
          </span>
        </span>
      </div>
      
      <v-card-text class="px-0 py-0" v-if="!initialloading">
        <v-data-table
          :headers="headers"
          :items="items"
          
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint=480
          @click:row.self="(item) => redirecionar(item)"
          data-cy="tabela-lista-agencia"
        >
       
          <template v-slot:item.idusuario="{ item }">
            <span class="text-body text-h5">
              {{ item.idusuario }}
            </span>
          </template>

          <template v-slot:item.nome="{ item }">
            <span class="text-body text-h5">
              {{ item.nome }}
            </span>
          </template>

          <template v-slot:item.numerodocumento="{ item }">
            <span class="text-body text-h5">
              {{ item.numerodocumento }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <td>
              <v-btn
                @click="editItem(item)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-warning
                  me-2
                  my-2
                  rounded-sm
                "
                color="#fb6340"
              >
                <v-icon size="12">editar</v-icon>
              </v-btn>
            </td>
            <td @click.stop>
              <v-btn
                @click="(item) => link(`/show/agency/${item.idusuario}`)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class="
                  font-weight-600
                  text-capitalize
                  btn-ls btn-danger
                  me-2
                  my-2
                  rounded-sm
                "
                color="#f5365c"
              >
                <v-icon size="12">visualizar</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding" v-if="!initialloading">
        <v-row>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <span class="text-body me-3">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <v-btn
              elevation="2"
              outlined
              small
              text
              @click="exportar"
              v-if="items.length"
              >
              exportar excel
            </v-btn>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#0f88f2"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ListSkeleton v-if="initialloading"/>
  </div>
</template>
<script>
import AgenciaServico from "../../../servicos/agencia.servico";
import ListSkeleton from '../../../components/Loading/ListSkeleton.vue'
import {exportToExcel} from '../../../util/excel.js'

export default {
  components: {ListSkeleton},
  name: "paginated-tables",
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: "Id", value: "idusuario" },
        { text: "Nome", value: "nome" },
        { text: "CNPJ", value: "numerodocumento" },
      ],
      items: [],
      acessoadmdisponivel:false,
      search: null,
      nomepesquisa: null,
      initialloading: false
    };
  },
  
  methods: {
    exportar(){
      let data = this.items
      exportToExcel(data, 'planilha-dados-agencia')
    },
    link(to) {
      this.$router.push(to);
    },

    async requisitarAgencias() {
      const { data } = await AgenciaServico.listarTodos();
      this.items = data;
    },
    redirecionar(item){
      if(this.acessoadmdisponivel){
        this.link(`/list/agency/${item.idusuario}`)
      }
    },
    async buscaAgencia(){
      const {data} = await AgenciaServico.buscar({nome: this.nomepesquisa, idusuariotipo:3})
      this.items = data
    },
    async resetaAgencia(){
      await this.requisitarAgencias()
      this.nomepesquisa = null
  },
  },
  async mounted() {
    //alterar viualizacao dos botoes
    this.initialloading = true
    const idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    if(idusuariotipo === 1 || idusuariotipo === 2){
      this.acessoadmdisponivel = true
    } 
    await this.requisitarAgencias();
    this.initialloading = false
  },
};
</script>
<style lang="scss" scoped>
.search-wrapper{
  display: flex;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  padding: 0.5em 1em;
  border-radius: 0.7125em;
  height: 4em;
  display: flex;
  justify-content: space-between;

}

.search-field{
  .unicon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;  }
  display: flex;
  align-items: center;
}
.search-input{
  outline: none;
  font-size: 14px;
  color:rgba(0,0,0,0.8);
  flex: 1;  
}

.search-button{
  border: 2px solid #0f88f2;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;margin-right: .5em;
  color: #0f88f2;
}
.reset-button{
    border: 2px solid rgba(133, 133, 133, 0.4);
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;
  color: rgba(133, 133, 133, 0.4);
}

.lista-header{
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    > button {
      margin-top: 1.5em;
    }    
  }
}
::v-deep tbody tr {
  cursor: pointer;
}
.v-data-table {
  width: 100%;
  text-align: left;
}
</style>
