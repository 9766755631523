<template>
  <div>
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <v-row class="d-flex justify-space-between py-5 px-5 lista-header">
          <div>
            <p class="font-weight-600 text-h3 text-typo mb-1">
              Lista de Solicitacão
            </p>
            <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
              Segue abaixo a listagem de todas as Solicitacão disponíveis
            </p>
          </div>
          <!-- <Spinner v-if="loading"/> -->
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            @click="link('/register/request')"
            class="
              font-weight-600
              text-capitalize
              btn-primary
              py-3
              px-6
              rounded-sm
            "
            color="#0f88f2"
            :loading="loading"
            v-if="!acessoadmdisponivel"
            >Nova Solicitação</v-btn
          >
        </v-row>
         <Dropdown
              label="agente"
              placeholder="Selecione um agente"
              :options="agentes"
              v-model="agente"
              id="agente"
              data-cy="agente"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :initialValue="agente"
              :sideEffect="requisitarSolicitacoes"
              v-if="acessoadmdisponivel"
            />
      </div>

      <v-card-text class="px-0 py-0" v-if="!initialloading">
        <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint=480
          :loading="loading"
          :loading-text="'Carregando ...'"         
          data-cy="tabela-lista-solicitacao"
        >
          
          <template v-slot:item.idsolicitacao="{ item }">
            <span class="text-body text-h5">
              {{ item.idsolicitacao }}
            </span>
          </template>

          <template v-slot:item.nomepremio="{ item }">
            <span class="text-body text-h5">
              {{ item.nomepremio }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <td @click.stop>
              <v-btn
                @click="link(`/list/request/${item.idsolicitacao}`)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="44"
                width="44"
                class="
                  btn-editar
                  font-weight-600
                  text-capitalize
                  btn-ls btn-warning
                  me-2
                  my-2
                  rounded-sm
                "
                v-if="acessoadmdisponivel"
              >
                <v-icon size="12" data-cy="btn-editar">editar</v-icon>
              </v-btn>
            </td>
            <td @click.stop>
              <v-btn
                @click="link(`/show/request/${item.idsolicitacao}`)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="70"
                width="70"
                depressed
                class="
                  btn-visualizar
                  font-weight-600
                  text-capitalize
                  btn-ls btn-warning
                  me-2
                  my-2
                  rounded-sm
                "
              >
                <v-icon size="12" data-cy="btn-visualizar">visualizar</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding" v-if="!initialloading">
        <v-row>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <span class="text-body me-3">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <v-btn
              elevation="2"
              outlined
              small
              text
              @click="exportar"
              v-if="items.length"
              >
              exportar excel
            </v-btn>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#0f88f2"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
      <ListSkeleton  v-if="initialloading"/>
    </v-card>
  </div>
</template>
<script>
import SolicitacaoServico from "../../../servicos/solicitacao.servico";
import PremioServico from "../../../servicos/premio.servico";
import UsuarioServico from "../../../servicos/usuario.servico";
import Dropdown from "../../../components/Dropdown.vue";
import ListSkeleton from '../../../components/Loading/ListSkeleton'
import Spinner from '../../../components/Loading/Spinner'
import {exportToExcel} from '../../../util/excel.js'

export default {
  name: "paginated-tables",
  components: { Dropdown, Spinner, ListSkeleton },
  data() {
    return {
      agentes: null,
      agente: null,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      acessoadmdisponivel: false,
      headers: [
        { text: "Id", value: "idsolicitacao" },
        { text: "Premio", value: "nomepremio" },
        { text: "ações", value: "actions" },
      ],
      items: [],
      loading: false,
      initialloading: false,
    };
  },
  methods: {
    exportar(){
      let data = this.items
      exportToExcel(data, 'planilha-dados-solicitacoes')
    },
    async nomePremio(id){
      let retorno = null
      await PremioServico.buscar({idpremio: id})
        .then(res => {
          retorno =  `${res.data[0].nome}`
        })
  
      return retorno
    },
    link(to) {
      this.$router.push(to);
    },
    async requisitarSolicitacoes(id) {
      this.loading = true
        if(id){
        const { data } = await SolicitacaoServico.listarPorUsuario(id);
        localStorage.setItem('agente',id)
        this.items = data;
          for(let i =0, len = this.items.length; i < len; i++){
             this.items[i].nomepremio = await this.nomePremio(this.items[i].idpremio)
          } 
        this.loading = false
      }else{
        const { data } = await SolicitacaoServico.listarPorUsuario(this.agente);
        localStorage.setItem('agente',this.agente)
        this.items = data;
          for(let i =0, len = this.items.length; i < len; i++){
             this.items[i].nomepremio = await this.nomePremio(this.items[i].idpremio)
          } 
        this.loading = false

      }
    },
    async requisitarAgentes() {
      const { data } = await UsuarioServico.listarPorTipo(4);   

      this.agentes = data.map((usuario) =>{
        return {nome: usuario.nome, value: usuario.idusuario }
      })
    },
  },
  async mounted() {
    this.initialloading = true
    const idusuario = parseInt(localStorage.getItem('idusuario'))
    const idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    if(idusuariotipo === 1 || idusuariotipo === 2){
      this.acessoadmdisponivel = true
      this.loading = true
      await this.requisitarAgentes();
      this.items = []
      //codigo para manter pesquisa
      const currSearch = localStorage.getItem('agente')
      if (currSearch){
        this.agente = parseInt(currSearch)
        this.requisitarSolicitacoes()
      }
      this.loading = false
    }else{
      await this.requisitarSolicitacoes(idusuario)
    }

    this.initialloading = false
  },
};
</script>
<style lang="scss" scoped>

.lista-header{
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    > button {
      margin-top: 1.5em;
    }    
  }
}


.btn-visualizar,
.btn-editar {
  color: #000;
  &:hover {
    color: #000;
  }
}
::v-deep tbody tr {
  cursor: pointer;
}
.v-data-table {
  width: 100%;
  text-align: left;
}
.wrapper-loading{
  display: flex;
  align-items: center;
  width: 20em;
  justify-content: space-between;
}
</style>
