<template class="template">
  <form id="agenteForm" class="agenteForm" autocomplete="off">
    <v-card class="card-shadow" width="100%" v-if="!loading">
      <v-row class="card-padding pb-0 mb-0 d-flex align-center">
        <v-col cols="6" lg="6" class="d-flex flex-column py-0 px-0">
      <h2 class="">Exibição de Promoção Agente</h2>
          
        </v-col>
        <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
           <button 
           type="button"
             @click="link('/list/promo-agent')"
             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
        </v-col>
      </v-row>

      <v-card-text class="card-padding">
        <v-row class="">
          <v-col cols="12" lg="6" md="6" xs="12" class="px-0"> 
            <div class="section-content">
              
              <div class="wrapper-midias">
                <div class="item-midias item-imagem" v-if="imagem!==null">
                   
                    <img 
                      :src="'data:' + imagem.tipo + ';base64,' + imagem.dados"
                      v-if="imagem !==null"/> 
                  </div>
                  <span 
                    class="wrapper-aviso" 
                    v-if="imagem ===null"
                  >
                    <div class="card-aviso">
                      <v-icon>ni ni-image</v-icon>
                      <h4>sem imagens ainda</h4>  
                      <h3>clique para adicionar</h3>  
                    </div>
                  </span>
              </div>
             <div class="exibicao-grupo">
              <div class="exibicao-field column">
                <p class="label mr">Hotel:</p>
                <p class="info">{{hotel.nome}}</p>
              </div>
              <div class="exibicao-field column">
                <p class="label mr">Promoção:</p>
                <p class="info">{{titulo}}</p>          
              </div>
              <div class="exibicao-field column">
                <p class="label mr">Nível:</p>
                <p class="info" v-for="(n,idx) in nivel" :key="idx">{{niveis[n]}}</p>          
              </div>
            </div>
            <div class="exibicao-grupo">
              <div class="exibicao-field mr">
                <p class="label mr">Inicio Reserva:</p>
                <p class="info">{{inicioperiodoreserva | formatarData}}</p>
              </div>
              <div class="exibicao-field">
                <p class="label mr">Fim Reserva:</p>
                <p class="info">{{fimperiodoreserva | formatarData}}</p>          
              </div>
            </div>
            <div class="exibicao-grupo">
              <div class="exibicao-field mr">
                <p class="label mr">Inicio Venda:</p>
                <p class="info">{{inicioperiodovenda | formatarData}}</p>
              </div>
              <div class="exibicao-field">
                <p class="label mr">Fim Venda:</p>
                <p class="info">{{fimperiodovenda | formatarData}}</p>          
              </div>
            </div>
            </div>



          </v-col>
          <v-col cols="12" lg="6" md="6" xs="12" class="">
            <div class="exibicao-field column nobackground">
              <p class="label">descrição:</p>
              <p class="info">{{descricao}}</p> 
            </div>  
          </v-col>
        </v-row>
        
      </v-card-text>
    </v-card>
    <PromoSkeleton v-if="loading"/>
  </form>
</template>
<script>
import HotelServico from "../../../servicos/hotel.servico";
import Input from "../../../components/Input.vue";
import Dropdown from "../../../components/Dropdown.vue";
import PromoSkeleton from '../../../components/Loading/PromoSkeleton.vue'

export default {
  components: { Input,  Dropdown, PromoSkeleton },
  name: "CadastroPromocaoCorretores",
  data: () => ({
    titulo: null,
    imagem: null,
    inicioperiodoreserva: null,
    fimperiodoreserva: null,
    inicioperiodovenda: null,
    fimperiodovenda: null,
    descricao: null,
    hoteis:[],
    hotel: null,
    niveis: {
      3: "Ouro",
      2: "Prata",
      1: "Bronze",
    },
    nivel: null,
    foiValidado: true,
    loading:false
  }),

  methods: {
    async requisitarHotel(id) {
      const { data } = await HotelServico.listar(id);
      this.hotel = data[0];
    },

    link(to) {
      this.$router.push(to);
    },
    async requisitarPromocao(id){
     const {data} = await HotelServico.listarPromocaoAgentes(id)
     this.promocao = data[0] 
    }
  },
  async mounted() {
    this.loading= true
    const {id} = this.$route.params
    await this.requisitarPromocao(id)
    this.titulo = this.promocao.titulo
    this.imagem = this.promocao.imagem
    this.descricao = this.promocao.descricao
    this.inicioperiodoreserva = this.promocao.inicioperiodoreserva
    this.fimperiodoreserva = this.promocao.fimperiodoreserva
    this.inicioperiodovenda = this.promocao.inicioperiodovenda
    this.fimperiodovenda = this.promocao.fimperiodovenda
    this.hotel = this.promocao.idhotel
    this.nivel = this.promocao.nivel
    await this.requisitarHotel(this.hotel)
    this.loading=false

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.agenteForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}

button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
label {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.02rem;
  margin-bottom: 0.5rem;
}
.item-midias{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  padding: 10px;
  border-radius: .7125em;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  width: 100%;
  background-color: white;
  flex-shrink: 0;

  &.item-texto{
    max-width: 20em;
    height: fit-content;
    max-height: 28em;
    >.content-texto{
      overflow-y: scroll;
      word-wrap: break-word;
    }
  }
  &.item-imagem{
    img{
      width: 100%;
      height: auto;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.section-content{
  padding: 1em;
  background-color: #dee4e6;
  position: relative;
}

.wrapper-midias{
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  
  padding: 10px 0;
}
.wrapper-aviso{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .card-aviso{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover{
      cursor: pointer;
    }
    .v-icon{
      font-size: 7em;
      color: #bbb;
    }

    h4{
      color: #bbb;
    }

    h3{
      color: #bbb;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}
.exibicao-field{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  &.mr{
    margin-right: .5em;
  }
  &.column{
    flex-direction: column;
    align-items: flex-start;
  }
  border-radius: 12px;
  padding: .75em .5em;
  &.nobackground{
    background-color: unset;
    border-radius:  unset;
    box-shadow: unset;
  }
  background: #fff;
  box-shadow: 2px 2px 10px #d3d9db,
            -2px -2px 10px #e9eff2;

  @media screen and (max-width:480px){
    width: 100%;
  }
}
.label{
  font-size: clamp(1em, 1vw, 1.5em);  
  text-transform: capitalize;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.02rem;
  &.mr{
    margin-right: .5em;
  }
}
.info{
  background-color: none;
  
  @media screen and (max-width:480px){
    min-width: unset;
    width: 100%;
  }
}
.exibicao-grupo{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}
//css indesejado
.v-application p{
  margin-bottom: unset
}
</style>
