<template class="template">
<span>
    <form
    id="formularioCadastroPremio"
    class="formularioCadastroPremio"
    autocomplete="off"
    v-if="!loading"
  >
    <v-card class="card-shadow" width="100%">
      <v-row class="card-padding pb-0">
        <v-col
          cols="12"
          lg="12"
          class="d-flex align-center justify-space-between"
        >
          <span class="form-header">
            <h1>Vincular Zonas</h1>
            <h4>Selecione uma localização</h4>
          </span>
        </v-col>
      </v-row>
      <v-card-text class="card-padding">
        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
           
            <Dropdown
              :multiple="false"
              :options="paises"
              id="pais-select"
              label="País"
              placeholder="Selecione um pais"
              v-model="pais"
              :reset="paisreset"
              :sideEffect="handleChangePais"
              data-cy="pais"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
           
            <Dropdown
              :multiple="false"
              :options="estados"
              id="estado-select"
              label="Estado"
              placeholder="Selecione um estado"
              v-model="estado"
              :reset="estadoreset"
              :sideEffect="handleChangeEstados"
              data-cy="estado"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            
            <Dropdown
              :multiple="false"
              :options="cidades"
              id="cidade-select"
              label="Cidade"
              placeholder="Selecione uma cidade"
              v-model="cidade"
              :sideEffect="handleChangeCidades"
              :reset="cidadereset"
              data-cy="cidade"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-map-big'"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="card-shadow" v-if="cidade !== null
    ">
      <v-card-text class="card-padding">
        <v-row class="">
           <v-col cols="12" md="6" sm="6" xs="6" class="pb-0">
            <div class="subtitle-card">
              <h1>Zonas Selecionandas</h1>
              <h4 v-if="zonas.length !== 0">Segue abaixo uma lista das zonas de cada cidade</h4>
               <span v-if="zonas.length === 0" class="subtitle"
              ><h4>Nenhuma zona nessa cidade</h4></span
            >
              <ul class="zona-wrapper">
              <li
                class="zona-item"
                v-for="zona in zonas"
                :key="zona.id"
                v-if="zonas.length !== 0"
                :data-cy="`desvincula ${zona}`"
              >
                {{ zona }}
                <button type="button" class="desvincula" @click="removerZona(zona)">x</button>
              </li>
            </ul>
                                  <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      data-cy="btn-envio"
      color="#0f88f2"
      :disabled="reqloading"
      :loading="reqloading"
       @click="envioForm" 
      >vincular</v-btn
    >
           
            </div>
           
            
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="6"
            v-if="cidade !== null"
          >

            <div v-if="defaultZonas.length === zonas.length" class="subtitle-card"
              ><h4>Não há mais zonas disponíveis</h4></div
            >
            <div v-if="defaultZonas.length !== zonas.length" class="subtitle-card"
              ><h1>Escolha uma zona</h1><h4>Selecione uma das zonas abaixo:</h4>
            <ul class="zona-wrapper">
              <li
                class="zona-item uncheck vincula"
                v-for="zona in computedZonas"
                :key="zona.value"
                @click="selecionarZona(zona)"
                :data-cy="`vincula ${zona}`"
              >
                {{ zona }}
              </li>
            </ul>
            </div>
          </v-col>
         
        </v-row>
       
      </v-card-text>
    </v-card>

    <button type="reset" id="btn-reset" hidden />
    <span v-if="!foiValidado" hidden data-cy="data-cy-error" />
  </form>
  <FormSkeleton v-if="loading"/>
</span>
</template>
<script>
import Dropdown from "../../../components/Dropdown.vue";
import Input from "../../../components/Input.vue";
import { alertar } from "@/servicos/notificacao.servico.js";
import LocalizacaoServico from "@/servicos/localizacao.servico.js";
import FormSkeleton from '../../../components/Loading/FormSkeleton.vue'
export default {
  name: "CadastroZona",
  components: { Dropdown, Input, FormSkeleton },
  data() {
    return {
      paises: [],
      estados: [],
      cidades: [],
      zonas: [],
      pais: null,
      estado: null,
      cidade: null,
      idzona: null,
      nomezona: null,
      paisreset: false,
      estadoreset: false,
      cidadereset: false,
      foiValidado: null,
      defaultZonas: ["Zona Leste", "Zona Oeste", "Zona Norte", "Zona Sul"],
      loading: false,
      reqloading:false,
    };
  },
  watch: {},
  computed: {
    computedZonas() {
      return this.defaultZonas.filter((x) => !this.zonas.includes(x));
    },
  },
  methods: {
    async envioForm() {
      if (this.validacao()) {
        this.reqloading = true
        const dadosusuario = {
          idcidade: this.cidade,
          idzona: this.zonas.map(zona => this.pegarIdZona(zona))}
        await LocalizacaoServico.vincularZona(dadosusuario)
          .then(res => {
            if(res.status === 200){
              alertar('success', '=)', 'Zonas vinculadas com sucesso')
            }
          })
          .catch(e => {
            alertar('warning', '=/', 'Erro ao vincular as zonas')
          })
          this.reqloading = false
      }
    },
    async fetchPaises() {
      this.pais = 0;
      const response = await LocalizacaoServico.paises({});
      this.paises = [];
      response.data.map((p) => {
        this.paises.push({ nome: p.nome, value: p.idpais });
      });
      this.cidades = [];
    },
    async fetchEstados(e) {
      this.estados = 0;
      const response = await LocalizacaoServico.estados({
        idpais: this.pais,
      });
      this.estados = [];
      response.data.map((p) => {
        this.estados.push({ nome: p.nome, value: p.idestado });
      });
    },
    async fetchCidades(e) {
      const response = await LocalizacaoServico.cidades({
        idestado: this.estado,
      });
      this.cidades = [];
      response.data.map((p) => {
        this.cidades.push({ nome: p.nome, value: p.idcidade, zonas: p.zonas });
      });
    },

    handleChangePais(e) {
      this.fetchEstados(e);
      this.cidades = [];
      this.cidadereset = !this.cidadereset;
      this.estados = [];
      this.estadoreset = !this.estadoreset;
    },
    handleChangeEstados(e) {
      this.cidadereset = !this.cidadereset;
      this.fetchCidades(e);
      this.cidades = [];
    },
    handleChangeCidades() {
      this.zonas = [];
      const zonas = this.cidades.filter((el) => el.value == this.cidade)[0]
        .zonas;
      this.zonas = zonas.map((zona) => zona.nome);
    },

    removerZona(zona) {
      this.zonas = this.zonas.filter((el) => el !== zona);
    },
    selecionarZona(zona) {
      if (!this.zonas.filter((el) => el === zona)[0]) {
        this.zonas = this.zonas.concat(zona);
      }
    },

    pegarIdZona(zona) {
      switch (zona) {
        case "Zona Leste":
          return 1;
        case "Zona Oeste":
          return 2;
        case "Zona Norte":
          return 3;
        case "Zona Sul":
          return 4;
      }
    },

    validacao() {
      if (!this.pais || this.pais == null) {
        alertar("warning", "Informe um Pais Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.estado || this.estado == null) {
        alertar("warning", "Informe um Estado Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.cidade || this.cidade == null) {
        alertar("warning", "Informe uma Cidade Válida!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }
      //  if (!this.zonas || this.zonas.length == 0) {
      //   alertar("warning", "Selecione as zonas!", "Campo Obrigatório!");
      //   this.foiValidado = false;
      //   return false;
      // }

      return true;
    },
  },
  async mounted() {
    this.loading = true
    await this.fetchPaises();
    this.loading = false
  },
};
</script>
<style lang="scss">
.form-header {
  width: 100%;
  h1 {
    font-size: 1.5rem;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.87);
  }
  h4 {
    font-size: 0.8rem;
    color: #bbb;
    margin-bottom: 0.5rem;
  }
}
.zona-wrapper {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  .zona-item {
    width: fit-content;
    list-style: none;
    border:1px solid rgba(15, 136, 242, 0.7);
    color: rgba(15, 136, 242, 0.7);
    font-weight: bold;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 1rem;
    &:hover {
      cursor: pointer;
      background: rgba(15, 136, 242, 0.3);
      color: white;
    }

    &.uncheck{
    background: rgba(15, 136, 242, 0.7);
    border:none;
    color: white;
      &:hover {
      cursor: pointer;
      background: rgba(15, 136, 242, 0.9);
    }
    }
  }
}

.subtitle {
  h4 {
    font-size: 0.8rem;
    color: #bbb;
  }
}


.subtitle-card {
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.9);
  width: auto;
  padding: 0.5em 1em;
  border-radius: .5rem;
  h1 {
    font-size: 1.5rem;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.87);
  }
  h4 {
    font-size: 0.8rem;
    color: #aaa;
    margin: 0.5rem 0;
  }
}

.md {
  margin-bottom: 0.5rem;
}

.submit-btn {
  margin-top: 1rem;
  background: rgb(15, 136, 242);
  padding: 0.5125rem 1rem;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
}

//css indesejado
.v-application ul,
.v-application ol {
  padding-left: 0;
}
</style>