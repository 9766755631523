<template class="template">
  <form
    id="formularioCadastroReserva"
    class="formularioCadastroReserva"
    autocomplete="off"
  >
    <v-card class="card-shadow" width="100%">
      <v-row class="card-padding pb-0 mb-0 d-flex align-center">
        <v-col cols="6" lg="6" class="d-flex flex-column py-0">
          <div class="d-flex flex-row pd-0 align-center">
            <label for="" class="label-color font-weight-600 pr-2"
              >Premio:
            </label>
            <p class="label-color font-weight-300 mb-0">
              {{ premio.nome }}
            </p>
          </div>
          <div class="d-flex flex-row pd-0 align-center">
            <label for="" class="label-color font-weight-600 pr-2">Id: </label>
            <p class="label-color font-weight-300 mb-0">
              {{ premio.idpremio }}
            </p>
          </div>
        </v-col>
        <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
           <button 
             @click="link('/list/award')"
             type='button'

             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
        </v-col>
      </v-row>

              <v-row class="card-padding pt-0 mt-0 d-flex flex-row align-end">
        <v-col cols="6" lg="6" md="6" sm="6" class="">
          <Dropdown
            :options="status"
            :multiple="false"
            label="status"
            placeholder="Selecione um status"
            v-model="statusselecionado"
            id="status-select"
            :initialValue="statusselecionado"
            :border="'2px solid rgba(15, 136, 242, 0.3)'"
            :borderactive="'2px solid rgba(15, 136, 242)'"
            :borderradius="'0.7125rem'"
            :icon="'ni ni-briefcase-24'"
          />
        </v-col>
        <v-col cols="6" lg="2" md="2" sm="2" xs="2" class="px-0">
         <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    width="145"
                    class="
                      font-weight-600
                      text-capitalize
                      btn-primary
                      py-0
                      px-6
                      rounded-sm
                      mt-7
                    "
                    outlined
                    color="#0f88f2"
                    data-cy="btn-envio-status"
                    @click="atualizarStatus"
                    >status</v-btn
                  >
        </v-col>
      </v-row> 
      <v-card-text class="card-padding">
        <v-row class="">
          <v-col md="6" sm="12" xs="12">
            <Input
              name="nome"
              label="nome"
              v-model="nome"
              :input="true"
              icon="ni ni-circle-08"
              placeholder="Insira seu nome"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="pontuacao"
              label="pontuacao"
              v-model="pontuacao"
              :input="true"
              type="number"
              icon="ni ni-collection"
              placeholder="Insira seu pontuacao"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
                       <Dropdown
              label="nível"
              placeholder="Selecione um nível"
              :options="niveis"
              v-model="nivel"
              id="nivel"
              data-cy="nivel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-trophy'"
              :initialValue="nivel"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
                     <Dropdown
              label="hotel"
              placeholder="Selecione um hotel"
              :options="hoteis"
              v-model="hotel"
              id="hotel"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :reset="hotelreset"
              :initialValue="hotel"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <!-- <v-checkbox
                v-model="checkbox"
                color="#0f88f2"
                :ripple="false"
                class="ma-0 checkbox-custom checkbox-thinner"
                hide-details
              >
                <template v-slot:label>
                  <span class="text-muted text-body-2 ls-0"
                    >Li e Estou de Acordo com o
                    <a
                      href="javascript:;"
                      class="text-primary text-decoration-none"
                      >Termo de Privacidade</a
                    >
                  </span>
                </template>
              </v-checkbox> -->
        </v-row>
         <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      color="#0f88f2"
      data-cy="btn-envio"
      @click="envioForm"
      >Atualizar</v-btn
    >
      </v-card-text>
    </v-card>

    <button type="reset" id="btn-reset" hidden />

   
  </form>
</template>

<script>
import { alertar } from "@/servicos/notificacao.servico.js";
import PremioServico from "../../../servicos/premio.servico";
import UsuarioServico from "../../../servicos/usuario.servico";
import HotelServico from "../../../servicos/hotel.servico";
import Dropdown from "../../../components/Dropdown";
import Input from "../../../components/Input";

export default {
  components: { Dropdown, Input },
  name: "LeituraPremio",
  data() {
    return {
      niveis: [],
      nome: null,
      pontuacao: null,
      nivel: null,
      foiValidado: null,
      status: [
        { nome: "Ativo", value: true },
        { nome: "Inativo", value: false },
      ],
      statusselecionado: null,
      premio: {},
      hoteis: [],
      hotel: null,
    };
  },
  methods: {
    async envioForm(e) {
      e.preventDefault();
      if (this.validacao()) {
          const dadosusuario = {
            idpremio: this.premio.idpremio,
            nome: this.nome,
            idhotel: this.hotel,
            pontuacao: this.pontuacao,
            nivel: this.nivel,
            ativo: this.statusselecionado,
          };
          PremioServico.atualizar(dadosusuario)
            .then((resp) => {
              console.log("resp", resp);
              if (resp.status === 200) {
                alertar("success", "=)", "Prêmio atualizado com sucesso!");
              }
            })
            .catch((error) => {
              alertar("warning", "=(", error.response.data.msg);
            });
      }
    },
    async atualizarStatus(){
          const dadosusuario = {
            idpremio: this.premio.idpremio,
            nome: this.nome,
            idhotel: this.hotel,
            pontuacao: this.pontuacao,
            nivel: this.nivel,
            ativo: this.statusselecionado,
          };
          PremioServico.atualizar(dadosusuario)
            .then((resp) => {
              console.log("resp", resp);
              if (resp.status === 200) {
                alertar("success", "=)", "Status do Prêmio atualizado com sucesso!");
              }
            })
            .catch((error) => {
              alertar("warning", "=(", error.response.data.msg);
            });
    },
    validacao() {
      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.nivel || this.nivel == 0) {
        alertar("warning", "Informe um nivel Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.pontuacao || this.pontuacao == 0) {
        alertar(
          "warning",
          "Informe um valor para pontuação!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      }
      return true;
    },
    async requsitarNiveis(){
      const {data} = await UsuarioServico.niveis()
      this.niveis = data
    },


    link(to) {
      this.$router.push(to);
    },
    async requisitarPremio(id) {
      const { data } = await PremioServico.listar(id);
      return data[0];
    },
     async requisitarHoteis() {
      const { data } = await HotelServico.listarTodos();
      this.hotel = 0;
      let hoteis = new Array();
      data.map((hotel) => {
        hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
      });
      this.hoteis = hoteis;
    },
  },
  async mounted() {
    await this.requisitarHoteis()
    await this.requsitarNiveis()
    const { id } = this.$route.params;
    this.premio = await this.requisitarPremio(id);
    this.hotel = this.premio.idhotel
    this.nome = this.premio.nome;
    this.pontuacao = this.premio.pontuacao;
    this.nivel = this.premio.nivel;
    this.statusselecionado = this.premio.ativo;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.formularioCadastroReserva {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}
.fields-container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
.span-error {
  width: 100%;
  font-size: 12px;
  color: red;
  text-align: left;
}

.item-checkbox-checked {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  height: 4rem;
  border-radius: 1rem;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: green;
  margin-bottom: 1rem;

  label {
    cursor: pointer;
    color: white;
    font-weight: bold;
  }
  input {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
    transition: 0.2s ease-in-out;
    outline: none;
  }
  input[type="checkbox"] {
    // or change the colour
    filter: hue-rotate(277deg);
  }
}
.item-checkbox {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  height: 4rem;
  border-radius: 1rem;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: #dee4e6;
  margin-bottom: 1rem;

  label {
    cursor: pointer;
  }
  input {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
    transition: 0.2s ease-in-out;
    outline: none;
  }
  input[type="checkbox"] {
    // or change the colour
    filter: hue-rotate(277deg);
  }
}
.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}
</style>
