<template class="template">
<span>
      <v-card class="card-shadow" width="100%" v-if="!loading">
      <h2 class="ml-5 mt-5">Cadastro de Kit</h2>
      <v-card-text class="card-padding">

          <v-row>
            <v-col cols="12" lg="6" md="6" xs="12" class="mb-0 pb-0">
               
              <Dropdown
                placeholder="Selecione um hotel"
                :options="hoteis"
                v-model="hotel"
                id="hotel"
                data-cy="hotel"
                :border="'2px solid rgba(15, 136, 242, 0.3)'"
                :borderactive="'2px solid rgba(15, 136, 242)'"
                :borderradius="'0.7125rem'"
                :icon="'ni ni-building'"
                :ableToSearch="true"
                :reset="hotelreset"
                label="hotel"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" xs="12" class="mb-0 pb-0">
              <Input 
              name="nome"
              :input="true" 
              label='nome' 
              icon="ni ni-circle-08"
              v-model="nome"
              placeholder="insira o nome do kit"/>           
            </v-col>
            <v-col cols="12" lg="6" md="6" xs="12" class="mb-5">
              <Input 
              :textarea="true" 
              name='descricao' 
              label='descricao' 
              v-model="descricao"
              placeholder="insira o descrição do kit"/>  
              </v-col>
          </v-row> 
        
            <section class="section">
              <div class="section-header">
                <h1>Imagens</h1>
              </div>
              <div class="section-content">
                <form>
                  <span class="form-header">
                    <button 
                      type="button" 
                      @click.stop="clicarElemento('btn-upload-file imagem')"
                      class="btn"
                    >
                      <v-icon>
                        ni ni-fat-add
                      </v-icon>
                    </button>
                    <p v-if="imagens.length > 0">{{imagens.length}} imagens</p>
                  </span>
                  <input 
                    type="file" 
                    :multiple="true" 
                    @change="(e)=>alteracaoCampoMidias(e, 'imagem')" 
                    accept="image/png, image/jpeg"
                    hidden
                    id="btn-upload-file imagem"
                    data-cy="btn-upload-file imagem"
                  />
                  <button type="reset" id="btn-reset-files imagem"/>
                </form>  
                <div class="wrapper-midias">
                  <div v-for="imagem,idx in imagens" :key="idx" class="item-midias">
                    <div class="item-midias-header">
                      <button 
                        type="button" 
                        class="btn-item-imagem icon danger"
                        @click="removerMidia(imagem.key, 'imagem')">
                          <v-icon>ni ni-fat-remove</v-icon>
                      </button>
                      <button 
                        type="button"
                        class="btn-item-imagem link"
                        @click="clicarElemento(imagem.key)">
                          alterar imagem
                      </button>
                      <input 
                        type="file"
                        @change="(e)=>editarMidia(e,idx, 'imagem')" 
                        accept="image/png, image/jpeg" 
                        :id="imagem.key"
                        hidden
                      />
                    </div>
                    <img 
                      :src="'data:' + imagens[idx].tipo + ';base64,' + imagens[idx].dados"
                      v-if="imagens[idx].dados"/> 
                  </div>
                  <span 
                    class="wrapper-aviso" 
                    v-if="!(imagens.length > 0)"
                    @click="clicarElemento('btn-upload-file imagem')"
                  >
                    <div class="card-aviso">
                      <v-icon>ni ni-image</v-icon>
                      <h4>sem imagens ainda</h4>  
                      <h3>clique para adicionar</h3>  
                    </div>
                  </span>
                </div>
              </div>
            </section>
            
            <section class="section">
              <div class="section-header">
                <h1>Vídeos</h1>
              </div>
              <div class="section-content">
                <form>
                  <span class="form-header">
                    <button 
                      type="button" 
                      @click.stop="modalvideo = !modalvideo"
                      class="btn"
                      data-cy="btn-modal-video"
                    >
                      <v-icon>
                        ni ni-fat-add
                      </v-icon>
                    </button>
                    <p v-if="videos.length > 0">{{videos.length}} vídeos</p>
                  </span>
                </form>  
                <div class="wrapper-midias">
                  <div v-for="video,idx in videos" :key="idx" class="item-midias">
                    <div class="item-midias-header">
                      <button 
                        type="button" 
                        class="btn-item-imagem icon danger"
                        @click="removerMidia(video.key, 'video')">
                          <v-icon>ni ni-fat-remove</v-icon>
                      </button>
                      <button 
                        type="button"
                        class="btn-item-imagem link"
                        @click="alterarVideo(video,idx)">
                          alterar vídeo
                      </button>
                    </div>
                    <iframe 
                      width="300" 
                      height="215" 
                      :src="video.dados.replace('watch?v=', 'embed/')" 
                      title="YouTube video player" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
                      </iframe> 
                  </div>
                  <span 
                    class="wrapper-aviso" 
                    v-if="!(videos.length > 0)"
                    @click="modalvideo = !modalvideo"
                  >
                    <div class="card-aviso">
                      <v-icon>ni ni-camera-compact</v-icon>
                      <h4>sem vídeos ainda</h4>  
                      <h3>clique para adicionar</h3>  
                    </div>
                  </span>
                </div>
                <div 
                  v-if="modalvideo"
                  class="wrapper-modal" 
                >
                  <form id="modal-video" class="form" @submit="submeterVideo">
                      <span class="form-header">
                        <button 
                          type="button" 
                          @click.stop="modalvideo = !modalvideo"
                          class="btn secondary"
                        >
                          <v-icon>
                            ni ni-fat-remove
                          </v-icon>
                        </button>
                      </span>
                      <div class="form-content">
                        <div class="form-field-group">
                          <div class="form-field">
                            <label class="form-label" for="input-midia-video">URL:</label>
                            <input 
                              class="form-input" 
                              id=input-midia-video
                              name=input-midia-video
                              type="text" 
                              placeholder="insira a url do video" 
                              data-cy="input-midia-video"
                              v-model="video.dados"/>
                          </div>
                          <button type="submit" class="form-btn" data-cy="submeter-video">inserir</button>
                        </div>
                      </div>
                  </form>
                </div>
              </div>
            </section>
            
            <section class="section">
              <div class="section-header">
                <h1>Textos</h1>
              </div>
              <div class="section-content">
                <form>
                  <span class="form-header">
                    <button 
                      type="button" 
                      @click.stop="modaltexto = !modaltexto"
                      class="btn"
                      data-cy="btn-modal-texto"
                    >
                      <v-icon>
                        ni ni-fat-add
                      </v-icon>
                    </button>
                  </span>
                </form>  
                <div class="wrapper-midias">
                  <div v-for="texto,idx in textos" :key="idx" class="item-midias item-texto">
                    <div class="item-midias-header">
                      <button 
                        type="button" 
                        class="btn-item-imagem icon danger"
                        @click="removerMidia(texto.key, 'texto')">
                          <v-icon>ni ni-fat-remove</v-icon>
                      </button>
                      <button 
                        type="button"
                        class="btn-item-imagem link"
                        @click="alterarTexto(texto,idx)">
                          alterar texto
                      </button>
                    </div>
                   <div class="content-texto">{{texto.dados}}</div>
                  </div>
                  <span 
                    class="wrapper-aviso" 
                    v-if="!(textos.length > 0)"
                    @click="modaltexto = !modaltexto"
                  >
                    <div class="card-aviso">
                      <v-icon>ni ni-books</v-icon>
                      <h4>sem textos ainda</h4>  
                      <h3>clique para adicionar</h3>  
                    </div>
                  </span>
                </div>
                <div 
                  v-if="modaltexto"
                  class="wrapper-modal" 
                  >
                  <form id="modal-texto" class="form" @submit="submeterTexto">
                      <span class="form-header">
                        <button 
                          type="button" 
                          @click.stop="modaltexto = !modaltexto"
                          class="btn secondary"
                        >
                          <v-icon>
                            ni ni-fat-remove
                          </v-icon>
                        </button>
                      </span>
                      <div class="form-content">
                        <div class="form-field-group">
                          <div class="form-field">
                            <label class="form-label" for="input-midia-texto">Texto:</label>
                            <input 
                              class="form-input" 
                              id=input-midia-texto
                              name=input-midia-texto
                              type="text" 
                              placeholder="insira a url do texto" 
                              data-cy="input-midia-texto"
                              v-model="texto.dados"/>
                          </div>
                          <button type="submit" class="form-btn" data-cy="submeter-texto">inserir</button>
                        </div>
                      </div>
                  </form>
                </div>
              </div>
            </section>

            <span class="acao-pagina"> 
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-600
                  text-capitalize
                  btn-primary
                  py-3
                  px-6
                  rounded-sm
                  my-0
                  mx-0
                  mr-4
                "
                color="#0f88f2"
                data-cy="btn-envio"
                :disabled="reqloading"
                :loading="reqloading"
                @click="cadastrarKit()">
                  Cadastrar
              </v-btn>
              
              
            </span>
      </v-card-text>

    <button type="reset" id="btn-reset" hidden /> 
    <span v-if="!foiValidado" hidden data-cy="data-cy-error" />
    </v-card>
    <FormSkeleton v-if="loading"/>
</span>
</template>

<script>
import {serializarImagem} from '../../../util/serializar'
import Dropdown from '../../../components/Dropdown.vue'
import Input from '../../../components/Input.vue'
import HotelServico from '../../../servicos/hotel.servico.js'
import { alertar } from "@/servicos/notificacao.servico.js";
import KitServico from '@/servicos/kit.servico.js'
import FormSkeleton from '../../../components/Loading/FormSkeleton.vue'
export default {
  components: { Dropdown, Input, FormSkeleton },
  data(){
    return{
      loading: false,
      nome: null,
      descricao: null,
      imagens: [],
      videos: [],
      modalvideo: false,
      videoid: null,
      video: {
        dados: null
      },
      textos: [],
      modaltexto: false,
      textoid: null,
      texto: {
        dados: null
      },
      hoteis: [],
      hotel: null,
      hotelreset: false,
      foiValidado: null,
      idusuariotipo: null,
      idusuario: null,
      reqloading: false,
    }
  },
  watch: {
    modalvideo: function(){
      if (this.modalvideo === false){
        this.video = {dados: null}
      }
    },
    modaltexto: function(){
      if (this.modaltexto === false){
        this.texto = {dados: null}
      }
    }
  },
  methods: {
    async cadastrarKit(){
      if(this.validacao()){
        this.reqloading = true
        const dadosusuario = {
          idhotel: this.hotel,
          imagens: this.imagens,
          videos: this.videos,
          textos: this.textos,
          nome: this.nome,
          descricao: this.descricao,
        }

        await KitServico.registrar(dadosusuario)
          .then((resp) => {
            if(resp.status === 200){
              this.idhotel = null;
              this.resethotel = null;
              this.videos = new Array()
              this.textos = new Array()
              this.imagens = new Array()
              this.video = {dados: null}
              this.texto = {dados: null}
              this.nome = null
              this.descricao = null
              alertar("success", "=)", "Kit de midia criado com Sucesso!");
            }
          })
          .catch(e => {
              alertar("warning", "=(", e.response.data.msg);
          })
          this.reqloading = false
        
      }
    },
    validacao(){
      if (!this.imagens || this.imagens.length < 1) {
          alertar("warning", "Insira imagens!", "Campo Obrigatório!");
          this.foiValidado = false;
          return false;
        }
        if (!this.videos || this.videos.length < 1) {
          alertar("warning", "Insira videos!", "Campo Obrigatório!");
          this.foiValidado = false;
          return false;
        }
        if (!this.textos || this.textos.length < 1) {
          alertar("warning", "Insira textos!", "Campo Obrigatório!");
          this.foiValidado = false;
          return false;
        }
        if (!this.hotel || this.hotel == 0) {
          alertar("warning", "Insira um hotel valido!", "Campo Obrigatório!");
          this.foiValidado = false;
          return false;
        }
        if (!this.nome || this.nome.trim() == '') {
          alertar("warning", "Insira um nome valido!", "Campo Obrigatório!");
          this.foiValidado = false;
          return false;
        }
        if (!this.descricao || this.descricao.trim() == '') {
          alertar("warning", "Insira um descricao valido!", "Campo Obrigatório!");
          this.foiValidado = false;
          return false;
        }
      return true
    },
    alteracaoCampoMidias(e, type){
      switch(type){
        case 'imagem':
          //serializar a imagem e inseri-la no array imagens
          if (Object.keys(e.target.files).length > 1){
            Object.keys(e.target.files).map(async key => {
              await serializarImagem(e.target.files[key], 0.5)
                .then(resp => {
                  resp['key'] = this.randomString()
                  const estadoatual = this.imagens
                  estadoatual[this.imagens.length] = resp
                  this.imagens = new Array(...estadoatual)
                })
                .catch(error=>{
                  console.log('error serialiando', error)
                })
              })
          }else{
            serializarImagem(e.target.files[0], 0.5)
            .then(resp => {
              resp['key'] = this.randomString()
              const estadoatual = this.imagens
              estadoatual[this.imagens.length] = resp
              this.imagens = new Array(...estadoatual)
            })
            .catch(error=>{
              console.log('error serialiando', error)
            })
          }
          document.getElementById(`btn-reset-files ${type}`).click()  
          return        
        case 'video':
          //serializar a imagem e inseri-la no array imagens
          if (Object.keys(e.target.files).length > 1){
            Object.keys(e.target.files).map(async key => {
              await serializarImagem(e.target.files[key], 0.5)
                .then(resp => {
                  resp['key'] = this.randomString()
                  const estadoatual = this.videos
                  estadoatual[this.videos.length] = resp
                  this.videos = new Array(...estadoatual)
                })
                .catch(error=>{
                  console.log('error serialiando', error)
                })
              })
          }else{
            serializarImagem(e.target.files[0], 0.5)
            .then(resp => {
              resp['key'] = this.randomString()
              const estadoatual = this.videos
              estadoatual[this.videos.length] = resp
              this.videos = new Array(...estadoatual)
            })
            .catch(error=>{
              console.log('error serialiando', error)
            })
          }
          document.getElementById(`btn-reset-files ${type}`).click()  
          return        
       
      }
    },
    editarMidia(e, idx, type){
      switch(type){
        case 'imagem': 
          serializarImagem(e.target.files[0], 0.5)
            .then(resp => {
              resp['key'] = this.randomString()
              const estadoatual = this.imagens
              estadoatual[idx] = resp
              this.imagens = new Array(...estadoatual)
            })
            .catch(error=>{
              console.log('error serialiando', error)
            })
          return
        case 'video': 
          serializarImagem(e.target.files[0], 0.5)
            .then(resp => {
              resp['key'] = this.randomString()
              const estadoatual = this.videos
              estadoatual[idx] = resp
              this.videos = new Array(...estadoatual)
            })
            .catch(error=>{
              console.log('error serialiando', error)
            })
          return
      }
    },
    removerMidia(key,type){
      switch(type){
        case 'imagem':
          const estadoatualimagens = this.imagens.filter(el => el.key !== key)
          this.imagens = new Array(...estadoatualimagens)
          return
        case 'video':
          const estadoatualvideos = this.videos.filter(el => el.key !== key)
          this.videos = new Array(...estadoatualvideos)
          return
        case 'texto':
          const estadoatualtextos = this.textos.filter(el => el.key !== key)
          this.textos = new Array(...estadoatualtextos)
          return
      }
    },
    randomString(){
      return (Math.random() + 1).toString(36).substring(7).split('').sort(() => 0.5-Math.random()).join('');
    },
    clicarElemento(id){
      document.getElementById(id)?.click()
    },
    submeterVideo(e){
      e.preventDefault()
      this.video['key'] = this.randomString()
      const estadoatual = this.videos
      estadoatual[this.videoid !==null ? this.videoid : this.videos.length] = this.video
      this.videos = new Array(...estadoatual)
      this.video = {dados: null}
      this.videoid = null
      this.modalvideo = !this.modalvideo
    },
    alterarVideo(video, id){
      this.video = video
      this.videoid = id
      this.modalvideo = !this.modalvideo
    },
    submeterTexto(e){
      e.preventDefault()
      this.texto['key'] = this.randomString()
      const estadoatual = this.textos
      estadoatual[this.textoid !==null ? this.textoid : this.textos.length] = this.texto
      this.textos = new Array(...estadoatual)
      this.texto = {dados: null}
      this.textoid = null
      this.modaltexto = !this.modaltexto
    },
    alterarTexto(texto, id){
      this.texto = texto
      this.textoid = id
      this.modaltexto = !this.modaltexto
    },
    async requisitarHoteis() {
      if( this.idusuariotipo === 2){
        console.log('=====adm hotel =====')
        const { data } = await HotelServico.listarPorUsuarioAdm(true, this.idusuario);
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis; 
      }else if(this.idusuariotipo === 1){
        console.log('=====adm =====')
        const { data } = await HotelServico.listarTodos();
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
      }
    },
  },
  async mounted(){
    this.loading = true
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    await this.requisitarHoteis()
    this.loading =  false
  }
}
</script>
<style lang="scss" scoped>
.section{
  margin-bottom: 2em;
  min-height: 21em;
 .section-header{
    font-size: clamp(1em, 1vw, 1.5em);  
    margin-bottom: .5em;
  }
}

.section-content{
  padding: 1em .5em;
  background-color: #dee4e6;
  position: relative;
}

.wrapper-midias{
  display: flex;
  overflow: hidden;
  padding: 10px 0;
}
.item-midias{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  padding: 10px;
  border-radius: .7125em;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  min-width: 10em;
  background-color: white;
  flex-shrink: 0;
  .item-midias-header{
    width: 100%;
    display:flex;
    align-items: center;    
    justify-content: space-between;
    margin-bottom: 1em;

    .btn-item-imagem{
      &.icon{
         width: 2em;
         height: 2em;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 100%;
         border:none;

         .v-icon{
           color: white;
           border:none;
         }

         &.danger{
           background-color: red;
         }
         &.text{
           background-color: blue;
         }
        }
      &.link{
        font-size: 12px;
        &:hover{
          color: blue;
          text-decoration: underline;
        }
      }
    }
          
  }
  &.item-texto{
    max-width: 20em;
    height: fit-content;
    max-height: 28em;
    >.content-texto{
      overflow-y: scroll;
      word-wrap: break-word;
      text-overflow: ellipsis;
    }
    
  }
}
.btn{
  background: #187fd9;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin-left: .5em;
  .v-icon{
    color: white
  }

  &.secondary{
    background-color: gray;
    color: black;
  }
}

.wrapper-aviso{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .card-aviso{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover{
      cursor: pointer;
    }
    .v-icon{
      font-size: 7em;
      color: #bbb;
    }

    h4{
      color: #bbb;
    }

    h3{
      color: #bbb;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

.form{
  position: relative;
}
.form-header{
  display: flex;
  align-items: flex-end;
  p{
    margin: 0 .5em;
  }
}

.form-content{
  width: 100%;
  height: 100%;
  }

.form-field-group{
  margin: 1em auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 30em;
  background-color: #dee4e6;
  padding: 1em;
  height: 90%;
  border-radius: .7125em;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);  

 } 
.form-field{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1em;
  @media screen and (max-width:480px){
    width: 100%;
  }
}
.form-label{
  font-size: clamp(1em, 1vw, 1.5em);  
  text-transform: capitalize;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.02rem;
  margin-bottom: 0.5rem;
}
.form-input{
  border-radius: .7125em;
  background-color: white;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);  
  height: 3em;
  min-width: 20em;
  padding: 0 1em;
  outline: none;
  
  @media screen and (max-width:480px){
    min-width: unset;
    width: 100%;
  }
}

.form-btn{
  width: 7em;
  height: 3em;
  border-radius: .7125em;
  background-color: #0f88f2;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);  
  text-transform: uppercase;
  color: white;

  @media screen and (max-width:480px){
    width: 100%;
  }
}

.wrapper-modal{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 1em .5em;
}

.acao-pagina{
  display: flex;
  align-items: center;
}
//css indesejado
.v-application p {
  margin-bottom: unset;
}
</style>