<template>
  <div :class="textarea ? 'form-field-textarea' : 'form-field'">
    <label :for="name" v-if="!nolabel">{{ label }}:</label>
    <span class="field">
      <v-icon size="22" v-if="icon">{{ icon }}</v-icon>
      <input
        :type="type"
        :name="name"
        autocomplete="off"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        v-if="input"
        :disabled="disabled"
        v-model="value"
        :id="name"
        @input="(e) => this.$emit('input', e.target.value)"
        :data-cy="name"
      />
      <textarea
        :name="name"
        autocomplete="off"
        :placeholder="placeholder"
        :disabled="disabled"
        v-if="textarea"
        v-model="value"
        @input="(e) => this.$emit('input', e.target.value)"
        :data-cy="name"
      />
      <Dropdown
        v-if="select"
        :options="options"
        :multiple="false"
        :border="'none'"
        :borderradius="'0.7125rem'"
        :label="placeholder"
        :initialValue="initialValue"
        :disabled="disabled"
      />
    </span>
    <span class="error">{{ error }}</span>
  </div>
</template>
<script>
const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

export default {
  props: {
    name: String,
    type: String,
    icon: String,
    placeholder: String,
    select: Boolean,
    input: Boolean,
    textarea: Boolean,
    options: [],
    initialValue: String,
    disabled: Boolean,
    min: String,
    max: String,
    value: Object,
    nolabel: Boolean,
    label: String
  },
  data() {
    return {};
  },
  computed: {
    cssVars() {
      return {
        /* variables you want to pass to css */
        "--border": this.border,
        "--border-active": this.borderactive,
        "--border-radius": this.borderradius,
      };
    },
  },
  methods: {},
  mounted() {
    this.$emit("input", this.initialValue);
  },
};
</script>
<style lang="scss" scoped>
.form-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0.5rem;
  flex-grow: 0;
  margin-right: 1rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);

  > span.field {
    width: 100%;
    display: flex;
    align-items: center;
    border: 2px solid rgba(15, 136, 242, 0.3);
    border-radius: 0.7125rem;
    transition: 0.2s all;
    > i {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s all;
      margin: 0 0.7rem;
      color: rgba(15, 136, 242, 0.5);
    }
    > input,
    textarea {
      border-radius: 0.5rem;
      outline: none;
      width: 100%;
      min-height: 2.75rem;
      font-size: 14px;
      letter-spacing: 0.02rem;
    }
    > textarea {
      padding: 0.5rem;
      min-height: 2.75rem;
      resize: y;
      overflow: hidden;
    }
    &:focus-within {
      border: 2px solid rgb(15, 136, 242);
      > i {
        color: rgb(15, 136, 242);
      }
    }
  }

  label {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.02rem;
    margin-bottom: 0.5rem;
  }
}

.form-field-textarea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0.5rem;
  flex-grow: 0;
  margin-right: 1rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);

  > span.field {
    width: 100%;
    display: flex;
    align-items: center;
    border: 2px solid rgba(15, 136, 242, 0.3);
    border-radius: 0.7125rem;
    transition: 0.2s all;
    > i {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s all;
      margin: 0 0.7rem;
      color: rgba(15, 136, 242, 0.5);
    }
    textarea {
      border-radius: 0.5rem;
      outline: none;
      width: 100%;
      min-height: 7rem;
      color: rgb(0, 0, 0);
      font-size: 14px;
      letter-spacing: 0.02rem;
      padding: 0.5rem;
      resize: y;
      overflow: hidden;
    }
    &:focus-within {
      border: 2px solid rgb(15, 136, 242);
      > i {
        color: rgb(15, 136, 242);
      }
    }
  }

  label {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.02rem;
    margin-bottom: 0.5rem;
  }
}

//css indesejados
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
