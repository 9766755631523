import api from '@/servicos/api'

export default {
  estados(body) {
    return api().post('/geo/estado/select/', body)
  },
  paises(body) {
    return api().post('/geo/pais/select/', body)
  },
  cidades(body) {
    return api().post('/geo/cidade/select/', body)
  },
  vincularZona(body) {
    return api().post('/geo/cidade/zona', body)
  },
  listarZonaPorUsuario(id){
    return api().post('/geo/zonas', {idpessoa: id})
  }
}
