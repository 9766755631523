module.exports = (cpf) => {
  const cpfnumber = cpf.replace('.', '').replace('.', '').replace('-', '')
  const cpfString = cpf.replace('.', '').replace('.', '').replace('-', '').split("")
  /**
   * calculo do primeiro digito verificador
   * 
   * utiliza os nove primeiros numeros numa multiplicacao 
   * pela sequencia decrescente de 10 a 2 
  */
  let sequence = [10, 9, 8, 7, 6, 5, 4, 3, 2]
  let pre_result = []
  for (let i = 0; i <= 8; i++) {
    pre_result.push(cpfString[i] * sequence[i])
  }
  const result = pre_result.reduce((total, currEl) => total + currEl)
  const pdv = (11 - (result % 11)) >= 10 ? 0 : (11 - (result % 11))
  const isValiidPdv = parseInt(cpfString[9]) === pdv


  /**
 * calculo do segunfo digito verificador
 * 
 * utiliza os nove primeiros numeros numa multiplicacao 
 * pela sequencia decrescente de 11 a 2 
*/
  let sequence2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2]
  let pre_result2 = []
  for (let i = 0; i <= 9; i++) {
    pre_result2.push(cpfString[i] * sequence2[i])
  }
  const result2 = pre_result2.reduce((total, currEl) => total + currEl)
  const sdv = (11 - (result2 % 11)) >= 10 ? 0 : (11 - (result2 % 11))
  const isValiidSdv = parseInt(cpfString[10]) === sdv


  if (isValiidPdv && isValiidSdv) {
    if (['11111111111', '22222222222', '33333333333',
      '44444444444', '555555555555', '66666666666',
      '77777777777', '88888888888', '99999999999'].includes(cpfnumber)) {
      return false
    }
    return true
  }
  if (!isValiidPdv || !isValiidSdv) {
    return false
  }
}