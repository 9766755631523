import api from '@/servicos/api'

export default {
  getRegrasPorHotel(idhotel) {
    return api().get('/regra/'+idhotel)
  },

  inativarRegra(idhotel,idregra) {
    return api().post(`/regra/inativar/${idhotel}/${idregra}`)
  },

  ativarRegra(idhotel,idregra) {
    return api().post(`/regra/ativar/${idhotel}/${idregra}`)
  },

}
