<template>
  <div class="page-wrapper">
    <v-col cols="12" lg="10" md="7">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <LeituraReserva />
        </v-card>
      </v-form>
    </v-col>
  </div>
</template>



<script>
import LeituraReserva from "../Components/Leituras/LeituraReserva.vue";
export default {
  name: "ReadReserve",
  components: {
    LeituraReserva,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.page-wrapper {
  display: flex;
  justify-content: center;
}
</style>