<template class="template">
    <v-card class="card-shadow" width="100%">
      
 <v-row class="card-padding">
        <v-col cols="12" lg="6">
          <Input 
          :input="true" 
          name="premio-bronze" 
          initialValue="Bronze" 
          type="text"
          label="nível"
          icon="ni ni-trophy"
          v-model="bronze"
          :disabled="true"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <Input 
          :input="true" 
          name="pontuacao-bronze" 
          type="number"
          label="Quantidade de Pontos"
          placeholder="Insira a quantidade de pontos"
          icon="ni ni-app"
          v-model="pontosbronze"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <Input 
          :input="true" 
          name="tempo-bronze" 
          type="number"
          label="Ultimos dias"
          placeholder="Insira a quantidade de tempo"
          icon="ni ni-user-run"
          v-model="tempobronze"
          />
        </v-col>
      </v-row>
      <v-row class="card-padding">
        <v-col cols="12" lg="6">
          <Input 
          :input="true" 
          name="premio-prata" 
          initialValue="Prata" 
          type="text"
          label="nível"
          icon="ni ni-trophy"
          v-model="prata"
          :disabled="true"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <Input 
          :input="true"
          type="number"
          name="pontuacao-prata" 
          label="Quantidade de Pontos"
          placeholder="Insira a quantidade de pontos"
          icon="ni ni-app"
          v-model="pontosprata"/>
        </v-col>
        <v-col cols="12" lg="6">
          <Input 
          :input="true" 
          name="tempo-prata" 
          placeholder="Insira a quantidade de tempo"
          type="number" 
          label="Ultimos dias"
          icon="ni ni-user-run"
          v-model="tempoprata"/>
        </v-col>
      </v-row>
      <v-row class="card-padding">
        <v-col cols="12" lg="6">
          <Input 
          :input="true" 
          name="premio-ouro" 
          initialValue="Ouro" 
          type="text"
          label="nível"
          icon="ni ni-trophy"
          v-model="ouro"
          :disabled="true"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <Input 
          :input="true" 
          name="pontuacao-ouro" 
          type="number" 
          placeholder="Insira a quantidade de pontos"
          label="Quantidade de Pontos"
          icon="ni ni-app"

          v-model="pontosouro"/>
        </v-col>
        <v-col cols="12" lg="6">
          <Input 
          :input="true" 
          name="tempo-ouro" 
          type="number" 
          label="Ultimos dias"
          placeholder="Insira a quantidade de tempo"
          icon="ni ni-user-run"
          v-model="tempoouro"/>
        </v-col>
      </v-row>
     
   <v-row class="card-padding py-0 my-0">
      <v-col cols="12" lg="6" class="py-0 my-0">
      <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      data-cy="btn-envio"
      color="#0f88f2"
      @click="salvar"
      >Salvar</v-btn
    >
    </v-col>
   </v-row>
    </v-card>  
</template>
<script>
import Input from "../../../components/Input.vue";
import Dropdown from "../../../components/Dropdown.vue";
import { alertar } from "@/servicos/notificacao.servico.js";
import NivelServico from '@/servicos/nivel.servico.js';

export default {
  name: "LeituraConfigNiveis",
  components: { Input, Dropdown },
  data() {
    return {
      bronze: 'Bronze',
      prata: 'Prata',
      ouro: 'Ouro',
      idusuario: null, 
      idusuariotipo: null,
      foivalidado: null,
      pontosouro: null,
tempoouro: null,
pontosprata: null,
tempoprata: null,
pontosbronze: null,
tempobronze: null,
    };
  },
  props: {
    niveis: Array,
    idhotel: String || Number
  },

  methods: {
    async salvar(){
      if(this.validacao()){
      const nivelouro = this.niveis.filter(el => el.idnivel == 3)[0]
      nivelouro['qtdepontos'] = parseInt(this.pontosouro)
      nivelouro['qtdedias'] = parseInt(this.tempoouro)
      const nivelprata = this.niveis.filter(el => el.idnivel == 2)[0]
      nivelprata['qtdepontos'] = parseInt(this.pontosprata)
      nivelprata['qtdedias'] = parseInt(this.tempoprata)
      const nivelbronze = this.niveis.filter(el => el.idnivel == 1)[0]
      nivelbronze['qtdepontos'] = parseInt(this.pontosbronze)
      nivelbronze['qtdedias'] = parseInt(this.tempobronze)
      this.niveis = [nivelouro, nivelprata, nivelbronze]

      const dadosdousuario = {
        niveis: this.niveis,
      }
      NivelServico.atualizar(dadosdousuario)
          .then((resp) => {
            if (resp.status === 200) {
              alertar("success", "=)", "Niveis configurado com sucesso!");
            }
          })
          .catch((error) => {
            alertar("warning", "=(", error.response.data.msg);
          });
    }
    },
    validacao(){
      if (!this.pontosbronze || this.pontosbronze == 0) {
        alertar("warning", "Informe uma pontuação válida para o nivel bronze!", "Campo Obrigatório!");
        this.foivalidado = false;
        return false;
      }
      if (!this.pontosprata || this.pontosprata == 0) {
        alertar("warning", "Informe uma pontuação válida para o nivel prata!", "Campo Obrigatório!");
        this.foivalidado = false;
        return false;
      }
      if (!this.pontosouro || this.pontosouro == 0) {
        alertar("warning", "Informe uma pontuação válida para o nivel ouro!", "Campo Obrigatório!");
        this.foivalidado = false;
        return false;
      }
      if (!this.tempoouro || this.tempoouro == 0) {
        alertar("warning", "Informe uma quantidade de dias válida para o nivel ouro!", "Campo Obrigatório!");
        this.foivalidado = false;
        return false;
      }
      if (!this.tempoprata || this.tempoprata == 0) {
        alertar("warning", "Informe uma quantidade de dias válida para o nivel prata!", "Campo Obrigatório!");
        this.foivalidado = false;
        return false;
      }
      if (!this.tempobronze || this.tempobronze == 0) {
        alertar("warning", "Informe uma quantidade de dias válida para o nivel bronze!", "Campo Obrigatório!");
        this.foivalidado = false;
        return false;
      }
      return true
    }
    
  },
  async mounted(){
      const nivelouro = this.niveis.filter(el => el.idnivel == 3)[0]
      this.pontosouro = nivelouro.qtdepontos
      this.tempoouro = nivelouro.qtdedias
      const nivelprata = this.niveis.filter(el => el.idnivel == 2)[0]
      this.pontosprata = nivelprata.qtdepontos
      this.tempoprata = nivelprata.qtdedias
      const nivelbronze = this.niveis.filter(el => el.idnivel == 1)[0]
      this.pontosbronze = nivelbronze.qtdepontos
      this.tempobronze = nivelbronze.qtdedias

  }
};
</script>
<style lang="scss" scoped>
.rotas-lista{
  list-style: none;
  max-height: 20em;
  overflow-y: scroll;
}

.rotas-item{
  display: flex;
  align-items: center;
  margin-bottom: .5em;
  background-color: #dee4e6;
  padding: .75em 1em;
  border-radius: 0.7125rem;

  &:hover{
    cursor: pointer;
  }
  input{
    margin-right: .5em;
    width: 1.5em;
    height: 1.5em;
    &:hover{
      cursor: pointer;
    }
  }
}

.mensagem{
  background-color: #dee4e6;
  color: #bbb;
  width: 100%;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
    border-radius: 0.7125rem;

}
//css indesejado
.v-application ul, .v-application ol {
    padding-left: 0;
}
</style>