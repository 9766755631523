<template class="template">
    <v-card class="card-shadow" width="100%">
    <span v-if="!loading">
      <h2 class="ml-5 mt-5">Exibição do Kit</h2>
      <v-card-text class="card-padding">

          <v-row>
            <v-col cols="12" lg="3" md="3" xs="12" class="mb-0 pb-0"> 
            <div class="exibicao-field">
              <p class="label">Hotel:</p>
              <p class="info">{{hotel.nome}}</p>
            </div>
            <div class="exibicao-field">
              <p class="label">Kit:</p>
              <p class="info">{{nome}}</p>          
            </div>
            </v-col>
            <v-col cols="12" lg="9" md="9" xs="12" class="">
              <div class="exibicao-field">
                <p class="label">descrição:</p>
                <p class="info">{{descricao}}</p> 
              </div>  
            </v-col>
          </v-row> 
        
            <section class="section">
              <div class="section-header">
                <h1>Imagens</h1>
              </div>
              <div class="section-content">  
                <div class="wrapper-midias">
                  <div v-for="imagem,idx in imagens" :key="idx" class="item-midias">
                    <div class="item-midias-header">

                      <button 
                        type="button"
                        class="btn-item-imagem link"
                        @click="downloadBase64File(imagem.tipo, imagem.dados,imagem.chave)">
                          download imagem
                      </button>
                    </div>
                    <img 
                      :src="'data:' + imagens[idx].tipo + ';base64,' + imagens[idx].dados"
                      v-if="imagens[idx].dados"/> 
                  </div>
                  <span 
                    class="wrapper-aviso" 
                    v-if="!(imagens.length > 0)"
                    @click="clicarElemento('btn-upload-file imagem')"
                  >
                    <div class="card-aviso">
                      <v-icon>ni ni-image</v-icon>
                      <h4>sem imagens ainda</h4>  
                      <h3>clique para adicionar</h3>  
                    </div>
                  </span>
                </div>
              </div>
            </section>
            
            <section class="section">
              <div class="section-header">
                <h1>Vídeos</h1>
              </div>
              <div class="section-content">
                
                <div class="wrapper-midias">
                  <div v-for="video,idx in videos" :key="idx" class="item-midias">
                    <div class="item-midias-header">
                      
                      <button 
                        type="button"
                        class="btn-item-imagem link"
                        @click="copiar(video, 'video')">
                          copiar url
                      </button>
                    </div>
                    <iframe 
                      width="300" 
                      height="215" 
                      :src="video.dados.replace('watch?v=', 'embed/')" 
                      title="YouTube video player" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen>
                      </iframe> 
                  </div>
                  <span 
                    class="wrapper-aviso" 
                    v-if="!(videos.length > 0)"
                    @click="modalvideo = !modalvideo"
                  >
                    <div class="card-aviso">
                      <v-icon>ni ni-camera-compact</v-icon>
                      <h4>sem vídeos ainda</h4>  
                      <h3>clique para adicionar</h3>  
                    </div>
                  </span>
                </div>
                <span class="aviso-copiado" v-if="videocopiado">video copiado</span>               
              </div>
            </section>
            
            <section class="section">
              <div class="section-header">
                <h1>Textos</h1>
              </div>
              <div class="section-content">
                 
                <div class="wrapper-midias">
                  <div v-for="texto,idx in textos" :key="idx" class="item-midias item-texto">
                    <div class="item-midias-header">
                      <button 
                        type="button"
                        class="btn-item-imagem link"
                        @click="copiar(texto, 'texto')">
                          copiar texto
                      </button>
                    </div>
                   <div class="content-texto">{{texto.dados}}</div>
                  </div>
                  <span 
                    class="wrapper-aviso" 
                    v-if="!(textos.length > 0)"
                    @click="modaltexto = !modaltexto"
                  >
                    <div class="card-aviso">
                      <v-icon>ni ni-books</v-icon>
                      <h4>sem textos ainda</h4>  
                      <h3>clique para adicionar</h3>  
                    </div>
                  </span>
                </div>
                <span class="aviso-copiado" v-if="textocopiado">texto copiado</span>               
               
              </div>
            </section>
      </v-card-text>

    </span>
    <KitSkeleton v-if="loading"/>

    <button type="reset" id="btn-reset" hidden /> 
    <span v-if="!foiValidado" hidden data-cy="data-cy-error" />
    </v-card>
</template>

<script>
import Dropdown from '../../../components/Dropdown.vue'
import Input from '../../../components/Input.vue'
import HotelServico from '../../../servicos/hotel.servico.js'
import KitServico from '@/servicos/kit.servico.js'
import KitSkeleton from '../../../components/Loading/KitSkeleton'
export default {
  components: { Dropdown, Input, KitSkeleton },
  data(){
    return{
      kit: null,
      nome: null,
      descricao: null,
      imagens: [],
      videos: [],
      textos: [],
      hoteis: [],
      hotel: null,
      hotelreset: false,
      videocopiado: false,
      textocopiado: false,
      loading: false
    }
  },
  methods: {

    async requisitarHotel(id) {
      const { data } = await HotelServico.listar(id);
      this.hotel = data[0];
    },
    async requisitarKit(id){
      const {data} = await KitServico.listar(id)
      this.kit = data 
    },
    downloadBase64File(contentType, base64Data, fileName) {
     const linkSource = `data:${contentType};base64,${base64Data}`;
     const downloadLink = document.createElement("a");
     downloadLink.href = linkSource;
     downloadLink.download = fileName;
     downloadLink.click();
    },
    copiar(elemento, type) {
      switch(type){
        case 'video':
          navigator.clipboard.writeText(elemento.dados);
          this.videocopiado = !this.videocopiado
          setTimeout(() => {this.videocopiado = !this.videocopiado}, 2000);
          return
        case 'texto':
          navigator.clipboard.writeText(elemento.dados);
          this.textocopiado = !this.textocopiado
          setTimeout(() => {this.textocopiado = !this.textocopiado}, 2000);
          return
      }
      
    }
  },
  async mounted(){
    this.loading = true
    const {id} = this.$route.params
    await this.requisitarKit(id)
    this.nome = this.kit.nome
    this.descricao = this.kit.descricao
    this.hotel = this.kit.idhotel
    this.imagens = this.kit.imagens
    this.videos = this.kit.videos
    this.textos = this.kit.textos
    await this.requisitarHotel(this.hotel)
    this.loading = false
  }
}
</script>
<style lang="scss" scoped>
.section{
  margin-bottom: 2em;

  .section-header{
    font-size: clamp(1em, 1vw, 1.5em);  
    margin-bottom: .5em;
  }
}

.section-content{
  padding: 1em .5em;
  background-color: #dee4e6;
  position: relative;
  border-radius: .7125em;
}

.wrapper-midias{
  display: flex;
  overflow: hidden;
  padding: 10px 0;
}
.item-midias{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  padding: 10px;
  border-radius: .7125em;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  min-width: 10em;
  background-color: white;
  flex-shrink: 0;
  .item-midias-header{
    width: 100%;
    display:flex;
    align-items: center;    
    justify-content: space-between;
    margin-bottom: 1em;

    .btn-item-imagem{
      &.icon{
         width: 2em;
         height: 2em;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 100%;
         border:none;

         .v-icon{
           color: white;
           border:none;
         }

         &.danger{
           background-color: red;
         }
         &.text{
           background-color: blue;
         }
        }
      &.link{
        font-size: 12px;
        &:hover{
          color: blue;
          text-decoration: underline;
        }
      }
    }
          
  }
  &.item-texto{
    max-width: 20em;
    height: fit-content;
    max-height: 28em;
    >.content-texto{
      overflow-y: scroll;
      word-wrap: break-word;
    }
    
  }
}
.btn{
  background: #187fd9;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin-left: .5em;
  .v-icon{
    color: white
  }

  &.secondary{
    background-color: gray;
    color: black;
  }
}

.wrapper-aviso{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .card-aviso{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover{
      cursor: pointer;
    }
    .v-icon{
      font-size: 7em;
      color: #bbb;
    }

    h4{
      color: #bbb;
    }

    h3{
      color: #bbb;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}


.exibicao-field{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1em;
  @media screen and (max-width:480px){
    width: 100%;
  }
}
.label{
  font-size: clamp(1em, 1vw, 1.5em);  
  text-transform: capitalize;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.02rem;
  margin-bottom: 0.5rem;
}
.info{
  background-color: white;
  outline: none;
  
  @media screen and (max-width:480px){
    min-width: unset;
    width: 100%;
  }
}

.form-btn{
  width: 7em;
  height: 3em;
  border-radius: .7125em;
  background-color: #0f88f2;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);  
  text-transform: uppercase;
  color: white;

  @media screen and (max-width:480px){
    width: 100%;
  }
}

.aviso-copiado{
  width: 12em;
  background-color: white;
  padding: 0.5em 1em;
  border-left: 2px solid green;
  position: absolute;
  bottom: 5%;
  right: 0;
  text-transform: capitalize;
}
.acao-pagina{
  display: flex;
  align-items: center;
}
//css indesejado
.v-application p {
  margin-bottom: unset;
}
</style>