module.exports = (cnpj) => {
  const cnpjnumber = cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '')
  const cnpjString = cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '').split("")
  /**
   * calculo do primeiro digito verificador
   * 
   * utiliza os nove primeiros numeros numa multiplicacao 
   * pela sequencia decrescente de 10 a 2 
  */
  let sequence = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5]
  let pre_result = []
  for (let i = 11; i >= 0; i--) {
    pre_result.push(cnpjString[i] * sequence[11 - i])
  }
  const result = pre_result.reduce((total, currEl) => total + currEl)
  const pdv = (11 - (result % 11)) >= 10 ? 0 : (11 - (result % 11))
  const isValiidPdv = parseInt(cnpjString[12]) === pdv


  /**
 * calculo do segunfo digito verificador
 * 
 * utiliza os nove primeiros numeros numa multiplicacao 
 * pela sequencia decrescente de 11 a 2 
*/
  let sequence2 = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6]
  let pre_result2 = []
  for (let i = 12; i >= 0; i--) {
    pre_result2.push(cnpjString[i] * sequence2[12 - i])
  }
  const result2 = pre_result2.reduce((total, currEl) => total + currEl)
  const sdv = (11 - (result2 % 11)) >= 10 ? 0 : (11 - (result2 % 11))
  const isValiidSdv = parseInt(cnpjString[13]) === sdv

  let isValid = false

  if (isValiidPdv && isValiidSdv) {
    if (['11.111.111/1111-11', '22.222.222/2222-22', '33.333.333/3333-33',
      '44.444.444/4444-44', '55.555.555/5555-55', '66.666.666/6666-6',
      '77.777.777/7777-77', '88.888.888/8888-88', '99.999.999/9999-99'].includes(cnpjnumber)) {
      return isValid = false
    }
    return isValid = true
  }
  if (!isValiidPdv || !isValiidSdv) {
    return isValid = false
  }
  return isValid
}
