<template class="template">
<div>
  <form
    id="formularioCadastroReserva"
    class="formularioCadastroReserva"
    autocomplete="off"
    v-if="!loading"
  >
    <v-card class="card-shadow" width="100%">
      <h2 class="ml-5 mt-5">Cadastro de Venda</h2>



      <v-card-text class="card-padding">
                <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Dropdown
              placeholder="Selecione um hotel"
              :options="hoteis"
              v-model="hotel"
              id="hotel"
              label="Hotel"
              :sideEffect="alteracaoCampoHoteis"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :reset="hotelreset"

            />
          </v-col>
          <!-- <v-col cols="12" md="6" sm="6" xs="6">
           
            <Dropdown
              :options="agencias"
              v-model="agencia"
              id="agencia"
              label="Agência"
              placeholder="Selecione uma agência"
              data-cy="agencia"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-briefcase-24'"
              :ableToSearch="true"
              :reset="agenciareset"
            />
          </v-col> -->
        </v-row>
        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="nome"
              label="nome do hóspede"
              v-model="nome"
              :input="true"
              icon="ni ni-circle-08"
              placeholder="Insira seu nome"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="sobrenome"
              label="sobrenome do hóspede"
              v-model="sobrenome"
              :input="true"
              icon="ni ni-hat-3"
              placeholder="Insira seu sobrenome"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="email"
              label="email do hóspede"
              v-model="email"
              :input="true"
              icon="ni ni-email-83"
              placeholder="Insira seu email"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="celular"
              label="celular do hóspede"
              v-model="celular"
              v-mask="'(##) # ####-####'"
              type="tel"
              :input="true"
              icon="ni ni-mobile-button"
              placeholder="Insira seu celular"
            />
          </v-col>
        </v-row>



        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="facebook"
              label="facebook do hóspede"
              v-model="facebook"
              :input="true"
              icon="ni ni-ungroup"
              placeholder="Insira seu facebook"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="instagram"
              label="instagram do hóspede"
              v-model="instagram"
              :input="true"
              icon="ni ni-ungroup"
              placeholder="Insira seu instagram"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="linkedin"
              label="linkedin do hóspede"
              v-model="linkedin"
              :input="true"
              icon="ni ni-ungroup"
              placeholder="Insira seu linkedin"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            
            <Dropdown
              :options="operadoras"
              label="Operadora"
              placeholder="Selecione uma operadora"
              :multiple="false"
              v-model="operadora"
              data-cy="operadora"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-briefcase-24'"
              :reset="operadorareset"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="checkin"
              label="checkin"
              v-model="checkin"
              :input="true"
              type="date"
              icon="ni ni-calendar-grid-58"
              placeholder=""
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="checkout"
              label="checkout"
              v-model="checkout"
              :input="true"
              type="date"
              icon="ni ni-calendar-grid-58"
              placeholder=""
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="chegada"
              label="chegada"
              v-model="chegada"
              :initialValue="chegada"
              :input="true"
              type="time"
              icon="ni ni-watch-time"
              placeholder=""
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="partida"
              label="partida"
              v-model="partida"
              :initialValue="partida"
              :input="true"
              type="time"
              icon="ni ni-watch-time"
              placeholder=""
            />
          </v-col>
        </v-row>

        <label for="" class="label-color font-weight-600 mt-2 d-block"
          >Solicitações Especiais</label
        >
        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6" data-cy="solicitacoes">
            <div
              v-for="(item, idx) in solicitacoes"
              :key="item.nome"
              v-if="solicitacoes[idx].hotelatende"
              :class="
                solicitacoes[idx].solicitado
                  ? 'item-checkbox-checked'
                  : 'item-checkbox'
              "
              @click="
                solicitacoes[idx].solicitado = !solicitacoes[idx].solicitado
              "
            >
              <input
                type="checkbox"
                v-model="solicitacoes[idx].solicitado"
                :id="`item-${item.nome}`"
                :name="`item-${item.nome}`"
                :label="`item-${item.nome}`"
                :data-cy="`${item.nome.toLowerCase()}`"
              />
              <label
                :for="`item-${item.nome}`"
                @click="
                  solicitacoes[idx].solicitado = !solicitacoes[idx].solicitado
                "
                >{{ item.nome }}</label
              >
            </div>
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="12" sm="12" xs="12">
            <Input
              name="mensagem"
              label="mensagem"
              v-model="mensagem"
              :textarea="true"
              placeholder="Deixe sua mensagem"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <!-- <v-checkbox
                v-model="checkbox"
                color="#0f88f2"
                :ripple="false"
                class="ma-0 checkbox-custom checkbox-thinner"
                hide-details
              >
                <template v-slot:label>
                  <span class="text-muted text-body-2 ls-0"
                    >Li e Estou de Acordo com o
                    <a
                      href="javascript:;"
                      class="text-primary text-decoration-none"
                      >Termo de Privacidade</a
                    >
                  </span>
                </template>
              </v-checkbox> -->
        </v-row>
      </v-card-text>
    </v-card>

    <button type="reset" id="btn-reset" hidden />
    <span v-if="!foiValidado" hidden data-cy="data-cy-error" />

    <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      data-cy="btn-envio"
      color="#0f88f2"
      :disabled="reqloading"
      :loading="reqloading"
      @click="envioFormulario"
      >Cadastrar</v-btn
    >
  </form>
  <FormSkeleton v-if="loading"/>
</div> 
</template>

<script>
import AgenciaServico from "../../../servicos/agencia.servico";
import { alertar } from "@/servicos/notificacao.servico.js";
import ReservaServico from "../../../servicos/reserva.servico";
import HotelServico from "../../../servicos/hotel.servico";
import OperadoraServico from "../../../servicos/operadora.servico";
import Dropdown from "../../../components/Dropdown.vue";
import Input from "../../../components/Input.vue";
import FormSkeleton from '../../../components/Loading/FormSkeleton'
export default {
  components: { Dropdown, Input, FormSkeleton },
  name: "CadastroReserva",
  data() {
    return {
      solicitacoes: [],
      nome: null,
      sobrenome: null,
      email: null,
      celular: null,
      agencia: 0,
      instagram: null,
      facebook: null,
      linkedin: null,
      codigo: null,
      operadora: null,
      checkin: null,
      checkout: null,
      chegada: null,
      partida: null,
      mensagem: null,
      agencias: [],
      hotel: 0,
      hoteis: [],
      operadora: 0,
      operadoras: [],
      idagente: null,
      pesquisaHotel: "",
      pesquisaAgencia: "",
      foiValidado: null,
      hotelreset: null,
      agenciareset: null,
      operadorareset: null,
      loading: false,
      idagencia: null,
      idusuariotipo: null,
      idusuario: null,
      reqloading: false,
    };
  },
  methods: {
    async envioFormulario(e) {
      e.preventDefault();
      if (this.validacao()) {
        this.reqloading = true
        const today = new Date();
        const dataCadastro =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        const datacheckin = this.transformDate(this.checkin, this.chegada);
        const datacheckout = this.transformDate(this.checkout, this.partida);
        const dadosusuario = {
          idhotel: parseInt(this.hotel),
          idagente: parseInt(this.idagente), //fixo pois nao tem sistema de login
          idagencia: parseInt(this.idagencia),
          idoperadora: parseInt(this.operadora),
          idreservastatus: 1,
          numeroreservapms: 1,
          idreservapms: 1,
          localizadorpms: 1,
          datacadastro: dataCadastro,
          datacheckin: datacheckin.toISOString(),
          datacheckout: datacheckout.toISOString(),
          mensagem: this.mensagem,
          hospedes: [
            {
              principal: true,
              nome: this.nome,
              sobrenome: this.sobrenome,
              datanascimento: null,
              email: this.email,
              iddocumentotipo: null,
              numerodocumento: null,
              celular: this.celular,
              facebook: this.facebook,
              instagram: this.instagram,
              twitter: null,
              linkedin: this.linkedin,
              enderecos: null,
            },
          ],
          solicitacoes: this.solicitacoes.map((soli) => {
            return {
              idtiposolicitacao: soli.idtiposolicitacao,
              solicitado: soli.solicitado,
            };
          }),
        };
        console.log(JSON.stringify(dadosusuario, null, "\t"));
        await ReservaServico.registrar(dadosusuario)
          .then((resp) => {
            if (resp.status === 200) {
              this.nome = null;
              this.sobrenome = null;
              this.email = null;
              this.celular = null;
              this.agencia = null;
              this.hotel = null;
              this.instagram = null;
              this.facebook = null;
              this.linkedin = null;
              this.operadora = null;
              this.checkin = null;
              this.checkout = null;
              this.chegada = null;
              this.partida = null;
              this.mensagem = null;
              this.hotelreset=true
              this.agenciareset=true
              this.operadorareset=true
              Object.keys(this.solicitacoes).map(
                (key) => (this.solicitacoes[key] = false)
              );
              document.getElementById("btn-reset").click();
              alertar("success", "=)", "Reserva criada com Sucesso!");
            }
          })
          .catch((error) => {
            alertar("warning", "=(", error.response.data.msg);
          });
          this.reqloading = false
      }
    },
    validacao() {
      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.sobrenome || this.sobrenome.trim() == "") {
        alertar(
          "warning",
          "Informe um Sobrenome Válido!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      }

      if (!this.email || this.email.trim() == "") {
        alertar("warning", "Informe um Email Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.celular || this.celular.trim() == "") {
        alertar("warning", "Informe um Celular Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      // if (!this.agencia || this.agencia == 0) {
      //   alertar("warning", "Informe uma Agência Válida!", "Campo Obrigatório!");
      //   this.foiValidado = false;
      //   return false;
      // }

      if (!this.hotel || this.hotel == 0) {
        alertar("warning", "Informe um hotel Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }
      if (!this.operadora || this.operadora == 0) {
        alertar(
          "warning",
          "Informe uma operadora Válida!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      }

      if (
        !this.checkin ||
        this.checkin.trim() == "" ||
        !(new Date(this.checkin) > 0)
      ) {
        alertar(
          "warning",
          "Informe uma data de Check-in Válida!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      }
      if (
        !this.checkout ||
        this.checkout.trim() == "" ||
        !(new Date(this.checkout) > 0)
      ) {
        alertar(
          "warning",
          "Informe uma data de Check-out Válida!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      }
      if (
        new Date(this.checkin) > new Date(this.checkout) ||
        new Date(this.checkout) < new Date(this.checkin)
      ) {
        alertar(
          "warning",
          "Revisar datas de check-in e check-out",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      }
      if (!this.chegada || this.chegada.trim() == "") {
        alertar(
          "warning",
          "Informe um horario de chegada Válido!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      }
      if (!this.partida || this.partida.trim() == "") {
        alertar(
          "warning",
          "Informe um horario de partida Válido!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      }
      return true;
    },
    async requisitarAgencias() {
      const documents = await AgenciaServico.listarTodos();
      let agencias = [];
      documents.data.map((doc) => {
        if (agencias.filter((el) => el.nome === doc.nome).length === 0) {
          agencias.push({ nome: doc.nome, value: doc.idusuario });
        }
      });
      this.agencias = agencias;
    },
    async requisitarOperadoras() {
      const { data } = await OperadoraServico.listarTodos();
      this.operadora = 0;
      let operadoras = new Array();
      data.map((operadora) => {
        operadoras.push({ nome: operadora.nome, value: operadora.idoperadora });
      });
      this.operadoras = operadoras;
    },
    link(to) {
      this.$router.push(to);
    },
    async requisitarHoteis() {
      let usuarioadm = this.idusuariotipo === 2 ? true : false
      if(usuarioadm){
        const { data } = await HotelServico.listarPorUsuarioAdm(usuarioadm, this.idusuario);
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
        return       
      }else{
      const { data } = await HotelServico.listarTodos();
      this.hotel = 0;
      let hoteis = new Array();
      data.map((hotel) => {
        hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
      });
      this.hoteis = hoteis;
      }
    },
    requisitarIdLocalStorage() {
      this.idagente = localStorage.idusuario;
    },
    async alteracaoCampoHoteis() {
      this.solicitacoes = []
      const solicitacoes = await HotelServico.solicitacoesPorHotel(this.hotel);
      solicitacoes.map((soli) => {
        return this.solicitacoes.push({
          ...soli,
          solicitado: false,
        });
      });
    },
    transformDate(date, time) {
      const dateArray = date.split("-");
      const timeArray = time.split(":");
      const auxDate = new Date(
        dateArray[0],
        parseInt(dateArray[1]) - 1,
        dateArray[2],
        timeArray[0],
        timeArray[1]
      );
      return new Date(auxDate - auxDate.getTimezoneOffset() * 60000);
    },
  },
  async mounted() {
    this.loading=true
    //await this.requisitarAgencias();
    this.idagencia = parseInt(localStorage.getItem('idagencia'))
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    await this.requisitarOperadoras();
    await this.requisitarHoteis();
    await this.requisitarIdLocalStorage();
    this.chegada = '13:00'
    this.partida = '12:00'
    this.loading=false
  },
  computed: {
    hoteisFiltrados: function () {
      const data = this.hoteis.filter((hotel) => {
        return hotel.message.match(this.pesquisaHotel);
      });
      data.push({ message: "Selecione um hotel", value: 0 });
      return data;
    },
    agenciasFiltradas: function () {
      const data = this.agencias.filter((agencia) => {
        return agencia.message.match(this.pesquisaAgencia);
      });
      data.push({ message: "Selecione uma Agência", value: 0 });
      return data;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.pesquisa {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 0.5rem;
  outline: none;
  padding: 0.3rem 1rem;
}
.formularioCadastroReserva {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}
.fields-container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}
button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
.span-error {
  width: 100%;
  font-size: 12px;
  color: red;
  text-align: left;
}

.item-checkbox-checked {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  height: 4rem;
  border-radius: 1rem;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: green;
  margin-bottom: 1rem;

  label {
    cursor: pointer;
    color: white;
    font-weight: bold;
  }
  input {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
    transition: 0.2s ease-in-out;
    outline: none;
  }
  input[type="checkbox"] {
    // or change the colour
    filter: hue-rotate(277deg);
  }
}
.item-checkbox {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  height: 4rem;
  border-radius: 1rem;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: #dee4e6;
  margin-bottom: 1rem;

  label {
    cursor: pointer;
  }
  input {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
    transition: 0.2s ease-in-out;
    outline: none;
  }
  input[type="checkbox"] {
    // or change the colour
    filter: hue-rotate(277deg);
  }
}
</style>
