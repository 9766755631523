<template class="template">
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <form
      id="formularioCadastroReserva"
      class="formularioCadastroReserva"
      autocomplete="off"
    >
      <v-card class="card-shadow" width="100%">
        <v-row class="card-padding pb-0 mb-0 d-flex align-center">
          <v-col cols="6" lg="6" class="d-flex flex-column py-0">
            <div class="d-flex flex-row pd-0 align-center">
             
            </div>
          </v-col>
          <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
             <button 
             @click="link('/list/request')"
             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
          </v-col>
        </v-row>
        <v-card-text class="card-padding">
          <v-row>
            <v-col cols="12" lg="6" sm="6" md="6">
              <v-row class="">
           <v-col cols="12" sm='12' md="12" lg="12">
             <label for="" class="label-color font-weight-600 pr-2"
                >Dados do Prêmio:
              </label>
           </v-col>
           <div class="exibicao-grupo column">
              <div class="exibicao-field">
                <p class="label mr">Nome:</p>
                <p class="info">{{premio.nome}}</p>
              </div>
              <div class="exibicao-field">
                <p class="label mr">Pontuação:</p>
                <p class="info">{{premio.pontuacao}}</p>          
              </div>  
              <div class="exibicao-field">
                <Dropdown
              :options="status"
              :multiple="false"
              label="status"
              placeholder="Selecione um status"
              v-model="statusselecionado"
              id="status-select"
              :initialValue="statusselecionado"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-briefcase-24'"
            />          
              </div>  
            </div>   
          </v-row>
            </v-col>
            <v-col cols="12" lg='6' sm='12' md='6'>
              <v-row class="">
           <v-col cols="12" sm='12' md="12" lg="12">
             <label for="" class="label-color font-weight-600 pr-2"
                >Dados do agente:
              </label>
           </v-col>
           <div class="exibicao-grupo column">
              <div class="exibicao-field">
                <p class="label mr">Nome:</p>
                <p class="info" data-cy="nome">{{usuario.nome}}</p>
              </div>
              <div class="exibicao-field">
                <p class="label mr" data-cy="email">email:</p>
                <p class="info">{{usuario.email}}</p>          
              </div>  
           </div>   
              </v-row>
            </v-col>
           
          </v-row>
          <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        width="130"
        class="
          font-weight-600
          text-capitalize
          btn-primary
          py-3
          my-3
          px-0
          rounded-sm
        "
        color="#0f88f2"
        @click="atualizarSolicitacao"
        :loading="reqloading"
        :disabled="reqloading"
        >Atualizar</v-btn
      >
        </v-card-text>
      </v-card>
      <button type="reset" id="btn-reset" hidden />
      
    </form>
  </div>
</template>

<script>
import { alertar } from "@/servicos/notificacao.servico.js";
import SolicitacaoServico from '../../../servicos/solicitacao.servico'
import UsuarioServico from '../../../servicos/usuario.servico'
import PremioServico from '../../../servicos/premio.servico'
import Dropdown from "../../../components/Dropdown.vue";
import Input from "../../../components/Input.vue";
import EmailServico from '../../../servicos/email.servico.js'
import NotificacaoServico from '../../../servicos/notificacao.servico.js'
export default {
  components: { Dropdown, Input },
  name: "LeituraSolicitacao",
  data() {
    return {
      status: [],
      statusselecionado: null,
      solicitacao: {},
      usuario: {},
      premio: {},
      statusinicial: null,
      idusuario: null,
      reqloading: false,
    };
  },
  methods: {
    async atualizarSolicitacao(){
              this.reqloading=true

      const dadosususario = {
        status: this.statusselecionado,
        idsolicitacao: this.solicitacao.idsolicitacao
      }
      await SolicitacaoServico.atualizar(dadosususario)
        .then(async res=> {
          if(res.status === 200){
            //enviar email
              if(this.statusinicial !== this.statusselecionado){
                const status = this.status.filter(el => el.value === this.statusselecionado)[0].nome
                EmailServico.email({
                  titulo: 'Status de Solicitação Alterado',
                  conteudo: `O status da solicitação n° ${dadosususario.idsolicitacao} foi alterado para ${status}`,
                  subject: 'Status Solicitação'
                }).then(() => console.log('email enviado')).catch(err => console.log('erro', err))
                //criar notificacao
                await NotificacaoServico.registrar({
                  idusuario: this.idusuario,
                  titulo: 'Atualização Solicitacao',
                  mensagem: `Você atualizou a solicitação n° ${dadosususario.idsolicitacao}`
                }).then(() => console.log('notificacao criador')).catch(e => console.log('error criador'))
                await NotificacaoServico.registrar({
                  idusuario: this.solicitacao.idusuario,
                  titulo: 'Atualização Solicitacao',
                  mensagem: `Sua solicitacao foi alterada. Confira.`
                }).then(() => console.log('notificacao criador')).catch(e => console.log('error criador'))
              }
              this.reqloading=false
            alertar('success', '=)', 'solicitação alterada com sucesso')
          }
        })
        .catch(e => {
              this.reqloading=false

          alertar('warning', '=/', 'erro na atualização da solicitação')
        })
    },
    link(to) {
      this.$router.push(to);
    },
    async requisitarSolicitacao(id) {
      const { data } = await SolicitacaoServico.listar(id);
      return data[0];
    },
    async requisitarStatus() {
      const {data} = await SolicitacaoServico.status()
      this.status = data.map(status => ({
        nome: status.nome,
        value: status.idstatus
      }));
    },
    async requisitarUsuario(id){
      const {data} = await UsuarioServico.listar(id)
      this.usuario = data[0]
    },
    async requisitarPremio(id){
      const {data} = await PremioServico.listar(id)
      this.premio = data[0]
    },
  },
  async mounted() {
    await this.requisitarStatus();
    const { id } = this.$route.params;
    this.solicitacao = await this.requisitarSolicitacao(id);
    this.statusselecionado = this.solicitacao.status
    this.statusinicial = this.solicitacao.status
    await this.requisitarUsuario(this.solicitacao.idusuario)
    await this.requisitarPremio(this.solicitacao.idpremio)
    this.idusuario = localStorage.getItem('idusuario')
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.exibicao-field{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  &.mr{
    margin-right: 1em;
  }
  &.column{
    flex-direction: column;
  }
  @media screen and (max-width:480px){
    width: 100%;
  }
}
.label{
  font-size: clamp(1em, 1vw, 1.5em);  
  text-transform: capitalize;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.02rem;
  &.mr{
    margin-right: .5em;
  }
}
.info{
  background-color: none;
  
  @media screen and (max-width:480px){
    min-width: unset;
    width: 100%;
  }
}
.exibicao-grupo{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  &.column{
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }
}
.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}
</style>
