<template>
  <div>
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <v-row class="d-flex justify-space-between py-5 px-5 lista-header">
          <div>
            <p class="font-weight-600 text-h3 text-typo mb-1">
              Cofiguração Níveis
            </p>
            <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
              Segue abaixo a listagem dos níveis
            </p>
          </div>
          <!-- <Spinner v-if="loading"/> -->
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            @click="link('/config/levels')"
            class="
              font-weight-600
              text-capitalize
              btn-primary
              py-3
              px-6
              rounded-sm
            "
            color="#0f88f2"
            v-if="criarnivel"
            :loading="loading"
            >Configurar Niveis</v-btn
          >
        </v-row>
            <Dropdown
              label="hotel"
              placeholder="Selecione um hotel"
              :options="hoteis"
              v-model="hotel"
              id="hotel"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :initialValue="hotel"
              :sideEffect="aleteracaoCampoHoteis"
            />

      </div>
      <LeituraConfigNiveis v-if="niveis.length > 0" :niveis="niveis" :idhotel="idhotel"/>
      
     
    </v-card>
    <ListSkeleton v-if="initialloading"/>
  </div>
</template>
<script>
import NivelServico from "../../../servicos/nivel.servico";
import HotelServico from "../../../servicos/hotel.servico";
import Dropdown from '../../../components/Dropdown.vue';
import ListSkeleton from '../../../components/Loading/ListSkeleton.vue'
import Spinner from '../../../components/Loading/Spinner.vue'
import LeituraConfigNiveis from '../../Components/Leituras/LeituraConfigNiveis.vue'
export default {
  name: "paginated-tables",
  components: {Dropdown, ListSkeleton, Spinner, LeituraConfigNiveis},
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      hoteis: [],
      hotel: 0,
      niveis: [],
      acessoadmdisponivel:false,
      loading: false,
      nomepesquisa: null,
      initialloading: false,
      idusuario: null,
      idusuariotipo: null,
      hotelreset: false,
      criarnivel: false
    };
  },
  methods: {

    link(to) {
      this.$router.push(to);
    },

    async requisitarHoteis() {
      let usuarioadm = this.idusuariotipo === 2 ? true : false
      if(usuarioadm){
        const { data } = await HotelServico.listarPorUsuarioAdm(usuarioadm, this.idusuario);
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis; 
        return       
      }else{
      const { data } = await HotelServico.listarTodos();
      this.hotel = 0;
      let hoteis = new Array();
      data.map((hotel) => {
        hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
      });
      this.hoteis = hoteis;
      }
    },
    async aleteracaoCampoHoteis(){
      this.loading = true
      const {data} = await NivelServico.listarPorHotel(this.hotel)
      console.log('data nivel', data)
      localStorage.setItem('hotel',this.hotel)
      this.loading = false
      this.niveis = data
      if(data.length === 0 ){
        this.criarnivel = true
      }else{
        this.criarnivel = false
      }
    },
  },

  async mounted() {
    this.initialloading = true
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    if(this.idusuariotipo === 1 || this.idusuariotipo === 2){
      this.acessoadmdisponivel = true
    }
    await this.requisitarHoteis()
    //codigo para manter pesquisa
    const currSearch = localStorage.getItem('hotel')
    if (currSearch){
      this.hotel = parseInt(currSearch)
      this.aleteracaoCampoHoteis()
    }
    this.initialloading = false
  },
};
</script>
<style lang="scss" scoped>
.search-wrapper{
  display: flex;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  padding: 0.5em 1em;
  border-radius: 0.7125em;
  height: 4em;
  display: flex;
  justify-content: space-between;

}

.search-field{
  .unicon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;  }
  display: flex;
  align-items: center;
}
.search-input{
  outline: none;
  font-size: 14px;
  color:rgba(0,0,0,0.8);
  flex: 1;  
}

.search-button{
  border: 2px solid #0f88f2;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;margin-right: .5em;
  color: #0f88f2;
}
.reset-button{
    border: 2px solid rgba(133, 133, 133, 0.4);
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;
  color: rgba(133, 133, 133, 0.4);
}
.lista-header {
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    > button {
      margin-top: 1.5em;
    }
  }
}

::v-deep tbody tr {
  cursor: pointer;
}
.v-data-table {
  width: 100%;
  text-align: left;
}
.btn-visualizar,
.btn-editar {
  color: #000;
  &:hover {
    color: #000;
  }
}
</style>
