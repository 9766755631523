<template class="template">
  <form id="admhotelForm" class="admhotelForm" autocomplete="off">
    <v-card class="card-shadow" width="100%">
      <v-row class="card-padding pb-0 mb-0 d-flex align-center">
        <v-col cols="6" lg="6" class="d-flex flex-column py-0">
          <h2 class="label-color font-weight-600">{{ admhotel.nome }}</h2>
          <div class="d-flex flex-row pd-0 align-center">
            <label for="" class="label-color font-weight-600 pr-2">Id: </label>
            <p class="label-color font-weight-300 mb-0">
              {{ admhotel.idusuario }}
            </p>
          </div>
        </v-col>
        <v-col cols="6" lg="6" class="d-flex align-self-baseline justify-end">
           <button 
             @click="link('/list/admhotel')"
             type='button'

             class="btn-retorno"><v-icon>ni ni-bold-left</v-icon></button>
        </v-col>
      </v-row>
      <v-row class="card-padding pt-0 mt-0 d-flex align-center">
        <v-col cols="6" lg="3" class="d-flex flex-column py-0">
          
        </v-col>
      </v-row>
      <v-row class="card-padding pt-0 mt-0 d-flex flex-row align-end">
        <v-col cols="6" lg="6" md="6" sm="6" class="">
          <Dropdown
            :options="status"
            :multiple="false"
            placeholder="Selecione um status"
            label="Selecione um status"
            v-model="statusselecionado"
            id="status-select"
            :initialValue="statusselecionado"
            :border="'2px solid rgba(15, 136, 242, 0.3)'"
            :borderactive="'2px solid rgba(15, 136, 242)'"
            :borderradius="'0.7125rem'"
            :icon="'ni ni-briefcase-24'"
          />
        </v-col>
        <v-col cols="6" lg="2" md="2" sm="2" xs="2" class="px-0">
         <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    width="145"
                    class="
                      font-weight-600
                      text-capitalize
                      btn-primary
                      py-0
                      px-6
                      rounded-sm
                      mt-7
                    "
                    outlined
                    color="#0f88f2"
                    data-cy="btn-envio-status"
                    @click="atualizarStatus"
                    >status</v-btn
                  >
        </v-col>
      </v-row>
      <v-card-text class="card-padding">
        <v-row class="">
          <v-col md="6" sm="12" xs="12">
            <Input
              name="nome"
              label="nome"
              v-model="nome"
              :input="true"
              icon="ni ni-circle-08"
              placeholder="Insira seu nome"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="email"
              label="email"
              v-model="email"
              :input="true"
              icon="ni ni-email-83"
              placeholder="Insira seu email"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col md="6" sm="12" xs="12">
            <Input
              name="cpf"
              label="cpf"
              v-model="cpf"
              v-mask="'###.###.###-##'"
              :input="true"
              icon="ni ni-badge"
              placeholder="Insira seu cpf"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="data"
              label="data"
              v-model="data"
              :input="true"
              type="date"
              icon="ni ni-calendar-grid-58"
              placeholder="Insira seu data"
            />
          </v-col>
        </v-row>

        
      </v-card-text>
    </v-card>

    <button type="reset" id="btn-reset" hidden />

    <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      color="#0f88f2"
      data-cy="btn-envio"
      @click="atualizaradmhotel"
      >atualizar</v-btn
    >
    <span v-if="!foiValidado" hidden data-cy="data-cy-error" />
  </form>
</template>

<script>
import UsuarioServico from "../../../servicos/usuario.servico";
import LocalizacaoServico from "../../../servicos/localizacao.servico";
import { alertar } from "@/servicos/notificacao.servico.js";
import validarCpf from "../../../servicos/validarCpf";
import Dropdown from "../../../components/Dropdown.vue";
import Input from "../../../components/Input.vue";

export default {
  components: { Dropdown, Input },
  name: "CadastroAdmHotel",
  watch: {
    paisselecionado: function (val) {
      this.fetchEstados();
    },
  },
  data: () => ({
    nome: null,
    email: null,
    cpf: null,
    data: null,
    telefone: null,
    celular: null,
    senha: null,
    senha2: null,
    idusuario: null,
    paisselecionado: 0,
    estadoselecionado: 0,
    cidadeselecionado: 0,
    agenciaselecionado: 0,
    admhotel: {},
    paises: [{ nome: "", value: 0 }],
    estados: [{ nome: "", value: 0 }],
    cidades: [{ nome: "", value: 0 }],
    foiValidado: true,
    paisreset: false,
    estadoreset: false,
    cidadereset: false,
    status: [],
    statusselecionado: 0,
  }),
  methods: {
    async atualizaradmhotel() {
      if (this.validarCampos()) {
        const cpfNewFormat = this.cpf
          .replace(".", "")
          .replace(".", "")
          .replace("-", "");

        let dadosusuario = {
          idusuario: this.idusuario,
          email: this.email,
          nome: this.nome,
          datanascimento: this.data,
          iddocumentotipo: 1,
          numerodocumento: cpfNewFormat,
          idstatususuario: this.statusselecionado,
          statususuario: this.status.filter(
            (el) => el.value === this.statusselecionado
          )[0].nome,
        };
        
        UsuarioServico.atualizar(dadosusuario)
          .then((resp) => {
            if (resp.status === 200) {
              alertar("success", "=)", "Usuario atualizado com Sucesso!");
            }
          })
          .catch((error) => {
            alertar("warning", "=(", error.response.data.msg);
          });
      }

    },
    async atualizarStatus(){
      const dados = {
          idusuario: this.idusuario,
          idstatususuario: this.statusselecionado,
          statususuario: this.status.filter(
            (el) => el.value === this.statusselecionado
          )[0].nome,
      }
      UsuarioServico.atualizar(dados)
          .then((resp) => {
            if (resp.status === 200) {
              alertar("success", "=)", "Status do Usuario atualizado com Sucesso!");
            }
          })
          .catch((error) => {
            alertar("warning", "=(", error.response.data.msg);
          });
    },
    validarCampos() {
      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.email || this.email.trim() == "") {
        alertar("warning", "Informe um Email Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.cpf || this.cpf.trim() == "") {
        alertar("warning", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      } else if (!validarCpf(this.cpf)) {
        alertar("warning", "Informe um CPF Válido!");
        this.foiValidado = false;
        return false;
      }

      if (!this.data || this.data.trim() == "") {
        alertar(
          "warning",
          "Informe um Data de Nascimento Válida!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      } else {
        const currDate = new Date(this.data);
        if (currDate > 0 === false) {
          alertar(
            "warning",
            "Informe um 1Data de Nascimento Válida!",
            "Campo Obrigatório!"
          );
          this.foiValidado = false;
          return false;
        }
      }

      if (!this.statusselecionado || this.statusselecionado == 0) {
        alertar("warning", "Informe um status Válido!", "Campo Obrigatório!");
        return false;
      }

      return true;
    },
    async fetchPaises() {
      this.paisselecionado = 0;
      const response = await LocalizacaoServico.paises({});
      this.paises = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.paises.push({ nome: p.nome, value: p.idpais });
      });
      this.cidades = [{ nome: "", value: 0 }];
    },
    async fetchEstados(e) {
      this.estadoselecionado = 0;
      const response = await LocalizacaoServico.estados({
        idpais: this.paisselecionado,
      });
      this.estados = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.estados.push({ nome: p.nome, value: p.idestado });
      });
    },
    async fetchCidades(e) {
      this.cidadeselecionado = 0;
      const response = await LocalizacaoServico.cidades({
        idestado: this.estadoselecionado,
      });
      this.cidades = [{ nome: "", value: 0 }];
      response.data.map((p) => {
        this.cidades.push({ nome: p.nome, value: p.idcidade });
      });
    },
    handleChangePais(e) {
      this.fetchEstados(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "", value: 0 }];
      this.cidadereset = !this.cidadereset;
      this.estadoselecionado = 0;
      this.estados = [{ nome: "", value: 0 }];
      this.estadoreset = !this.estadoreset;
    },
    handleChangeEstados(e) {
      this.cidadereset = !this.cidadereset;
      this.fetchCidades(e);
      this.cidadeselecionado = 0;
      this.cidades = [{ nome: "", value: 0 }];
    },
    async requisitarAdmHotel(id) {
      const { data } = await UsuarioServico.listar(id);
      return data[0];    
    },
    link(to) {
      this.$router.push(to);
    },
    async requisitarStatus() {
      const { data } = await UsuarioServico.status();
      this.status = data.map((status) => ({
        nome: status.nome,
        value: status.idstatususuario,
      }));
    },
  },
  async mounted() {
    await this.requisitarStatus();
    const { id } = this.$route.params;
    this.admhotel = await this.requisitarAdmHotel(id);

    this.nome = this.admhotel.nome;
    this.email = this.admhotel.email;
    this.cpf = this.admhotel.numerodocumento;

    await this.fetchPaises();
    this.paisselecionado = parseInt(this.admhotel.idpais) || 0;
    await this.fetchEstados(this.estado);
    this.estadoselecionado = parseInt(this.admhotel.idestado) || 0;
    await this.fetchCidades(this.cidade);
    this.cidadeselecionado = parseInt(this.admhotel.idcidade) || 0;


    this.data = this.admhotel.datanascimento.substring(0, 10);

    this.idusuario = this.admhotel.idusuario;
    this.statusselecionado = this.admhotel.idstatususuario;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.admhotelForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 2% 5% 2%;
}
.fields-container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

button {
  width: 7rem;
  padding: 0 0.5rem;
  height: 2.75rem;
  border-radius: 5rem;
  border: none;
  font-weight: lighter;
  letter-spacing: 0.02rem;
  background-color: whitesmoke;
}
.span-error {
  width: 100%;
  font-size: 12px;
  color: red;
  text-align: left;
}
.btn-retorno{
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: #0f88f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-icon{
    color: white
  }
}
</style>






