<template>
  <div>
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <v-row class="d-flex justify-space-between py-5 px-5 lista-header">
          <div>
            <p class="font-weight-600 text-h3 text-typo mb-1">
              Lista de Vendas
            </p>
            <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
              Segue abaixo a listagem de todas as Vendas disponíveis
            </p>
          </div>
          <!-- <Spinner v-if="loading"/> -->
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            @click="link('/register/reserve')"
            class="
              font-weight-600
              text-capitalize
              btn-primary
              py-3
              px-6
              rounded-sm
            "
            color="#0f88f2"
            :loading="loading"
            v-if="habilitarCriarReserva"
            >Nova Venda</v-btn
          >
        </v-row>
         <Dropdown
              label="hotel"
              placeholder="Selecione um hotel"
              :options="hoteis"
              v-model="hotel"
              id="hotel"
              :sideEffect="requisitarReservas"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :initialValue="hotel"
            />
            <input v-model="search" type="text"/>
      </div>
      <div class="card-header-padding card-border-bottom" v-if="!initialloading">
        <span class="search-wrapper">
         <span class="search-field">
           
          <v-text-field
            label="Nome do hospede"
            v-model="nomepesquisa"
            outlined
          ></v-text-field>
         </span>
         <span class="search-field">
            <v-text-field
            type="date"
            label="Check-in"
            outlined
            v-model="checkinpesquisa"
          ></v-text-field>
         </span>
         <span class="search-field">
            <v-text-field
            type="date"
            label="Check-out"
            outlined
            v-model="checkoutpesquisa"
          ></v-text-field>
         </span>
                  <span class="search-field">
            <v-select
            label="Status"
            :items="status"
            outlined
            v-model="statusselecionado"
          ></v-select>
         </span>
          <button type="button" class="search-button" @click="buscaReserva">Pesquisar</button>
          <button type="button" class="reset-button" @click="resetaReserva">Resetar</button>
        </span>
      </div>

      <v-card-text class="px-0 py-0" v-if="!initialloading">
        <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint=480
          @click:row="
            (item) => link(`/list/reserve/${item.idreserva}&${item.idhotel}`)
          "
          data-cy="tabela-lista-reserva"
          :loading="loading"
          loading-text="Carregando"
        >
      

          <template v-slot:item.nomehospede="{ item }">
            <span class="text-body text-h5">
              {{ item.nomehospede }}
            </span>
          </template>

          <template v-slot:item.datacheckin="{ item }">
            <span class="text-body text-h5">
              {{ item.datacheckin | formatarData}}
            </span>
          </template>

          <template v-slot:item.datacheckout="{ item }">
            <span class="text-body text-h5">
              {{ item.datacheckout | formatarData }}
            </span>
          </template>

          
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding" v-if="!initialloading">
        <v-row>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <span class="text-body me-3">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <v-btn
              elevation="2"
              outlined
              small
              text
              @click="exportar"
              v-if="items.length"
              >
              exportar excel
            </v-btn>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#0f88f2"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ListSkeleton v-if="initialloading"/>
  </div>
</template>
<script>
import ReservaServico from "../../../servicos/reserva.servico";
import HotelServico from "../../../servicos/hotel.servico";
import Dropdown from "../../../components/Dropdown.vue";
import ListSkeleton from '../../../components/Loading/ListSkeleton.vue';
import Spinner from '../../../components/Loading/Spinner.vue';
import {exportToExcel} from '../../../util/excel.js'

export default {
  name: "paginated-tables",
  components: { Dropdown, ListSkeleton, Spinner },
  data() {
    return {
      hoteis: null,
      hotel: null,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: "Nome", value: "nomehospede" },
        { text: "Status", value: "reservastatus" },
        { text: "Data Check-in", value: "datacheckin" },
        { text: "Data Check-out", value: "datacheckout" },
      ],
      items: [],
      loading: false,
      nomepesquisa: null,
      checkinpesquisa:null,
      checkoutpesquisa:null,
      status: [],
      idusuario: null,
      idusuariotipo: null,
      habilitarCriarReserva: false,
      statusselecionado: null,
      initialloading: false,
      search: null,
    };
  },
  methods: {
    exportar(){
      let data = this.items
      exportToExcel(data, 'planilha-dados-vendas')
    },
    link(to) {
      this.$router.push(to);
    },
    async requisitarReservas() {
      
      this.loading =true
      const { data } = await ReservaServico.listarPorHotel({
        idhotel: this.hotel,
      });
      localStorage.setItem('hotel',this.hotel)
      console.log('reservas', data)
      this.items = data;
      this.loading =false
      
    },
    async requisitarHoteis() {
      let usuarioadm = this.idusuariotipo === 2 ? true : false
      if(usuarioadm){
        const { data } = await HotelServico.listarPorUsuarioAdm(usuarioadm, this.idusuario);
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
        return       
      }else{
      const { data } = await HotelServico.listarTodos();
      this.hotel = 0;
      let hoteis = new Array();
      data.map((hotel) => {
        hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
      });
      this.hoteis = hoteis;
      }
    },
    async buscaReserva(){
      let query = {idhotel: this.hotel}
      if(this.nomepesquisa!== null){
        query['nomehospede'] = this.nomepesquisa
      }
      if(this.checkinpesquisa!== null){
        query['datacheckin'] = this.checkinpesquisa
      }
      if(this.checkoutpesquisa!== null){
        query['datacheckout'] = this.checkoutpesquisa
      }
      if(this.statusselecionado!== null){
        query['idreservastatus'] = await ReservaServico.status().then(res => res.data.filter(el => el.nome === this.statusselecionado)[0].idreservastatus)
      }
      console.log('query', query)
      try{
        const {data} = await ReservaServico.buscar(query)
      console.log('data', data)
      this.items = data
      }catch(e){
        console.log('error', e)
      }
    },
    async resetaReserva(){
       await this.requisitarReservas()
       this.nomepesquisa = null
       this.checkinpesquisa = null
       this.checkoutpesquisa = null
    },
    async requisitarStatus(){
      const {data} = await ReservaServico.status()
      console.log('status', data)
      this.status = data.map(item => item.nome)
    }
  },
  async mounted() {
    this.initialloading = true
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    if(this.idusuariotipo === 2 || this.idusuariotipo === 1){
      this.habilitarCriarReserva = false
    }else{
      this.habilitarCriarReserva = true
    }
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    await this.requisitarHoteis();
    await this.requisitarStatus()
   
    //codigo para manter pesquisa
    const currSearch = localStorage.getItem('hotel')
    if(currSearch){
      this.hotel = parseInt(currSearch)
      await this.requisitarReservas()
    }
    this.initialloading = false
  },
};
</script>
<style lang="scss" scoped>
.search-wrapper{
  display: flex;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  padding: 0.5em 1em;
  border-radius: 0.7125em;
  height: 4em;
  display: flex;
  justify-content: space-between;

}

.search-field{
  .unicon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;  }
  display: flex;
  align-items: center;
}
.search-input{
  outline: none;
  font-size: 14px;
  color:rgba(0,0,0,0.8);
  flex: 1;  
}

.search-button{
  border: 2px solid #0f88f2;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;margin-right: .5em;
  color: #0f88f2;
}
.reset-button{
    border: 2px solid rgba(133, 133, 133, 0.4);
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;
  color: rgba(133, 133, 133, 0.4);
}

.lista-header{
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    > button {
      margin-top: 1.5em;
    }    
  }
}


::v-deep tbody tr {
  cursor: pointer;
}
.v-data-table {
  width: 100%;
  text-align: left;
}
</style>
