<template class="template">
<span>
    <form
    id="formularioCadastroPremio"
    class="formularioCadastroPremio"
    autocomplete="off"
    v-if="!loading"
  >
    <v-card class="card-shadow" width="100%">
      <h2 class="ml-5 mt-5">Cadastro de Prêmios</h2>
      <v-card-text class="card-padding">
        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="nome"
              label="nome"
              v-model="nome"
              :input="true"
              icon="ni ni-circle-08"
              placeholder="Insira o nome do prêmio"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Input
              name="pontuacao"
              label="pontuacao"
              v-model="pontuacao"
              :input="true"
              type="number"
              icon="ni ni-collection"
              placeholder="Insira seu pontuacao"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="6" sm="6" xs="6">
            <Dropdown
              label="Nível"
              placeholder="Selecione um nivel"
              :options="niveis"
              v-model="nivel"
              id="nivel"
              data-cy="nivel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-trophy'"
              :ableToSearch="false"
              :reset="nivelreset"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="6">
            <Dropdown
              label="hotel"
              placeholder="Selecione um hotel"
              :options="hoteis"
              v-model="hotel"
              id="hotel"
              data-cy="hotel"
              :border="'2px solid rgba(15, 136, 242, 0.3)'"
              :borderactive="'2px solid rgba(15, 136, 242)'"
              :borderradius="'0.7125rem'"
              :icon="'ni ni-building'"
              :ableToSearch="true"
              :reset="hotelreset"
            />
          </v-col>
        </v-row>

        <v-row class="">
          <!-- <v-checkbox
                v-model="checkbox"
                color="#0f88f2"
                :ripple="false"
                class="ma-0 checkbox-custom checkbox-thinner"
                hide-details
              >
                <template v-slot:label>
                  <span class="text-muted text-body-2 ls-0"
                    >Li e Estou de Acordo com o
                    <a
                      href="javascript:;"
                      class="text-primary text-decoration-none"
                      >Termo de Privacidade</a
                    >
                  </span>
                </template>
              </v-checkbox> -->
        </v-row>
         <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      data-cy="btn-envio"
      color="#0f88f2"
      @click="envioForm"
      :loading="reqloading"
      :disabled="reqloading"
      >Cadastrar</v-btn
    >
      </v-card-text>
   
    </v-card>

    <button type="reset" id="btn-reset" hidden />
    <span v-if="!foiValidado" hidden data-cy="data-cy-error" />

  </form>
  <FormSkeleton v-if="loading"/>
</span>
</template>
<script>
import Dropdown from "../../../components/Dropdown.vue";
import Input from "../../../components/Input.vue";
import { alertar } from "@/servicos/notificacao.servico.js";
import PremioServico from "@/servicos/premio.servico.js";
import HotelServico from '@/servicos/hotel.servico.js'
import UsuarioServico from '../../../servicos/usuario.servico';
import FormSkeleton from '../../../components/Loading/FormSkeleton.vue'
export default {
  name: "CadastroPremio",
  components: { Dropdown, Input, FormSkeleton },
  data() {
    return {
      niveis: [],
      hoteis: [],
      hotel: null,
      nome: null,
      pontuacao: null,
      nivel: null,
      nivelreset:null,
      foiValidado: null,
      loading: false,
      idusuariotipo: null,
      idusuario: null,
      reqloading: false,
    };
  },
  methods: {
    async envioForm() {
      this.reqloading = true
      const dadosusuario = {
        nome: this.nome,
        idhotel: this.hotel,
        pontuacao: this.pontuacao,
        nivel: this.nivel,
      };
      await PremioServico.registrar(dadosusuario)
        .then((resp) => {
          if (resp.status === 200) {
            this.nivel = null;
            this.nivelreset = true;
            this.nome = null;
            this.pontuacao = null;
            document.getElementById("btn-reset").click();
            alertar("success", "=)", "Prêmio criado com Sucesso!");
            this.nivel = false
          }
        })
        .catch((error) => {
          alertar("warning", "=(", error.response.data.msg);
        });
      this.reqloading = false
    },
    validacao() {
      if (!this.nome || this.nome.trim() == "") {
        alertar("warning", "Informe um Nome Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.nivel || this.nivel == 0) {
        alertar("warning", "Informe um nivel Válido!", "Campo Obrigatório!");
        this.foiValidado = false;
        return false;
      }

      if (!this.pontuacao || this.nome.trim() == "") {
        alertar(
          "warning",
          "Informe um valor para pontuação!",
          "Campo Obrigatório!"
        );
        this.foiValidado = false;
        return false;
      }
      return true
    },
    async requisitarHoteis() {
      if( this.idusuariotipo === 2){
        console.log('=====adm hotel =====')
        const { data } = await HotelServico.listarPorUsuarioAdm(true, this.idusuario);
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis; 
      }else if(this.idusuariotipo === 1){
        console.log('=====adm =====')
        const { data } = await HotelServico.listarTodos();
        let hoteis = new Array();
        data.map((hotel) => {
          hoteis.push({ nome: hotel.nome, value: hotel.idhotel });
        });
        this.hoteis = hoteis;
      }
    },
    async requsitarNiveis(){
      const {data} = await UsuarioServico.niveis()
      this.niveis = data
    }
  },
  async mounted(){
    this.loading =  true
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    await this.requisitarHoteis()
    await this.requsitarNiveis()
    this.loading =  false
  }
};
</script>
<style lang="scss"></style>