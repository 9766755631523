<template>
  <div>
    <v-card class="card-shadow">
      <div class="card-header-padding card-border-bottom" v-if="!initialLoading">
        <v-row class="d-flex justify-space-between py-5 px-5 lista-header">
          <div>
            <p class="font-weight-600 text-h3 text-typo mb-1">
              Lista de Opeadoras
            </p>
            <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
              Segue abaixo a listagem de todas as operadoras disponíveis
            </p>
          </div>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            @click="link('/register/operator')"
            class="
              font-weight-600
              text-capitalize
              btn-primary
              py-3
              px-6
              rounded-sm
            "
            :loading="loading"
            color="#0f88f2"
            >Nova Operadora</v-btn
          >
        </v-row>
      </div>
      <div class="card-header-padding card-border-bottom" v-if="!initialLoading">
        <span class="search-wrapper" >
          <span class="search-field">
            <unicon fill="#0f88f2" name="search" />
          
          <input 
            type="text"
            v-model="pesquisa"
            class="search-input"
            :placeholder="'Faça sua busca'"
          />
          </span>
          <span>


          <button type="button" class="search-button" @click="buscaOperadora">Pesquisar</button>
          <button type="button" class="reset-button" @click="resetaOperadora">Resetar</button>
          </span>
        </span>
      </div>

      <v-card-text class="px-0 py-0" v-if="!initialLoading">
        <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint=480
          @click:row="(item) => link(`/list/operator/${item.idoperadora}`)"
          data-cy="tabela-lista-operadora"
        >

          <template v-slot:item.idoperadora="{ item }">
            <span class="text-body text-h5">
              {{ item.idoperadora }}
            </span>
          </template>

          <template v-slot:item.nome="{ item }">
            <span class="text-body text-h5">
              {{ item.nome }}
            </span>
          </template>

          <template v-slot:item.cnpj="{ item }">
            <span class="text-body text-h5">
              {{ item.cnpj }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="editItem(item)"
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-warning
                me-2
                my-2
                rounded-sm
              "
              color="#fb6340"
            >
              <v-icon size="12">ni-ruler-pencil</v-icon>
            </v-btn>

            <v-btn
              @click="deleteItem(item)"
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="
                font-weight-600
                text-capitalize
                btn-ls btn-danger
                me-2
                my-2
                rounded-sm
              "
              color="#f5365c"
            >
              <v-icon size="12">ni-fat-remove</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding" v-if="!initialLoading">
        <v-row>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <span class="text-body me-3">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" lg="5" class="d-flex align-center">
            <v-btn
              elevation="2"
              outlined
              small
              text
              @click="exportar"
              v-if="items.length"
              >
              exportar excel
            </v-btn>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#0f88f2"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ListSkeleton v-if="initialLoading"/>
  </div>
</template>
<script>
import OperadoraServico from "../../../servicos/operadora.servico";
import ListSkeleton from '../../../components/Loading/ListSkeleton.vue'
import Input from '../../../components/Input.vue'
import {exportToExcel} from '../../../util/excel.js'

export default {
  name: "paginated-tables",
  components: {ListSkeleton, Input},
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: "Id", value: "idoperadora" },
        { text: "Nome", value: "nome" },
        { text: "Cnpj", value: "cnpj" },
      ],
      items: [],
      nomepesquisa: null,
      state: [],
      loading: false,
      initialLoading: false,
    };
  },
  methods: {
    exportar(){
      let data = this.items
      exportToExcel(data, 'planilha-dados-operadora')
    },
    link(to) {
      this.$router.push(to);
    },

    async requisitarOperadoras() {
      this.loading = true
      const { data } = await OperadoraServico.listarTodos();
      this.items = data;
      this.loading = false
    },

    async buscaOperadora(){
      const {data} = await OperadoraServico.buscar({nome: this.pesquisa})
      this.items = data
    },   
    async resetaOperadora(){
      await this.requisitarOperadoras()
      this.nomepesquisa = null
    }
  },
  
  async mounted() {
    this.initialLoading = true
    await this.requisitarOperadoras();
    this.initialLoading = false
  },
};
</script>
<style lang="scss" scoped>
.search-wrapper{
  display: flex;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  padding: 0.5em 1em;
  border-radius: 0.7125em;
  height: 4em;
  display: flex;
  justify-content: space-between;

}

.search-field{
  .unicon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;  }
  display: flex;
  align-items: center;
}
.search-input{
  outline: none;
  font-size: 14px;
  color:rgba(0,0,0,0.8);
  flex: 1;  
}

.search-button{
  border: 2px solid #0f88f2;
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;margin-right: .5em;
  color: #0f88f2;
}
.reset-button{
    border: 2px solid rgba(133, 133, 133, 0.4);
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
  border-radius: 0.7125em;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: .5em 1em;
  color: rgba(133, 133, 133, 0.4);
}

.lista-header{
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    > button {
      margin-top: 1.5em;
    }    
  }
}

::v-deep tbody tr {
  cursor: pointer;
}
.v-data-table {
  width: 100%;
  text-align: left;
}
</style>
