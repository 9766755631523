<template class="template">
  <div>
    <v-card class="card-shadow" width="100%" v-if="!initialloading">
      <v-row class="card-padding">
        <v-col cols="12" lg="6">
          <Dropdown
            :options="tipos"
            :multiple="false"
            placeholder="Selecione um status"
            v-model="tipo"
            id="status-select"
            :border="'2px solid rgba(15, 136, 242, 0.3)'"
            :borderactive="'2px solid rgba(15, 136, 242)'"
            :borderradius="'0.7125rem'"
            :icon="'ni ni-briefcase-24'"
            :sideEffect="alteracaoCampoTipo"
            :disabled="foiAlterado"
            data-cy='tipos-usuario'
          />
        </v-col>
      </v-row>
      <v-row class="card-padding pt-0 mt-0">
                <v-col cols="12" lg="6">
          <div v-if="tipo===null" class="mensagem">nenhum tipo de usuario selecionado</div>
          <ul class="rotas-lista" v-if="tipo !== null">
            <li v-for="rota,idx in navegacaoatual" :key="idx" :class="navegacaoatual[idx].ativo ? `rotas-item ativo` : 'rotas-item desativo'" @click="selecionarItem(rota, idx)">
              <input type="checkbox" :name="rota.titulo"  :data-cy="rota.titulo" v-model="navegacaoatual[idx].ativo"/>
              <label :for="rota.titulo">{{rota.titulo}}</label>
            </li>
          </ul>
        </v-col>
      </v-row>
        
      <v-row class="card-padding py-0 my-0">
      <v-col cols="12" lg="6" class="py-0 my-0">
      <v-btn
      elevation="0"
      :ripple="false"
      height="43"
      class="
        font-weight-600
        text-capitalize
        btn-primary
        py-3
        px-6
        rounded-sm
        my-6
      "
      data-cy="btn-envio"
      color="#0f88f2"
      @click="salvar"
      :disabled="reqloading"
      :loading="reqloading"
      >Salvar</v-btn
    >
    </v-col>
      </v-row>
    </v-card> 
    <FormSkeleton v-if="initialloading"/>
  </div> 
</template>
<script>
import Dropdown from "../../../components/Dropdown.vue";
import Input from "../../../components/Input.vue";
import { alertar } from "@/servicos/notificacao.servico.js";
import NavegacaoServico from '../../../servicos/navegacao.servico.js';
import UsuarioServico from '../../../servicos/usuario.servico.js'
import FormSkeleton from '../../../components/Loading/FormSkeleton.vue'

export default {
  name: "CadastroAcessoPerfil",
  components: { Dropdown, Input, FormSkeleton },
  data() {
    return {
      tipos: [],
      tipo: null,  
      prevtipo: null,   
      navegacaodefault: [],
      navegacaoatual: [],
      foiAlterado: null,
      initialloading: false,
      reqloading: false,
    };
  },

  methods: {
    async salvar(){
      this.reqloading = true
      const dadosdousuario = {
        idusuariotipo: this.tipo,
        navegacao: this.navegacaoatual
      }
      await NavegacaoServico.vincular(dadosdousuario)
          .then((resp) => {
            if (resp.status === 200) {
              this.foiAlterado = null
              alertar("success", "=)", "Rotas vinculadas com sucesso!");
            }
          })
          .catch((error) => {
            alertar("warning", "=(", error.response.data.msg);
          });
    this.reqloading = false
    },
    async requisitarTiposUsurio(){
      const{data} = await UsuarioServico.tipos()
      this.tipos = data.map(tipo => ({
        nome: tipo.nome,
        value: tipo.idusuariotipo
      }))
    },
    async requisitarRotas(){
      const {data} = await NavegacaoServico.listarTodos()
      return data.map(item => ({
        ...item,
        ativo: false,
      }))
    },
    async alteracaoCampoTipo(){
      if(this.foiAlterado){
        alertar("warning", "=/" ,"Salve as alterações")
        return
      }else{
        const navPorTipo = await NavegacaoServico.listarPorTipoUsuario(this.tipo)
        .then(result => result.data)
      const navList = navPorTipo.map(item => item.idnavegacao)
      
      const navegacaoatual = this.navegacaodefault.map(item => {
        if(navList.includes(item.idnavegacao)){
          return {
            ...item,
            ativo: true
          }
        }else{
          return{
            ...item
          }
        }
      })
      this.navegacaoatual = navegacaoatual
      }
    },
    selecionarItem(item, posicao){
      this.foiAlterado = !this.foiAlterado
      return this.navegacaoatual[posicao].ativo = !item.ativo
    }
  },
  async mounted(){
    this.initialloading = true
    await this.requisitarTiposUsurio() 
    this.navegacaodefault = await this.requisitarRotas()
    this.initialloading = false
  }
};
</script>
<style lang="scss" scoped>
.rotas-lista{
  list-style: none;
  max-height: 20em;
  overflow-y: scroll;
}

.rotas-item{
  display: flex;
  align-items: center;
  margin-bottom: .5em;
  background-color: #dee4e6;
  padding: .75em 1em;
  border-radius: 0.7125rem;

  &:hover{
    cursor: pointer;
  }
  input{
    margin-right: .5em;
    width: 1.5em;
    height: 1.5em;
    &:hover{
      cursor: pointer;
    }
  }
}

.mensagem{
  background-color: #dee4e6;
  color: #bbb;
  width: 100%;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
    border-radius: 0.7125rem;

}
//css indesejado
.v-application ul, .v-application ol {
    padding-left: 0;
}
</style>