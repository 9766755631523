
import api from '@/servicos/api'

export default {
  registrar(dados) {
    return api().post('/premio', dados)
  },
  listarTodos() {
    return api().post('/buscarpremio')
  },
  listarPorNivel(id) {
    return api().post('/buscarpremio', { nivel: id })
  },
  listarPorHotel(id) {
    return api().post('/premio/select', { idhotel: id })
  },
  listar(id) {
    return api().post('/premio/select', { idpremio: id })
  },
  buscar(query) {
    return api().post('/premio/select', query)
  },
  atualizar(body) {
    return api().put('/premio', body)
  },
  saldoAgente(idagente, idhotel){
    return api().post('/regras/getPontosDisponiveis', {idagente, idhotel})
  },
}
