<template>
  <v-row class="d-flex justify-center mt-n16">
    <v-col cols="12" md="7">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <v-row>
            <v-col cols="12" class="mt-n3">
              <v-card class="card-shadow">
                <v-card-text class="card-padding">
                  <v-row class="">
                    <v-col md="6" sm="12" xs="12">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Nome</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Nome"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-hat-3</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Email</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Email"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-email-83</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="">
                    <v-col md="6" sm="12" xs="12">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >CNPJ</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Nome"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-hat-3</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Data de Nascimento</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Email"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-email-83</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="">
                    <v-col md="6" sm="12" xs="12">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Telefone</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Nome"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-hat-3</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Celular</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Email"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-email-83</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="">
                    <v-col md="6" sm="12" xs="12">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Pais</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Nome"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-hat-3</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Estado</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Email"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-email-83</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="">
                    <v-col md="6" sm="12" xs="12">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Cidade</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Nome"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-hat-3</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Agencia</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Email"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-email-83</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="">
                    <v-col md="6" sm="12" xs="12">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Senha</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Nome"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-lock-circle-open</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Repetir Senha</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="Email"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >ni-lock-circle-open</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-card-text class="pa-12 text-center font-size-root">
            <v-checkbox
              v-model="checkbox"
              color="#0f88f2"
              :ripple="false"
              class="ma-0 checkbox-custom checkbox-thinner"
              hide-details
            >
              <template v-slot:label>
                <span class="text-muted text-body-2 ls-0"
                  >Li e Estou de Acordo com o
                  <a
                    href="javascript:;"
                    class="text-primary text-decoration-none"
                    >Termo de Privacidade</a
                  ></span
                >
              </template>
            </v-checkbox>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                my-6
              "
              color="#0f88f2"
              >Cadastrar</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "register",
  data() {
    return {
      checkbox: false,
    };
  },
};
</script>
